/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import MapContext from "@terrestris/react-geo/dist/Context/MapContext/MapContext";
import MapComponent from "@terrestris/react-geo/dist/Map/MapComponent/MapComponent";
import { renderToString } from 'react-dom/server';
import Feature from "ol/Feature";
import OlMap from "ol/Map";
import OlView from "ol/View";
import Point from "ol/geom/Point";
import OlLayerTile from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import "ol/ol.css";
import { fromLonLat, toLonLat } from "ol/proj";
import Cluster from "ol/source/Cluster.js";
import OlSourceOsm from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import CircleStyle from "ol/style/Circle.js";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text.js";
import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { MapContext as MP } from "..";
import LoadingIcon from "../Images/loadingicon.png";
import "../MapPage.scss";
import SatelliteLayer from "./SatelliteLayer";
import { BiCurrentLocation } from "react-icons/bi";
import { FaMap } from "react-icons/fa";
import { FaSatelliteDish } from "react-icons/fa";
import SelectArea from "./SelectArea";
import Modify from "ol/interaction/Modify";
import { Collection, Geolocation, Overlay } from "ol";
import EditFiberHouseReq from "../../../../Requests/FiberHouse/EditFiberHouseReq";
import FiberHouseApi from "../../../../API/FiberHouseApi";
import Swal from "sweetalert2";
import { IoMdSettings } from "react-icons/io";
import { UserContext } from "../../../../App";
import { CiMapPin } from "react-icons/ci";
import { MdFilterListAlt } from "react-icons/md";
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom.js';
import PinchZoom from 'ol/interaction/PinchZoom.js';
import { LineString, Polygon } from "ol/geom";
import { ImBold } from "react-icons/im";
import UsersApi from "../../../../API/UsersApi";
import { getDistance } from "ol/sphere";
import { render } from "@testing-library/react";
import { getVectorContext } from "ol/render";
// import MapHelper from "../MapHelper";
export const Map = ({
  fiberHouseClicked,
  currentPositionLayer,
  setCurrentPositionLayer,
  candidateClicked
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const user = useContext(UserContext);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
      console.log(screenWidth, screenHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [map, setMap] = useState();
  //const [cluster, setCluster] = useState(null);
  const [clusterLayer, setClusterLayer] = useState(null);
  // const [circleLayers, setCircleLayers] = useState([])
  const [isDrawingToolActive, setisDrawingToolActive] = useState(false);
  // const []
  const {
    setMapObject,
    mapIcons,
    index,
    setIndex,
    selectedFiberHouse,
    fiberHouses,
    setOpenMobileMenu,
    // setFiberHouses,
    // assignedLeadsEnabled,
    // unassignedLeadsEnabled,
    // usingLight,
    setSelectedFiberHouseFeature,
    // selectedFiberHouseFeature,
    setFiberHouseIds,
    // totalFiberHouses,
    // setTotalFiberHouses,
    searchAreaFeature,
    servicableAreas,
    shouldReRenderMap,
    cluster,
    locationHistories,
    playTime,
    pathFeature,
    setPathFeature,
    targetUserId,
    userPathColor,
    toggleState,
    activityCoords,
    repLatestLocation,
    candidates,
    setSelectedCandidateFeature
  } = useContext(MP);
  const [satelliteMode, setSatelliteMode] = useState(true);
  const [addedServicableAreas, setAddedServicableAreas] = useState([]);
  const fiberHousesRef = useRef();
  const selectedFiberHouseRef = useRef();
  selectedFiberHouseRef.current = selectedFiberHouse;
  fiberHousesRef.current = fiberHouses;
  //Displaying all fiber houses on the map
  const indexRef = useRef();
  const [zoom, setZoom] = useState(0);
  const [pathLayer, setPathLayer] = useState(null);
  const initialRender = useRef(true);
  const candidateLayerRef = useRef(null);

  function handleZoomChange(event) {
    if (map) {
      if (map.getView().getZoom() >= 15.6) {
        setZoom("ZoomIn")

      }
      else {
        setZoom("ZoomOut")

      }
    }
    console.log(zoom)
  }

  useEffect(() => {
    if (map) {
      map.getView().on("change:resolution", handleZoomChange);
    }
  }, [map]);


  useEffect(
    () => {
      if (clusterLayer && zoom === "ZoomIn") {
        clusterLayer?.setDistance(2)
        // showPathLayer.current = true;
        // setClusterDistance(0)
        // change the color of the circle to purple
      }
      else if (clusterLayer && zoom === "ZoomOut") {
        clusterLayer?.setDistance(50)

        // setClusterDistance(50)
      }
    }, [zoom]
  )

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }
    else {
      if (map) {
        console.log(activityCoords)
        console.log(map)
        if (activityCoords) {
          map.getView().setCenter(fromLonLat([activityCoords.long, activityCoords.lat]));
          map.getView().setZoom(20)
        }

      }

    }
    ;
  }, [activityCoords])


  useEffect(() => {
    indexRef.current = index;
  }, [index]);

  function binarySearchFiberHouses(leads, targetId) {
    let left = 0;
    let right = leads.length - 1;
    console.log(leads)
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const midId = leads[mid].id;
      console.log(midId, targetId)
      if (midId < targetId) {
        left = mid + 1;
      } else if (midId > targetId) {
        right = mid - 1;
      } else {
        console.log("Target found")
        return mid; // Found the target, return its index
      }
    }
    console.log("Target not found")
    return -1; // Target not found
  }

  // useEffect(
  //   () => {
  //     if (map) {
  //       console.log("Map is set")
  //       const view = map.getView();
  //       view.on('change', function () {

  //         handleChange(view)
  //       })
  //     }
  //   }, [map, fiberHouses]
  // )


  async function resetIconStyle(vectorSource) {
    if (selectedFiberHouseRef.current) {
      // eslint-disable-next-line array-callback-return
      vectorSource.getFeatures().map((fea) => {
        if (fea.get("id") === selectedFiberHouseRef.current.id) {
          // const fiberHouseIndex =  binarySearchFiberHouses(fiberHousesRef.current, selectedFiberHouseRef.current.id);
          let houseMapIcon = mapIcons.find(
            (mi) => mi.id === fea.get("mapIconId")?.mapIconId
          );

          const newStyle = new Style({
            image: new Icon({
              scale: 0.5, // Adjust this value to change the scale of the icon
              src: "data:image/png;base64," + houseMapIcon?.image,
            }),
          });

          // Apply the circle style to the feature
          fea.setStyle([
            newStyle, // This is the icon style
          ]);
          // zoom the map a little bit to make the icon visible
          map.getView().setZoom(map.getView().getZoom() - 0.0000001);
        }
      });
      // console.log(vectorSource.getFeatures())
      // const feature = binarySearchFeatureById(vectorSource.getFeatures(), selectedFiberHouseRef.current.id);
      // console.log(feature)
      // if (feature) {
      //   const fiberHouse = fiberHousesRef.current.find(
      //     (fh) => fh.id === selectedFiberHouseRef.current.id
      //   );
      //   let houseMapIcon = mapIcons.find(
      //     (mi) => mi.id === fiberHouse?.mapIconId
      //   );
      //   const newStyle = new Style({
      //     image: new Icon({
      //       scale: 0.5, // Adjust this value to change the scale of the icon
      //       src: "data:image/png;base64," + houseMapIcon?.image,
      //     }),
      //   });

      //   // Apply the circle style to the feature
      //   feature.setStyle([
      //     newStyle, // This is the icon style
      //   ]);
      // }
    }
  }

  useEffect(() => {
    if (map && candidates) {
      console.log("Candidates:", candidates);
      if (candidateLayerRef.current) {
        candidateLayerRef.current.getSource().getSource().clear();
        map.removeLayer(candidateLayerRef.current);
        candidateLayerRef.current = null;
        map.render();
      }
      if (candidates.length > 0) {
        const features = candidates.map((cand) => {
          const coordinate = [cand.coordinates.longitude, cand.coordinates.latitude];
          const feature = new Feature({
            geometry: new Point(fromLonLat(coordinate)),
            data: {
              id: cand.id,
              mapIconId: cand.mapIconId,
              selected: cand.selected,
              isHighlighted: cand.isHighlighted,
            },
          });
          return feature;
        });

        const vectorSource = new VectorSource({
          features: features,
        });

        const clusterSource = new Cluster({
          distance: 50,
          source: vectorSource,
        });

        const styleCache = {};

        const vectorLayer = new VectorLayer({
          source: clusterSource,
          style: (feature) => {
            const size = feature.get("features").length;
            let style = styleCache[size];

            if (size === 1) {
              const singleFeature = feature.get("features")[0];
              const candidateData = singleFeature.get("data");
              console.log("Candidate Data:", candidateData);
              let iconUrl = mapIcons.find((icon) => icon.id === candidateData.mapIconId)?.image;
              if (candidateData.selected) {
                style = [
                  new Style({
                    image: new Icon({
                      src: "data:image/png;base64," + iconUrl,
                      scale: 0.5,
                    }),
                  }),
                  new Style({
                    image: new CircleStyle({
                      radius: 15,
                      stroke: new Stroke({
                        color: "blue",
                        width: 4,
                      }),
                      fill: new Fill({
                        color: "rgba(0, 0, 0, 0)", // Transparent fill
                      }),
                    }),
                  }),
                ];
              } else if (candidateData.isHighlighted) {
                style = [
                  new Style({
                    image: new Icon({
                      src: "data:image/png;base64," + iconUrl,
                      scale: 0.5,
                    }),
                  }),
                  new Style({
                    text: new Text({
                      text: "Highlighted",
                      fill: new Fill({
                        color: "#fff",
                      }),
                      font: "bold 12px Arial",
                      offsetY: -10,
                    }),
                  }),
                ];
              } else {
                style = new Style({
                  image: new Icon({
                    src: "data:image/png;base64," + iconUrl,
                    scale: 0.3,
                  }),
                });
              }
            } else {
              style = new Style({
                image: new CircleStyle({
                  radius: 22,
                  stroke: new Stroke({
                    color: "#fff",
                    width: 3,
                  }),
                  fill: new Fill({
                    color: map.getView().getZoom() > 15 ? "#800080" : "#2761D0",
                  }),
                }),
                text: new Text({
                  text: size.toString(),
                  fill: new Fill({
                    color: "#fff",
                  }),
                  font: "bold 12px Arial",
                  scale: 1
                }),
              });
            }

            styleCache[size] = style;
            return style;
          },
        });

        map.addLayer(vectorLayer);
        candidateLayerRef.current = vectorLayer;
      }
    }
  }, [candidates, map, mapIcons]);


  const handleCandidateMapClick = useCallback(
    (event) => {
      if (isDrawingToolActive) return;

      let candidateFound = false;
      map.forEachFeatureAtPixel(event.pixel, (feature) => {
        const size = feature.get("features")?.length;
        const features = feature.get("features");
        console.log("Features:", features);
        console.log("ZOOM:", zoom);
        console.log("SIZE:", size);
        if (size > 1) {
          // Handle clustered features
          features.forEach((singleFeature) => {
            const candidateData = singleFeature.get("data");
            if (candidateData && candidateData.id) {
              candidateClicked(candidateData);
              candidateFound = true;
            }
          });
        } else if ((zoom === "ZoomIn" && feature.get("data")) || (size === 1 && features[0].get("data"))) {
          console.log(features)
          const candidateData = features[0];
          console.log("Candidate Data:", candidateData);
          setSelectedCandidateFeature(candidateData);
          candidateClicked(candidateData.get("data"));
          candidateFound = true;

        }

        return candidateFound;
      });

      if (!candidateFound) {
        console.log("No candidate found at clicked location.");
      }
    },
    [map, candidateClicked, isDrawingToolActive]
  );

  // Attach and detach the click event on map
  useEffect(() => {
    if (map) {
      map.on("click", handleCandidateMapClick);

      return () => {
        map.un("click", handleCandidateMapClick); // Clean up on unmount
      };
    }
  }, [map, handleCandidateMapClick]);



  useEffect(() => {
    if (!map) return
    if (!locationHistories) return
    if (!targetUserId) return
    const createdOverlays = new Set();

    if (pathLayer) {

      map.getOverlays().forEach((overlay) => {
        if (overlay?.get("name") === "image") {
          map.removeOverlay(overlay);
        }
      });
      pathLayer.getSource().forEachFeature((feature) => {
        // if (feature.get("isLast")) {
        pathLayer.getSource().removeFeature(feature);
        // }
      });
      pathLayer.getSource().clear();
      map.removeLayer(pathLayer);
      map.render()
      setPathLayer(null);
    }

    let coordinatesList = []
    let features = [];
    let repData = locationHistories.find(u => u.userId === targetUserId)
    console.log(repData)
    const randomColor = (repData?.pathColor !== null ? `${repData?.pathColor}` : userPathColor);
    if (toggleState.path) {
      if (!repData?.locations) return
      repData.locations.map((loc) => {
        const transformedCoordinates = fromLonLat([loc.lon, loc.lat]);
        coordinatesList.push(transformedCoordinates);
      })

      let line = new LineString(coordinatesList)


      const routeFeature = new Feature({
        type: 'route',
        geometry: line,
      });

      const startMarker = new Feature({
        type: 'icon',
        geometry: new Point(line.getFirstCoordinate()),
      });
      const endMarker = new Feature({
        type: 'icon',
        geometry: new Point(line.getLastCoordinate()),
      });
      const position = startMarker.getGeometry().clone();
      const geoMarker = new Feature({
        type: 'geoMarker',
        geometry: position,
      });


      const styles = {
        'route': new Style({
          stroke: new Stroke({
            width: 6,
            color: randomColor,
          }),
        }),
        'icon': new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: 'data/icon.png',
          }),
        }),
        'geoMarker': new Style({
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({ color: 'black' }),
            stroke: new Stroke({
              color: 'white',
              width: 2,
            }),
          }),
        }),
      };



      const vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [routeFeature, geoMarker, startMarker, endMarker],
        }),
        style: function (feature) {
          return styles[feature.get('type')];
        },
      });



      map.addLayer(vectorLayer);
      map.getView().setCenter(line.getFirstCoordinate());
      map.getView().setZoom(16);
      setPathLayer(vectorLayer)

      const speedBtn = document.getElementById('speedBtn');
      const seekerInput = document.getElementById('seeker')
      const startButton = document.getElementById('PlayPauseBtn')
      const panSwitch = document.getElementById('panSwitch')
      const zoomInBtn = document.getElementById('zoomInBtn')
      const zoomOutBtn = document.getElementById('zoomOutBtn')


      let animating = false;
      let distance = 0;
      let lastTime;

      function moveFeature(event) {
        const speed = Number(speedBtn.value);
        const zoom = Number(zoomInBtn.value) || Number(zoomOutBtn.value)
        const isPan = Number(panSwitch.value)
        const time = event.frameState.time;
        const elapsedTime = time - lastTime;
        distance = (distance + (speed * elapsedTime) / 1e6) % 2;
        lastTime = time;

        const currentCoordinate = line.getCoordinateAt(
          distance > 1 ? 2 - distance : distance,
        );
        let index = distance > 1 ? 2 - distance : distance;
        let length = line.getCoordinates().length;
        seekerInput.value = index
        seekerInput.addEventListener('input', function (e) {
          console.log(e.target.value)
          stopAnimation()
          distance = parseFloat(e.target.value)
          startAnimation()
        })
        console.log(length)
        console.log(index)
        position.setCoordinates(currentCoordinate);
        console.log(isPan)
        if (isPan === 1) {
          map.getView().setCenter(currentCoordinate);
        }
        console.log(zoom)
        map.getView().setZoom(zoom);
        const vectorContext = getVectorContext(event);
        vectorContext.setStyle(styles.geoMarker);
        vectorContext.drawGeometry(position);
        // tell OpenLayers to continue the postrender animation
        map.render();
      }

      function startAnimation() {
        animating = true;
        lastTime = Date.now();
        startButton.innerHTML = `
        <img src="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='currentColor'><rect x='6' y='4' width='4' height='16'></rect><rect x='14' y='4' width='4' height='16'></rect></svg>" 
        alt="Pause Icon" style="vertical-align: middle; margin-right: 4px;">
      `;
        vectorLayer.on('postrender', moveFeature);

        geoMarker.setGeometry(null);
      }

      function stopAnimation() {
        animating = false;
        startButton.innerHTML = `
        <img src="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='currentColor'><path d='M8 5v14l11-7z'></path></svg>" 
        alt="Play Icon" style="vertical-align: middle; margin-right: 4px;">
      `;

        geoMarker.setGeometry(position);
        vectorLayer.un('postrender', moveFeature);
      }

      startButton.addEventListener('click', function () {
        if (animating) {
          stopAnimation();
        } else {
          startAnimation();
        }
      });
    } else if (toggleState.currentLocation) {
      let currentLocation = repLatestLocation.find(u => u.userId === targetUserId);
      const transformedCoords = fromLonLat([currentLocation.longitude, currentLocation.latitude]);
      let locFeature = new Feature({
        geometry: new Point(transformedCoords),
        data: {
          info: `TimeStamp: ${new Date(currentLocation.recordedAt).toLocaleTimeString()}`,
          name: repData.name,
          repId: repData.userId
        },
        isPath: true,
        isLast: true,
        repProfile: repData.profile,
        repId: repData.userId,
      })
      features.push(locFeature);

      const vectorSource = new VectorSource({
        features: features,
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: function (feature) {
          if (feature.get('isLast') && !createdOverlays.has(feature)) {
            console.log('Creating overlay for the last feature');
            createOverlay(feature); // Create overlay only once
            createdOverlays.add(feature); // Mark this feature as processed
          }
        }
      })
      map.addLayer(vectorLayer)
      setPathLayer(vectorLayer)
      map.getView().setCenter(transformedCoords);
      map.getView().setZoom(20);

    } else if (toggleState.activity) {
      repData.locations.map((loc, index) => {
        let isLast = index == repData.locations.length - 1;

        const transformedCoords = fromLonLat([loc.lon, loc.lat]);
        let feature = new Feature({
          geometry: new Point(transformedCoords),
          data: {
            info: `TimeStamp: ${new Date(loc.timestamp).toLocaleTimeString()}`,
            name: repData.name,
            repId: repData.userId,
            activity: loc.activityHistory ?
              loc.activityHistory
              : null,
          },
          isPath: true,
          isLast: isLast,
          repProfile: isLast ? repData.profile : null,
          isActivity: !!loc.activityHistory,
          activityHistory: loc.activityHistory || null,
          repId: isLast ? repData.userId : null,
          pathColor: randomColor
        });

        if (loc.activityHistory) {
          features.push(feature)
        }
        coordinatesList.push(transformedCoords);
      })

      console.log(features)

      let featureLine = new Feature({
        geometry: new LineString(coordinatesList),
        randomColor: randomColor
      })

      features.push(featureLine)
      const vectorSource = new VectorSource({
        features: features,
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: function (feature) {
          if (feature.get("isActivity")) {
            return new Style({
              image: new CircleStyle({
                radius: 7, // Larger circle for activities.
                fill: new Fill({ color: '#2761D0' }), // Tomato color.
                stroke: new Stroke({ color: '#fff', width: 1 }),
              }),
            })
          }

        }
      });
      map.addLayer(vectorLayer)
      setPathLayer(vectorLayer)
      const popup = new Overlay({
        element: document.getElementById('popup'),
        positioning: 'bottom-center',
        stopEvent: false,
        offset: [0, -10],
      });

      map.addOverlay(popup);
      handleClickMap(popup);
    }



  }, [locationHistories, toggleState, targetUserId])


  const handleClickMap = (popup) => {
    map.on('click', (event) => {
      map.forEachFeatureAtPixel(event.pixel, (feature) => {
        const coordinates = feature.getGeometry().getCoordinates();

        // if (feature.get('isLast')) {
        //   showRepPath.current = feature.get('repId');
        //   console.log("last Clicked")
        // }

        // if (feature.get('isPath')) {
        //   const info = feature.get('data').info;
        //   popup.setPosition(coordinates);
        //   document.getElementById('popup-content').innerHTML = info;
        //   document.getElementById('popup').style.display = 'block';

        // }

        if (feature.get('isActivity')) {
          const activity = feature.get('data').activity;
          console.log(activity)
          popup.setPosition(coordinates);
          let iconFrom = "data:image/png;base64," + mapIcons.find(icon => icon.id === activity.iconFromId)?.image;
          let iconTo = "data:image/png;base64," + mapIcons.find(icon => icon.id === activity.iconToId)?.image;
          let nameOfCustomer = activity?.lead?.name ? activity?.lead?.name : "No Name";
          let date = new Date(activity.timestamp).toLocaleDateString();
          let time = new Date(activity.timestamp).toLocaleTimeString();
          function onClick() {
            document.getElementById('popup').style.display = 'none';
          }

          const snapToLocation = () => {
            fiberHouseClicked(activity.lead.fiberHouse);
            let lonlat = [activity.lead.fiberHouse.coordinates.longitude, activity.lead.fiberHouse.coordinates.latitude]
            map.getView().setCenter(fromLonLat(lonlat));
            map.getView().setZoom(21);

          }

          console.log(document.getElementById('popup-content'))
          if (document.getElementById('popup-content') != null) {
            document.getElementById('popup-content').innerHTML = `<div class="w-32">
      <p class="text-sm text-red-500"  id="close_button" >Close</p>
      <p class=" text-center font-bold mb-2">${nameOfCustomer}</p>
      <p class="text-grey text-center">${date}</p>
      <p class="text-grey text-center">${time}</p>
      <div class="flex items-center justify-center gap-4">
      <img class="w-8 h-8" src=${iconFrom} alt="activity" />
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
      <img class="w-8 h-8" src=${iconTo} alt="activity" />
      </div>
      <p id="snap_button" class="text-center underline text-[#2761D0]" >Go To Lead</p>
      </div>`
            document.getElementById('popup').style.display = 'block';
            document.getElementById('close_button').addEventListener('click', onClick);
            document.getElementById('snap_button').addEventListener('click', snapToLocation);
          }



        }

      });
    });
  }




  function createOverlay(feature) {
    const imgSrc = feature.get('repProfile');
    const coordinates = feature.getGeometry().getCoordinates();
    const name = feature.get('data').name || 'Unnamed';
    const overlayElement = document.createElement('div');
    overlayElement.style.width = '100px';
    overlayElement.style.height = '100px';
    overlayElement.style.borderRadius = '50%';
    overlayElement.style.display = 'flex';
    overlayElement.style.flexDirection = 'column';
    overlayElement.style.alignItems = 'center';
    overlayElement.style.objectFit = 'cover';

    const nameLabel = document.createElement('div');
    nameLabel.textContent = name;
    nameLabel.style.fontSize = '12px';
    nameLabel.style.fontWeight = 'bold';
    nameLabel.style.marginBottom = '5px';
    nameLabel.style.color = '#fff';
    nameLabel.style.textShadow = '0px 0px 5px rgba(0, 0, 0, 0.7)';

    const imgElement = document.createElement('img');
    imgElement.src = imgSrc;
    imgElement.onclick = () => {
     // setShowRepPath(feature.get('repId'));
      console.log("Image Clicked")
    }
    imgElement.style.width = '50%';
    imgElement.style.height = '50%';
    imgElement.style.objectFit = 'cover';
    imgElement.style.borderRadius = '50%';

    overlayElement.appendChild(nameLabel);
    overlayElement.appendChild(imgElement);

    const overlay = new Overlay({
      element: overlayElement,
      positioning: 'center-center',
      stopEvent: false,
      offset: [0, 0]
    });

    overlay.set("name", "image")

    map.addOverlay(overlay);

    overlay.setPosition(coordinates); // Set the overlay at feature coordinates
  }


  // useEffect(
  //   () => {
  //     if (map && pathFeature) {
  //       pathFeature.forEach((coordinatesList) => {
  //         if (coordinatesList.length > 0) {
  //           const marker = new Feature({
  //             geometry: new Point(coordinatesList[0]),
  //           });

  //           const markerStyle = new Style({
  //             image: new CircleStyle({
  //               radius: 15,
  //               fill: new Fill({ color: 'red' }),
  //               stroke: new Stroke({ color: 'black', width: 2 })
  //             })
  //           });

  //           marker.setStyle(markerStyle);
  //           pathLayer.getSource().addFeature(marker);
  //           let index = 0;
  //           const speed = 100; // speed of the animation
  //           console.log(coordinatesList)
  //           function animateMarker() {
  //             if (index < coordinatesList.length - 1) {
  //               index++;
  //               console.log(index)
  //               marker.getGeometry().setCoordinates(coordinatesList[index]);
  //               setTimeout(animateMarker, speed);
  //             }
  //           }

  //           animateMarker();
  //         }
  //       })
  //     }
  //   }, [playTime, pathFeature]
  // )


  useEffect(() => {
    if (!shouldReRenderMap.current) {
      shouldReRenderMap.current = true;
      return
    }
    if (fiberHouses && map) {
      if (cluster.current) {
        cluster.current.getSource().getSource().clear();
        cluster.current.getSource().clear();
        map.removeLayer(cluster.current);
        cluster.current = null;
        map.render()
        setClusterLayer(null)
      }
      console.log("FiberHOuses rendered")
      const features = [];
      const coordinates = [];

      for (let i = 0; i < fiberHouses.length; i++) {
        const fiberHouse = fiberHouses[i];

        if (!fiberHouse.remove) {
          let coordinate = [
            fiberHouse.coordinates.longitude,
            fiberHouse.coordinates.latitude,
          ];
          let feature = new Feature(new Point(fromLonLat(coordinate)));
          coordinates.push(coordinate);

          if (fiberHouse.id) {
            feature.set("id", fiberHouse.id);
            feature.set("fiber_house", true);
            feature.set("mapIconId", fiberHouse.mapIconId);
            feature.set("selected", fiberHouse.selected);
            feature.set("isBundled", fiberHouse.isBundled);
          } else {
            console.error("fiberHouse.id is not defined");
          }

          feature.set("isNewHouse", !!fiberHouse.isNewHouse);

          features.push(feature);
        }
      }
      // console.log("Duplicates: ", duplicates);
      const vectorSource = new VectorSource({
        features: features,
      });

      const clusterSource = new Cluster({
        distance: zoom === "ZoomIn" ? 1 : 50,
        source: vectorSource,

      });
      const styleCache = {};
      let clusters = null;

      clusters = new VectorLayer({
        source: clusterSource,
        name: "fiberHouses",
        style: function (feature) {
          const size = feature.get("features").length;



          console.log("size", size)
          let style = styleCache[size];
          if (size === 1) {

            let houseMapIcon = mapIcons.find(
              (mi) => mi.id === feature.get("features")[0]?.get("mapIconId")
            );


            let imgScale = 1;
            if (!houseMapIcon) {
              houseMapIcon = LoadingIcon;
              imgScale = 0.1;
            }
            else {
              houseMapIcon = "data:image/png;base64," + houseMapIcon?.image;
              imgScale = 0.5;
            }
            if (selectedFiberHouseRef.current && selectedFiberHouseRef.current.id === feature.get("features")[0].get("id")) {
              style = [
                new Style({
                  image: new Icon({
                    scale: 0.5, // Adjust this value to change the scale of the icon
                    src: houseMapIcon,
                  }),
                }),
                new Style({
                  image: new CircleStyle({
                    radius: 15, // This is now in pixels
                    stroke: new Stroke({
                      color: "black", // Border color
                      width: 4, // Border width
                    }),
                    fill: new Fill({
                      color: "rgba(0, 0, 0, 0)", // Transparent fill
                    }),
                  }),
                })
              ];
              console.log(feature.get("features")[0].get("isBundled"))
              if (feature.get("features")[0].get("isBundled")) {
                console.log("isBundled")
                style.push(
                  new Style({
                    text: new Text({
                      text: "TV",
                      fill: new Fill({
                        color: "#fff", // White text color
                      }),
                      font: "bold 12px Arial",
                      scale: 1.2,
                      textAlign: "center",
                      textBaseline: "middle",
                      offsetX: 10,
                      offsetY: 11,
                    }),
                  })
                )
              }
              return style;
            }

            // if (feature.get("features")[0].get("selected")) {
            //   style = [
            //     new Style({
            //       image: new Icon({
            //         src: houseMapIcon,
            //         scale: imgScale,
            //         // anchor: [0.5, 1],
            //       }),
            //     }),
            //     new Style({
            //       image: new CircleStyle({
            //         radius: 15, // This is now in pixels
            //         stroke: new Stroke({
            //           color: "blue", // Border color
            //           width: 4, // Border width
            //         }),
            //         fill: new Fill({
            //           color: "rgba(0, 0, 0, 0)", // Transparent fill
            //         }),
            //       }),
            //     }),
            //   ];

            //   return style;
            // }

            if (feature.get("features")[0].get("isBundled")) {
              style = [
                new Style({
                  image: new Icon({
                    src: houseMapIcon,
                    scale: imgScale,
                    anchor: [0.5, 1],
                  })
                }),
                new Style({
                  text: new Text({
                    text: "TV",
                    fill: new Fill({
                      color: "#fff", // White text color
                    }),
                    font: "bold 12px Arial", // Make the text bold and clear
                    scale: 1.2, // Scale the text for clarity
                    textAlign: "center", // Center align text
                    textBaseline: "middle", // Vertically center text
                    offsetX: 10, // Offset the text to match the circle's displacement
                    offsetY: -2, // Offset to ensure "B" stays in the middle of the circle
                  }),
                })
              ]
              return style;
            }

            style = new Style({
              image: new Icon({
                src: houseMapIcon,
                scale: imgScale,
                anchor: [0.5, 1],
              })
            });





          }
          else {
            style = new Style({
              image: new CircleStyle({
                radius: 22,
                stroke: new Stroke({
                  color: "#fff",
                  width: 3,
                }),
                fill: new Fill({
                  color: map.getView().getZoom() > 15.6 ? "#800080" : "#2761D0",
                }),
              }),
              text: new Text({
                text: size.toString(),
                fill: new Fill({
                  color: "#fff",
                }),
                font: "bold 10px Arial",
                scale: 1.2,
              }),
            });

          }

          return style;
        },
      });


      cluster.current = clusters;
      setClusterLayer(clusterSource);
      clusters.setZIndex(11000);
      map.addLayer(clusters);
      // map.addInteraction(modify);


      console.log("fiberHouses", fiberHouses.filter(house => house.id === selectedFiberHouse?.id))


    }
  }, [fiberHouses, mapIcons]);

  useEffect(
    () => {
      if (map) {
        map.on("click", handleMapClick);

        return () => {
          map.un("click", handleMapClick);
        }
      }
    }, [map, isDrawingToolActive]
  )

  const handleMapClick = (event) => {
    mapClickHandler(event);
  }

  function mapClickHandler(event) {
    let count = 0;
    map.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
      const size = feature?.get("features")?.length;
      const originalFeatures = feature.get("features");
      // console.log(feature.get("features"));
      // disable the cluster click and the lead main lead card when drawing toolis active
      // console.log(feature)
      if (count > 0) {
        return;
      }

      if (isDrawingToolActive) {
        return
      }

      if (Object.keys(feature.values_)[0] === "stat" && !isDrawingToolActive) {
        console.log("stat area cliked");
        console.log(feature)
        setIndex(8)
        count++;
        return;
      }

      if (size > 1) {
        // const coordinates = feature.getGeometry().getCoordinates();
        console.log(isDrawingToolActive)
        if (originalFeatures.length > 5000) {

          Swal.fire({
            icon: "warning",
            title: "Too Many Houses",
            text: "Limit is 5000 houses per cluster.",
            toast: true,
            position: "top-end",
            timer: 1500,
            showConfirmButton: false

          });
          return;
        }



        let fiberHouseIds = []
        originalFeatures.map(f => {
          fiberHouseIds.push(f.get("id"))
        });

        setFiberHouseIds(fiberHouseIds)
        setIndex(8)
        count++;
        return;
        // console.log("cLICKED");
      } else if (
        (zoom === "ZoomIn" && feature.get("fiber_house")) ||
        (size === 1 && originalFeatures[0].get("fiber_house"))
      ) {
        let selectedFeature = null;
        let fiberHouseId = null;
        console.log("Fiber House Clicked");
        // console.log(zoom);
        // if (zoom === "ZoomIn") {
        //   fiberHouseId = feature.get("id");
        //   selectedFeature = feature;
        //   // console.log(feature.get("id"));
        // } else {
        fiberHouseId = originalFeatures[0].get("id");
        selectedFeature = originalFeatures[0];
        // console.log(originalFeatures[0].get("id"));
        // }
        // const fiberHouseIndex = binarySearchFiberHouses(fiberHousesRef.current, fiberHouseId);

        // const fiberHouseIndex = binarySearch(fiberHousesRef.current, fiberHouseId);
        // const fiberHouse = fiberHousesRef.current[fiberHouseIndex];
        // console.log("Clicked");

        if (!isNaN(fiberHouseId)) {
          setSelectedFiberHouseFeature(selectedFeature);
          fiberHouseClicked({
            id: fiberHouseId,
            mapIconId: selectedFeature.get("mapIconId"),
          });
          // console.log(fiberHouse);
          let houseMapIcon = mapIcons.find(
            (mi) => mi.id === selectedFeature.get("mapIconId")
          );

          const newStyle = new Style({
            image: new Icon({
              scale: 0.5, // Adjust this value to change the scale of the icon
              src: "data:image/png;base64," + houseMapIcon?.image,
              // height:30,
              // width:30
            }),
          });
          if (clusterLayer)
            resetIconStyle(clusterLayer?.getSource());
          // Apply the new style to the feature
          const circleStyle = new Style({
            image: new CircleStyle({
              radius: 15, // This is now in pixels
              stroke: new Stroke({
                color: "black", // Border color
                width: 4, // Border width
              }),
              fill: new Fill({
                color: "rgba(0, 0, 0, 0)", // Transparent fill
              }),
            }),
          });
          let modify = null;
          if (zoom === "ZoomIn") {
            modify = new Modify({
              features: new Collection([feature]),
              hitDetection: new Collection([feature]),
              style: null
            });
          } else {
            modify = new Modify({
              features: new Collection([originalFeatures[0]]),
              hitDetection: new Collection([originalFeatures[0]]),
              style: null
            });
          }
          // const modify = new Modify({
          //     features: new Collection([originalFeatures[0]]),
          //     hitDetection: new Collection([originalFeatures[0]]),
          // });
          map.getInteractions().forEach(interaction => {
            if (interaction instanceof Modify) {
              console.log("clearing")
              map.removeInteraction(interaction)
            }
          })
          modify.on("modifyend", async function (event) {
            // Swal.fire({
            //   icon: "info",
            //   title: "Please Wait",
            //   text: "Moving Fiber House...",
            //   toast: true,
            //   position: "bottom-end",
            //   didOpen: () => {
            //     Swal.showLoading();
            //   },
            // });
            const coordinates = event.features
              .getArray()[0]
              .getGeometry()
              .getCoordinates();
            const req = new EditFiberHouseReq();
            req.id = selectedFeature.get("id");

            req.coordinate = {
              latitude: toLonLat(coordinates)[1],
              longitude: toLonLat(coordinates)[0],
            };
            req.salesRepId = user?.id;
            console.log(req);
            const res = await FiberHouseApi.EditFiberHouse(req);
            console.log(res);
            if (res?.status?.success) {
              console.log("Fiber House Moved Successfully");

            } else {
              console.log("Fiber House Move Failed");
              Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message || "Fiber House Move Failed",
                didOpen: () => {
                  Swal.hideLoading();
                },
              });
            }
          });
          map.addInteraction(modify);

          // Apply the circle style to the feature
          feature.setStyle([
            newStyle, // This is the icon style
            circleStyle, // This is the circle style
          ]);
          map.getView().setZoom(map.getView().getZoom() - 0.0000001);
        }
        count++;
        return;
      }



    });
  }

  // Map INIT
  useEffect(() => {
    setMap(
      new OlMap({
        layers: [
          new OlLayerTile({
            name: "OSM",
            source: new OlSourceOsm(),
          }),
        ],
        view: new OlView({
          center: fromLonLat([-98.5795, 39.8283]),
          zoom: 4,
          zoomDelta: 0.5,

        }),

      })

    );

  }, []);


  useEffect(
    () => {
      if (map) {
        const mouseWheelZoom = new MouseWheelZoom({
          duration: 100,
          constrainResolution: true,
          maxDelta: 1.5,
          timeout: 50
        })
        map.addInteraction(mouseWheelZoom);
        const pinchZoom = new PinchZoom({
          duration: 200 // Adjust this value for faster zooming
        });

        map.addInteraction(pinchZoom);



      }
    }, [map]
  )


  //Set Context Map Object
  useEffect(() => {
    if (map) {
      setMapObject(map);
      console.log("Map Object Set");
    }
  }, [map]);

  //Set Map Center to current location




  function makeTransparent(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  function getPolygonArea(latLngs) {
    const numPoints = latLngs.length;

    if (numPoints < 3) {
      return 0; // A polygon with less than 3 vertices has no area
    }

    let area = 0;

    for (let i = 0; i < numPoints; i++) {
      const [xi, yi] = latLngs[i];

      const nextIndex = (i + 1) % numPoints;
      const [xj, yj] = latLngs[nextIndex];

      area += xi * yj - xj * yi;
    }

    area = Math.abs(area) / 2;

    return area;
  }

  // Assuming a tooltip element with the ID 'map-tooltip' exists in your HTML
  // If not, create one and style it appropriately with CSS

  // useEffect(() => {
  //   if (map && servicableAreas && servicableAreas.length > 0) {
  //     console.log("Adding Layer");
  //     if (addedServicableAreas.length > 0) {
  //       addedServicableAreas?.map((layer) => {
  //         map.removeLayer(layer);
  //       });
  //       setAddedServicableAreas([]);
  //     }
  //     const layers = [];
  //     const sortedServicableAreas = servicableAreas.sort(
  //       (a, b) => getPolygonArea(b.latlngs) - getPolygonArea(a.latlngs)
  //     );
  //     sortedServicableAreas.map((area, ind) => {
  //       const coordinates = area.latlngs.map((latlng) => {
  //         return fromLonLat([latlng[1], latlng[0]]);
  //       });
  //       coordinates.push(coordinates[0]);
  //       const polygon = new Polygon([[...coordinates]]);
  //       const polygonFeature = new Feature({
  //         geometry: polygon,
  //         name: area.name, // Store the area's name in the feature
  //       });

  //       // Style setup omitted for brevity

  //       const vectorLayer = new VectorLayer({
  //         source: new VectorSource({
  //           features: [polygonFeature],
  //         }),
  //       });
  //       layers.push(vectorLayer);
  //       vectorLayer.setZIndex(1010 + ind);
  //       map.addLayer(vectorLayer);
  //     });

  //     setAddedServicableAreas(layers);

  //     // Tooltip display logic
  //     const tooltipElement = document.getElementById('map-tooltip');
  //     map.on("click", function (event) {
  //       map.forEachFeatureAtPixel(event.pixel, function (feature) {
  //         if (feature.getGeometry().getType() === "Polygon") {
  //           const name = feature.get('name'); // Retrieve the area's name
  //           const coordinate = event.coordinate;
  //           const [x, y] = map.getPixelFromCoordinate(coordinate);
  //           tooltipElement.innerHTML = name; // Set the tooltip content
  //           tooltipElement.style.display = 'block';
  //           tooltipElement.style.left = `${x}px`;
  //           tooltipElement.style.top = `${y}px`;
  //         }
  //       });
  //     });

  //     // Hide the tooltip when clicking elsewhere
  //     map.on("pointermove", function () {
  //       tooltipElement.style.display = 'none';
  //     });
  //   }
  // }, [servicableAreas, map]);

  useEffect(() => {
    if (map && servicableAreas && servicableAreas.length > 0) {
      console.log("Adding Layer")
      if (addedServicableAreas.length > 0) {
        addedServicableAreas?.map((layer) => {
          map.removeLayer(layer);
        });
        setAddedServicableAreas([]);
      }
      const layers = [];
      const sortedServicableAreas = servicableAreas.sort(
        (a, b) => getPolygonArea(b.latlngs) - getPolygonArea(a.latlngs)
      );
      sortedServicableAreas.map((area, ind) => {
        // Create a polygon feature
        const coordinates = area.latlngs.map((latlng) => {
          return fromLonLat([latlng[1], latlng[0]]);
        });
        coordinates.push(coordinates[0]);
        const polygon = new Polygon([[...coordinates]]);
        const polygonFeature = new Feature({
          geometry: polygon,
        });

        const style = {
          Polygon: new Style({
            stroke: new Stroke({
              color: area.color,
              width: 1,
            }),
            fill: new Fill({
              color: makeTransparent(area.color, 0.15),
            }),
          }),
        };
        polygonFeature.setStyle(style["Polygon"]);
        // Create a vector source and add the feature to it
        const vectorSource = new VectorSource({

          features: [polygonFeature],
        });

        // Create a vector layer with the source
        const vectorLayer = new VectorLayer({
          source: vectorSource,
        });
        layers.push(vectorLayer);
        // Create a tile layer for the base map\
        vectorLayer.setZIndex(1010 + ind);

        map.addLayer(vectorLayer);

        if (
          user.userType === "SalesOrgAdmin" ||
          user.userType === "SuperAdmin"
        ) {
          map.on("click", function (event) {
            event.preventDefault();
            let count = 0;
            map.forEachFeatureAtPixel(event?.pixel, function (feature, layer) {
              if (feature?.getGeometry()?.getType() === "Point") {
                count++;
              } else {
                // if (
                //   count == 0 &&
                //   feature === polygonFeature &&
                //   indexRef.current !== 2
                // )
                // servicableAreaClicked(area.id, area.latlngs, area);
              }
            });
          });
        }
      });

      setAddedServicableAreas(layers);
    }
  }, [servicableAreas, map]);

  if (!map) {
    return null;
  }
  const setCurrentLocation = () => {
    console.log("Getting Current Location")

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const center = fromLonLat([
          position.coords.longitude,
          position.coords.latitude,
        ])
        // mapObject.getView().setCenter(center)
        // mapObject.getView().setZoom(18)
        try {
          map.getView().animate({ center: center, zoom: 18, duration: 500 });
        }
        catch (e) {
          console.error(e)
        }
      })
  };
  return (
    <div className="relative h-full">

      <MapContext.Provider value={map}>

        <SatelliteLayer satelliteMode={satelliteMode} map={map} />
        <MapComponent
          map={map}
          style={{
            height: screenWidth > 1000 ? "70vh" : "100%",
            overflow: "hidden",
            borderRadius: screenWidth > 1000 ? "10px" : "0px",
          }}
        />
        <div id="map-tooltip"></div>
        <div id="popup" class="ol-popup" style={{ display: "none" }}>
          <div id="popup-content"></div>
        </div>

        <div id="image-overlay" class="ol-popup" style={{ display: 'none', position: 'absolute' }}>
          <div id="image-overlay-content">
            <img id="overlay-image" src="" alt="Rep Profile" />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div
            style={{
              top: screenWidth > 1000 ? null : "7%",
              bottom: screenWidth > 1000 ? "5%" : null,
            }}
            className={`flex absolute ${screenWidth > 1000 ? "bottom-8" : ""
              } justify-center gap-4 items-center`}
          >
            {
              (searchAreaFeature.length === 0 || screenWidth < 1000) &&
              <>
                <button
                  onClick={() => {
                    setCurrentLocation();
                  }}
                  className={"TrackLocationButton"}
                  style={{
                    borderRadius: "50%",
                    padding: "0.5rem",
                  }}
                >
                  <BiCurrentLocation
                    color="#181E4B"
                    size={screenWidth > 1000 ? 30 : 20}
                  />
                </button>
                <button
                  onClick={() => {
                    setSatelliteMode(!satelliteMode);
                  }}
                  className={"TrackLocationButton"}
                  style={{
                    borderRadius: "50%",
                    padding: "0.5rem",
                  }}
                >
                  {satelliteMode ? (
                    <FaMap color="#181E4B" size={screenWidth > 1000 ? 30 : 20} />
                  ) : (
                    <FaSatelliteDish
                      color="#181E4B"
                      size={screenWidth > 1000 ? 30 : 20}
                    />
                  )}
                </button>
                <button
                  style={{
                    borderRadius: "50%",
                    padding: "0.5rem",
                  }}
                  onClick={() => {
                    setIndex(2);
                  }}
                  className="TrackLocationButton"
                >
                  <CiMapPin color="#181E4B" size={screenWidth > 1000 ? 30 : 20} />
                </button>

              </>}
            <SelectArea active={isDrawingToolActive} setActive={setisDrawingToolActive} screenWidth={screenWidth} />
            {
              (searchAreaFeature.length === 0 || screenWidth < 1000) &&
              <button
                style={{
                  borderRadius: "50%",
                  padding: "0.5rem",
                }}
                onClick={() => {
                  setIndex(7);
                }}
                className="TrackLocationButton"
              >
                <MdFilterListAlt
                  size={screenWidth > 1000 ? 30 : 20}
                  color="#181E4B"
                />
              </button>}
            {screenWidth > 1000 ? null : (
              <button
                style={{
                  borderRadius: "50%",
                  padding: "0.5rem",
                }}
                onClick={(e) => {
                  setOpenMobileMenu(e.currentTarget);
                }}
                className="TrackLocationButton"
              >
                <IoMdSettings
                  size={screenWidth > 1000 ? 30 : 20}
                  color="#181E4B"
                />
              </button>
            )}

          </div>
        </div>
      </MapContext.Provider>
    </div>
  );
};

// fiberHouses  | dispalyed on the map as features