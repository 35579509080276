import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Avatar,
    Button,
    TextField,
    IconButton,
    Modal,
    Box,
    Typography
} from '@mui/material';
import {
    Edit,
    LocationOn,
    AddCircle,
    RemoveCircle,
    ContentCopy,
    Delete,
    Email as EmailIcon,
    Phone,
    Send,
    SendOutlined
} from '@mui/icons-material';
import Call from '../../components/NewLeadCard/Images/Call.png';
import Email from '../../components/NewLeadCard/Images/Email.png';
import Message from '../../components/NewLeadCard/Images/Message.png';
import Directions from '../../components/NewLeadCard/Images/Directions.png';
import wa from '../../components/NewLeadCard/Images/whatsapp.svg';
import './CandidateMainLeadCard.scss';
import MapIconDisplay from '../MapIconDisplay';
import { UserContext } from '../../../../../App';
import ContactButtons from '../../../../../Services/ContactButtons';
import { MdDeleteOutline } from 'react-icons/md';
import StringIntReq from '../../../../../Requests/StringIntReq';
import FiberHouseApi from '../../../../../API/FiberHouseApi';
import VersatileLoader from '../../../../../components/VersatileLoader'
import { MapContext } from '../..';
import { IoCloseCircle } from 'react-icons/io5';
import UpdateCandidateAppointmentReq from '../../../../../Requests/FiberPlan/UpdateCandidateAppointmentReq';
import Swal from 'sweetalert2';
import { set } from 'ol/transform';
import EditCandidateReq from '../../../../../Requests/FiberHouse/EditCandidateReq';

const CandidateMainLeadCard = ({ selectedCandidate }) => {
    const [notes, setNotes] = useState(selectedCandidate?.notes);
    const [isEditing, setIsEditing] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [mapIcon, setMapIcon] = useState(null);
    const [appointmentDate, setAppointmentDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [candidateDetails, setCandidateDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [email, setEmail] = useState(selectedCandidate?.email);
    const [phone, setPhone] = useState(selectedCandidate?.phone);
    const [name, setName] = useState(selectedCandidate?.name);
    const shouldSaveAddress = useRef(false);
    const shouldSaveNotes = useRef(false);
    const initialRender = useRef(true)
    const [address, setAddress] = useState(candidateDetails?.zipCode + "," + candidateDetails?.city + "," + candidateDetails?.state + "," + candidateDetails?.country);
    let User = useContext(UserContext);
    let contactButtons = new ContactButtons(User);
    let { mapIcons } = useContext(MapContext)

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    console.log(selectedCandidate);


    const getCandidateLeadDetails = async (id) => {
        const req = new StringIntReq();
        req.int = id;
        const res = await FiberHouseApi.GetCandidateLeadDetails(req);
        if (res?.status?.success) {
            setCandidateDetails(res?.data);
            setLoading(false)
            setEmail(res.data.email)
            setPhone(res.data.phone)
            setName(res.data.name)
            setNotes(res.data.notes)
            setAddress(res.data.zipCode + "," + res.data.city + "," + res.data.state + "," + res.data.country)
            setAppointmentDate(res.data.appointmentDateTime)
            console.log(res.data)
        }

    }

    const updateAppointmentDate = async () => {
        handleCloseModal();
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Updating Appointment Date",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            }
        })
        const req = new UpdateCandidateAppointmentReq();
        req.candidateId = selectedCandidate.id;
        req.appointmentDateTime = appointmentDate;
        console.log(req)
        const res = await FiberHouseApi.UpdateCandidateLeadAppointment(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Appointment Date Updated Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            setReload(
                (prev) => {
                    return !prev
                }
            )
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message
            })
        }
    }

    function copyPhone() {
        navigator.clipboard.writeText(phone.slice(2));
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Phone Number Copied to Clipboard',
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        })
    }

    function copyEmail() {
        navigator.clipboard.writeText(email);
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Email Copied to Clipboard',
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        })
    }

    function copyAddress() {
        navigator.clipboard.writeText(address);
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Address Copied to Clipboard',
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        })
    }

    function copyNotes() {
        navigator.clipboard.writeText(notes);
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Notes Copied to Clipboard',
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        })
    }

    useEffect(() => {
        if (selectedCandidate != null) {
            console.log(selectedCandidate);
            setLoading(true);
            getCandidateLeadDetails(selectedCandidate.id);
            setMapIcon(selectedCandidate.mapIconId);
        }
    }, [selectedCandidate, reload]);

    const updateLead = async (type) => {

        let req = new EditCandidateReq();
        req.id = candidateDetails.id;

        switch (type) {
            case 'name':
                req.name = name;
                break;
            case 'email':
                req.email = email;
                break;
            case 'phone':
                req.phone = phone;
                break;
            case 'address':
                req.address = address;
            case 'notes':
                req.notes = notes;
            default:
                req.name = name;
                req.email = email;
                req.phone = phone;
                req.address = address;
                req.notes = notes;
        }
        req.salesRepId = User.id;
        let res = await FiberHouseApi.EditCandidateLead(req);
        if (res?.status?.success) {
            setReload(!reload);
        }
    }


    useEffect(
        () => {
            if (name !== candidateDetails?.name && name !== "") {
                const timer = setTimeout(() => {
                    updateLead('name')
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [name]
    )

    useEffect(
        () => {
            if (email !== candidateDetails?.email && email !== "") {
                const timer = setTimeout(() => {
                    updateLead('email')
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [email]
    )

    useEffect(
        () => {
            if (phone !== candidateDetails?.phone) {
                const timer = setTimeout(() => {
                    updateLead('phone')
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [phone]
    )

    useEffect(
        () => {
            if (initialRender.current) {
                initialRender.current = false;
                return;
            }
            else if (shouldSaveAddress.current && address !== candidateDetails?.address) {
                const timer = setTimeout(() => {
                    updateLead('address')
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [address]
    )

    useEffect(
        () => {
            if (initialRender.current) {
                initialRender.current = false;
                return;
            }
            else if (shouldSaveNotes.current && notes !== candidateDetails?.notes) {
                const timer = setTimeout(() => {
                    updateLead('notes')
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [notes]
    )





    if (loading) {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%"
            }}>
                <VersatileLoader size='large' color='#2761CF' />
            </div>
        )
    }


    return (
        <Card className="candidate-main-lead-card">
            <div className="top-actions">
                <div style={{
                    width: "fit-content",
                    cursor: "pointer",
                }}>
                    <MdDeleteOutline className='icon' size={25} color='red' />
                </div>
            </div>
            <CardHeader

                avatar={<img
                    src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === mapIcon)?.image}
                    alt="Map Icon"
                    style={{ width: "50px", height: "50px" }}
                />}
                title={
                    <input placeholder='No Name' value={name} onChange={(event) => {
                        setName(event.target.value)
                    }} className='LeadName' />
                }
                subheader={
                    <div className="location">
                        <span>                            <textarea rows={3} style={{
                            width: "100%",
                            outline: "none",
                        }} placeholder='Address' className='' value={address} onChange={(e) => {
                            setAddress(e.target.value)
                            shouldSaveAddress.current = true
                        }} /></span>
                        <IconButton size="small" onClick={() => copyAddress()}>
                            <ContentCopy fontSize="small" />
                        </IconButton>
                    </div>
                }
            />
            <CardContent>

                <div className="contact-info">

                    <div className="info-item">
                        <EmailIcon />
                        <span>
                            <input
                                placeholder='No Email'
                                className='email_phone_input'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{
                                    fontSize: "1rem"
                                }}

                            />
                        </span>
                        {email &&
                            <IconButton size="small" onClick={() => copyEmail()}>
                                <ContentCopy fontSize="small" />
                            </IconButton>
                        }
                    </div>
                    <div className="info-item">
                        <Phone />
                        <span>
                            <input
                                placeholder='No Phone'
                                className='email_phone_input'
                                value={phone}
                                style={{
                                    fontSize: "1rem"
                                }}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </span>
                        {phone &&
                            <IconButton size="small" onClick={() => copyPhone()}>
                                <ContentCopy fontSize="small" />
                            </IconButton>
                        }
                    </div>
                </div>

                <div className="appointment-section">
                    <Button color="primary" onClick={handleOpenModal}>Set Follow-Up</Button>
                    <Typography variant="body2">
                        {appointmentDate ?
                            <p>{new Date(appointmentDate).toLocaleDateString() + " " + new Date(appointmentDate).toLocaleTimeString()}</p>
                            : "No Appointment Set"}
                    </Typography>
                </div>
                {candidateDetails?.candidateInvitation ?
                    <div className="customer-status">
                        <div className="status-text">
                            <span className="check-icon">✓</span>
                            <span>Invitation Sent</span>
                        </div>
                    </div>
                    :
                    <div className="customer-status">
                        <div className="status-text">

                            <Button startIcon={<SendOutlined />} color="primary">Send Invitation</Button>
                        </div>
                    </div>
                }

                <div className="status-icons">
                    <MapIconDisplay isCandidate={true} removeLightningBolts={true} setMapIcon={setMapIcon} />
                </div>

                <div className="notes-section">
                    <div className="notes-header">
                        <h3>Notes</h3>
                        <IconButton onClick={() => copyNotes()}>
                            <ContentCopy />
                        </IconButton>
                    </div>
                    <TextField
                        multiline
                        rows={4}
                        value={notes}
                        ref={shouldSaveNotes}
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder="Add notes here..."
                        fullWidth
                    />
                </div>

                <div className="communication-buttons">
                    <div className="MainLeadCard_Button">
                        <img src={Email} alt="Email" />
                        <span>Email</span>
                    </div>
                    <div className="MainLeadCard_Button">
                        <img src={Message} alt="Message" />
                        <span>SMS</span>
                    </div>
                    <div className="MainLeadCard_Button">
                        <img src={wa} alt="Message" />
                        <span>WA</span>
                    </div>
                    <div className="MainLeadCard_Button">
                        <img src={Call} alt="Call" />
                        <span>Call</span>
                    </div>
                    <div className="MainLeadCard_Button">
                        <img src={Directions} alt="Directions" />
                        <span>Directions</span>
                    </div>
                </div>
            </CardContent>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='custom_modal'>


                    <IoCloseCircle onClick={() => {
                        handleCloseModal()
                    }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                    <h1 >Appointment Date</h1>
                    <input type="datetime-local" value={appointmentDate} onChange={(e) => {
                        setAppointmentDate(e.target.value)
                    }} />
                    <button
                        onClick={() => {
                            updateAppointmentDate()
                        }
                        }>Update</button>
                </div>
            </Modal>
        </Card>
    );
};

export default CandidateMainLeadCard;