import React, { useEffect, useState } from 'react'
import { Modal } from '@mui/material'
import { IoCloseCircle } from 'react-icons/io5'
import Swal from 'sweetalert2'
import FiberHouseApi from '../../../../API/FiberHouseApi'
import UpdateLeadAppointmentReq from '../../../../Requests/Customer/UpdateLeadAppointmentReq'
import authorized from './../../Offer/components/authorized.png'
import authorizedFastwyre from './../../Offer/components/authorizedFastwyre.png'
const AppointmentForm = ({ fiberCompanyId,color, open, setOpen, lead }) => {

    const [appointmentDateTime, setAppointmentDateTime] = useState(lead?.appointmentDateTime)

    const updateAppointmentDate = async () => {
        setOpen(false)
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Updating Appointment Date",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        })
        const req = new UpdateLeadAppointmentReq();
        req.leadId = lead.id;
        req.appointmentDateTime = appointmentDateTime;
        req.appointmentType = null;
        const res = await FiberHouseApi.ScheduleAppointmentForProspect(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Appointment Date Updated Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                willClose: () => {
                    Swal.hideLoading()
                }
            })

        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message
            })
        }
    }


    return (
        <Modal setOpen={setOpen} open={open} onClose={() => {
            setOpen(false)
        }}>
            <div className='custom_modal'>
                <IoCloseCircle onClick={() => {
                    setOpen(false)
                }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color={"#2761d0"} />
                {
                    fiberCompanyId === 9 && <img alt="Logo" src={authorizedFastwyre} className='logo' />
                }
                {
                    fiberCompanyId === 2 && <img alt="Logo" src={authorized} className='logo' />
                }
                <h1 >Consultation Date</h1>
                <input type="datetime-local" value={appointmentDateTime} onChange={(e) => {
                    setAppointmentDateTime(e.target.value)
                }} />
                <button
                    onClick={() => {
                        updateAppointmentDate()
                    }
                    }>Schedule</button>
            </div>
        </Modal>
    )
}

export default AppointmentForm