import React, { useEffect, useState } from 'react'
import { Modal } from '@mui/material'
import './index.scss'
import { Link } from 'react-router-dom'
import { IoCloseCircle } from 'react-icons/io5'
import Swal from 'sweetalert2'
import CustomerApi from '../../API/CustomerApi'
import PhoneInput from 'react-phone-input-2'
const Index = ({ lead, customer, notes, open, setOpen, editable }) => {
    console.log(customer)

    let formData = lead?.formData ? JSON.parse(lead?.formData) : null
    const [editMode, setEditMode] = React.useState(false)

    const [name, setName] = React.useState(customer?.firstName + " " + customer?.lastName)
    const [address, setAddress] = React.useState(customer?.address)
    const [email, setEmail] = React.useState(customer?.email)
    const [phoneNumber, setPhoneNumber] = useState(customer?.cellPhone)
    const [customerNotes, setCustomerNotes] = React.useState(customer?.accountNotes)

    useEffect(() => {
        if (customer) {
            setEditMode(false)
            setName(customer?.firstName + " " + customer?.lastName)
            setAddress(customer?.address)
            setEmail(customer?.email)
            setCustomerNotes(customer?.accountNotes)
            setPhoneNumber(customer?.cellPhone)
        }
    }, [customer])

    function onCancel() {
        setEditMode(false)
        setName(customer?.firstName + " " + customer?.lastName)
        setAddress(customer?.address)
        setEmail(customer?.email)
        setCustomerNotes(customer?.accountNotes)
    }

    async function onSave() {
        Swal.fire({
            title: "Please wait",
            text: "Saving Customer Info",
            icon: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: 'custom_swal'
            },
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if(!phoneNumber){
            Swal.fire({
                title: "Error",
                text: "Phone Number is required",
                icon: "error",
                customClass: {
                    container: 'custom_swal'
                }
            })
            return
        }

        if (phoneNumber.length < 11) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone is invalid",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return
        }

        var pattern = new RegExp(/^\S+@\S+\.\S+$/);
        if(!email){
            Swal.fire({
                title: "Error",
                text: "Email is required",
                icon: "error",
                customClass: {
                    container: 'custom_swal'
                }
            })
        }

        if (!pattern.test(email)) {
            Swal.fire({
                title: "Error",
                text: "Invalid Email Address",
                icon: "error",
                customClass: {
                    container: 'custom_swal'
                }
            })
            return
        }

        let data = {
            customerId: customer?.id,
            firstName: name.split(" ")[0],
            lastName: name.split(" ")[1],
            address: address,
            accountNotes: customerNotes,
            phoneNumber: phoneNumber,
            email: email
        }

        const res = await CustomerApi.EditCustomerInfo(data)
        if (res.status.success) {
            Swal.fire({
                title: "Success",
                text: "Customer Info Saved Successfully",
                icon: "success",
                customClass: {
                    container: 'custom_swal'
                }
            })
            setEditMode(false)
        }
        else {
            Swal.fire({
                title: "Error",
                text: "Failed to save customer info",
                icon: "error",
                customClass: {
                    container: 'custom_swal'
                }
            })
        }

    }


    return (
        <Modal sx={{ outline: "none" }} open={open} onClose={() => {
            setOpen(false)
        }}>
            <div className='customer_info_model'>
                <IoCloseCircle onClick={() => {
                    setOpen(false)
                }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                <h2>Customer Info</h2>
                {
                    customer ?
                        <div className='customer_info'>
                            <div className='single flex justify-end items-center'>
                                {
                                    editable ?
                                        editMode ?
                                            <>
                                                <button className='mr-4 bg-[#de3e3e] px-4 py-2 text-sm rounded-lg text-white' onClick={() => onCancel()}>Cancel</button>
                                                <button onClick={() => {
                                                    onSave()
                                                }} className='bg-[#2761d0] px-4 py-2 text-sm rounded-lg text-white'>Save</button>
                                            </>
                                            :
                                            <button onClick={() => {
                                                setEditMode(true)
                                            }} className='bg-[#2761d0] px-4 py-2 text-sm rounded-lg text-white'>Edit</button>
                                        :
                                        ""
                                }
                            </div>
                            <h3 className='single'>Personal Information</h3>
                            <div className='info'>
                                <h4>Name</h4>
                                {

                                    editMode ?
                                        <input type='text' value={name} onChange={(e) => setName(e.target.value)} className='border-[#84A1C8] border-2 border-solid rounded-lg w-full p-2 col-span-2' />
                                        :
                                        <p>{name}</p>
                                }
                            </div>
                            <div style={{
                                alignItems: 'flex-start'
                            }} className='info single'>
                                <h4>Address</h4>
                                {
                                    editMode ?
                                        <textarea className=' border-[#84A1C8] border-2 border-solid rounded-lg w-full p-2 col-span-2'
                                            value={address} onChange={(e) => {
                                                setAddress(e.target.value)
                                            }}></textarea>
                                        :
                                        <p>{address}</p>
                                }
                            </div>
                            <div className='info'>
                                <h4>Email</h4>
                                {
                                    editMode ?
                                        <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} className='border-[#84A1C8] border-2 border-solid rounded-lg w-full p-2 col-span-2' />
                                        :
                                        <p>{email}</p>
                                }
                            </div>
                            <div className='info'>
                                <h4>Phone</h4>
                                {
                                    editMode ?
                                        <PhoneInput
                                        containerStyle={{
                                            gridColumn: "span 2"
                                        }}
                                        value={phoneNumber} onChange={(e) => {
                                            setPhoneNumber(e)
                                        }} />
                                        :
                                        <p>{phoneNumber}</p>
                                }
                            </div>

                            <h3 className='single'>Installation Information</h3>
                            <div className='info'>
                                <h4>Sales Rep</h4>
                                <p>{customer?.salesRep?.firstName + " " + customer?.salesRep?.lastName}</p>
                            </div>
                            <div className='info'>
                                <h4>Sold At</h4>
                                <p>{new Date(customer?.timeStamp).toLocaleDateString(
                                    'en-US',
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }
                                )}</p>
                            </div>
                            <div className='info'>
                                <h4>Account Status</h4>
                                <p>{customer?.accountStatus}</p>
                            </div>
                            <div className='info'>
                                <h4>Install Date</h4>
                                <p>{
                                    customer?.installDateTime ?
                                        new Date(customer?.installDateTime).toLocaleDateString(
                                            'en-US',
                                            {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            }
                                        ) : "Not Scheduled"
                                }</p>
                            </div>
                            <div className='info'>
                                <h4>Internet Plan</h4>
                                <p>{customer?.fiberPlan?.description}</p>
                            </div>
                            <div className='info'>
                                <h4>Rep Notes</h4>
                                <p>{notes ? notes : "No Notes"}</p>
                            </div>
                            <div className='info'>
                                <h4>Customer Notes</h4>
                                {
                                    editMode ?
                                        <textarea className=' border-[#84A1C8] border-2 border-solid rounded-lg w-full p-2 col-span-2'
                                            value={customerNotes} onChange={(e) => {
                                                setCustomerNotes(e.target.value)
                                            }}></textarea>
                                        :
                                        <p>{customerNotes ? customerNotes : "No Notes"}</p>
                                }
                            </div>
                            <Link to={`/CustomerDetails/${customer?.id}`} target='_blank' className='view_more'>View More</Link>
                        </div>
                        :
                        <div className='customer_info' style={{
                            maxHeight: '80vh',
                            overflowY: 'auto'
                        }}>
                            <h3 className='single'>Personal Information</h3>
                            <div className='info'>
                                <h4>Name</h4>
                                <p>{lead?.name}</p>
                            </div>
                            <div style={{
                                alignItems: 'flex-start'
                            }} className='info single'>
                                <h4>Address</h4>
                                <p>{lead?.fiberHouse?.mapString}</p>
                            </div>
                            <div className='info'>
                                <h4>Email</h4>
                                <p>{lead?.email}</p>
                            </div>
                            <div className='info'>
                                <h4>Phone</h4>
                                <p>{lead?.phone}</p>
                            </div>
                            <div className='info'>
                                <h4>Secondary account holder</h4>
                                <p>{formData?.secondaryHolder}</p>
                            </div>
                            <div className='info'>
                                <h4>City, State, Zip</h4>
                                <p>{formData?.cityStateZip}</p>
                            </div>
                            <div className='info'>
                                <h4>Internet Speed</h4>
                                <p>{formData?.internetSpeed}</p>
                            </div>
                            <div className='info'>
                                <h4>Is military, a senior, a first responder, or an educator?</h4>
                                <p>{formData?.eligibility}</p>
                            </div>
                            <div className='info'>
                                <h4>Preferred Installation Day?</h4>
                                <p>{formData?.installDay}</p>
                            </div>
                            <div className='info'>
                                <h4>Preferred Installation Time?</h4>
                                <p>{formData?.timePreference}</p>
                            </div>
                            <div className='info'>
                                <h4>High school attended/graduated</h4>
                                <p>{formData?.highSchool}</p>
                            </div>
                            <div className='info'>
                                <h4>Mother's Maiden Name</h4>
                                <p>{formData?.mothersMaidenName}</p>
                            </div>
                            <div className='info'>
                                <h4>Last 4 digits of SSN </h4>
                                <p>{formData?.ssnLast4}</p>
                            </div>
                        </div>
                }
            </div>
        </Modal>
    )
}

export default Index