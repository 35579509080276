/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Layout from '../../../Layouts/Layout'
import { useParams } from 'react-router-dom'
import GetOfferByGuidReq from '../../../Requests/Offer/GetOfferByGuidReq'
import OfferApi from '../../../API/OfferApi'
import './Offer.scss'
import { Modal } from '@mui/material'
// import CustomerForm from './components/CustomerForm'
import { CircularProgress, ClickAwayListener } from '@mui/material'
// import { sendPasswordResetEmail } from 'firebase/auth';
import Swal from 'sweetalert2';
// import { getAuth } from 'firebase/auth';
import BizCard from '../../TeamAdmin/MapPage/components/DigitalBizCard/BizCard'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import SearchFiberPlanReq from '../../../Requests/FiberPlan/SearchFiberPlansReq'
import FiberPlanApi from '../../../API/FiberPlanApi'
import QRCode from 'qrcode.react'
import { FaRegCopy } from 'react-icons/fa'
import MobileBizCard from '../../TeamAdmin/MapPage/components/DigitalBizCard/MobileBizCard'
import LightAcceptOfferForm from './components/LightAcceptOfferForm'

const Index = () => {
    const [offer, setOffer] = useState(null)
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [customerModal, setCustomerModal] = useState(false);
    const [reload, setReload] = useState(false)
    const [plans, setPlans] = useState([])
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [shareModal, setShareModal] = useState(false)
    // const auth = getAuth();
    // 'Symmetrical Speeds',
    const services = ['Unlimited - No Data Caps', "Free Install", "Free Modem", 'No Contracts', 'No Hidden Fees', 'Rate Lock - Lifetime Guarantee (1 Gig Only)', '24/7 Local Support', '30 Day Free Trial']
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth)
        }
        )
        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(window.innerWidth)
            })
        }

    }, [])

    useEffect(() => {
        const getOffer = async () => {
            setLoading(true)
            // console.log(id)
            const req = new GetOfferByGuidReq();
            req.string = id;
            req.int = 1;
            req.pagingParams.pageNumber = 1;
            req.pagingParams.pageSize = 10;
            const res = await OfferApi.GetOfferByGuid(req);
            // console.log(res)
            if (res?.status?.success) {
                setOffer(res.data)
                setSelectedPlan(res?.data?.offerTemplate?.offerTemplateFiberPlans[1]?.fiberPlan)
                // const req2 = new SearchFiberPlanReq();
                // req2.fiberCompanyId = res.data.fiberCompanyId;
                // const res2 = await FiberPlanApi.SearchFiberPlans(req2);
                // if (res2?.status?.success) {
  
                    setLoading(false)
                // }

            }
        }
        getOffer()
    }, [reload]);

    // const ForgotPassword = () => {

    //     if(offer?.lead?.email === null || offer?.lead?.email === undefined){
    //         return
    //     }
    //     var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    //     if (!pattern.test(offer.lead.email)) {
    //         // Swal.fire({
    //         //     icon: 'error',
    //         //     title: 'Invalid Email',
    //         //     text: 'Please enter a valid email address',
    //         // });

    //         return;
    //     }
    //     sendPasswordResetEmail(auth, offer.lead.email)
    //         .then((value) => {
    //             // Password reset email sent!
    //             // ..
    //             return
    //         })
    //         .catch((error) => {
    //             const errorCode = error.code;
    //             const errorMessage = error.message;

    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Error',
    //                 text: errorMessage,
    //             });
    //         });
    // }

    function shareOffer() {
        navigator.clipboard.writeText(window.location.origin + '/share-offer/' + id)
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Link Copied to clipboard',
        });
    }

    function getPlanSpeed(plan) {
        if (plan.speed < 1) {
            return plan.speed * 1000 + " Mbps"
        } else {
            return plan.speed + " Gig"
        }
    }

    return (
        <div style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
        }}>
            <div className='offer_container'>

                {
                    !loading && offer && !offer.accepted && (
                        <>
                            {
                                shareModal &&
                                <ClickAwayListener onClickAway={() => {
                                    setShareModal(false)
                                }}>
                                    <div className='share_container'>
                                        <QRCode value={window.location.origin + '/share-offer/' + id} />
                                        <h1>Share this link with your friends</h1>
                                        <div onClick={() => {
                                            shareOffer()
                                        }} className='link_input'>
                                            <input type='text' value={window.location.origin + '/share-offer/' + id} readOnly />
                                            <FaRegCopy size={30} color='#2761D0' />
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            }
                            <div className='offer'>
                                <Modal minHeight={'80vh'} open={customerModal} onClose={()=>{
                                    setCustomerModal(false)
                                }}>
                                    {/* <CustomerForm setReload={setReload} fiberPlan={selectedPlan} offer={offer} setOpen={setCustomerModal} lead={offer.lead} /> */}
                                    <LightAcceptOfferForm setReload={setReload} fiberPlan={selectedPlan} offer={offer} setOpen={setCustomerModal} lead={offer.lead} />
                                </Modal>
                                {/* {offer.freeInstall && <h3 className='free_install'>FREE INSTALL {offer.freeInstall && offer.freeOffer && '&'}</h3>}
                                {offer.freeOffer && <h2 className='two_free' style={{
                                    marginTop: "-1rem"
                                }}>1st MONTH</h2>}
                                {offer.freeOffer && <h1 className='month' style={{
                                    marginTop: "-2rem"
                                }}>FREE</h1>} */}

                                {
                                    offer.promo && offer.promo.split('\n').map((promo, index) => {
                                        return (
                                            <h3 key={index} className='free_install'>{promo} {(index<(offer.promo.split('\n').length-1)) && '&'}</h3>
                                        )
                                    })
                                }

                                <h2 style={{
                                    marginBottom: "0rem"
                                }}><span>LIMITED TIME OFFER</span></h2>
                                <div className='banner'>
                                    <div className='banner_top'>
                                        {
                                            offer.offerTemplate &&
                                            offer?.offerTemplate?.offerTemplateFiberPlans?.map((plan, index) => {
                                                
                                                    return (
                                                        <div key={index} onClick={() => {
                                                            setSelectedPlan(plan.fiberPlan)
                                                        }} className={`banner_card ${selectedPlan?.id === plan.fiberPlan.id && "active"}`}>
                                                            {selectedPlan?.id === plan.fiberPlan.id && <IoIosCheckmarkCircle style={{
                                                                position: 'absolute',
                                                                top: '-5%',
                                                                right: '-5%'
                                                            }} size={40} color='#2761D0' />}
                                                            <img style={{
                                                                height: "30px"
                                                            }} src={'data:image/png;base64,' + offer.fiberCompany?.logo} alt='fiber company logo' />
                                                            <h2>
                                                                {
                                                                    getPlanSpeed(plan.fiberPlan)       
                                                                }
                                                            </h2>                            
                                                            <h4>${plan.fiberPlan.price - (plan.discount?plan.discount:0)}/mo*</h4>
                                                        </div>
                                                    )
                                            })
                                        }

                                    </div>
                                    <div className='banner_bottom'>
                                        {
                                            offer?.offerTemplate ?
                                                offer?.offerTemplate?.bulletPoints.split('\n').map((service, index) => {
                                                    return (
                                                        <div key={index} className='service_card'>
                                                            <IoIosCheckmarkCircle size={20} color='#2761D0' />
                                                            <p>{service}</p>
                                                        </div>
                                                    )
                                                })
                                                :
                                                services.map((service, index) => {
                                                    return (
                                                        <div key={index} className='service_card'>
                                                            <IoIosCheckmarkCircle size={20} color='#2761D0' />
                                                            <p>{service}</p>
                                                        </div>
                                                    )
                                                })
                                        }
                                    </div>

                                    <button className='accept_offer_button' onClick={() => {
                                        if (!selectedPlan) {
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Select a plan',
                                                text: 'Please select a plan to continue',
                                            });
                                            return;
                                        }
                                        setCustomerModal(true)
                                    }} >ACCEPT OFFER</button>
                                </div>
                            </div>
                            {
                                screenWidth > 620 ?
                                    <BizCard salesRep={offer.salesRep} />
                                    :
                                    <MobileBizCard salesRep={offer.salesRep} />
                            }
                        </>
                    )
                }
                {
                    loading && (
                        <div style={{
                            gridColumn:"span 2"
                        }}>
                            <CircularProgress sx={{
                                color: "#2761D0"
                            }} size={70} />
                        </div>
                    )
                }
                {
                    !loading && offer && offer.accepted && (
                        <div className='offer'>
                            {
                                // ForgotPassword()
                            }
                            { offer?.lead?.email && <h1 style={{
                                fontSize:"1.2rem",
                                marginBottom:"1rem",
                                textAlign:"center",
                                fontWeight:"bold",
                                color:"#2761D0"
                            }}>You can Login using the email <span>{offer?.lead?.email}</span></h1>}
                            <p style={{
                                textAlign:"center",
                                fontSize:"1rem",
                                color:"#84A1C8"
                            }}>Your fiber internet order has been successfully submitted. An installation date will be provided within the next 24-48 hours.</p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Index