/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import MapIconApi from '../../../API/MapIconApi';
import SearchLeadsForContactCenterReq from '../../../Requests/Customer/SearchLeadsForContactCenterReq';
import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { SearchFiltersContext, UserContext } from '../../../App';
import { FaPhoneSlash, FaSearch } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { MdExpandMore } from 'react-icons/md';
import QuickDate from '../../../components/QuickDate';
import ContextMenu from '../../../components/ContextMenu';
import ExportData from '../../../Services/ExportData';
import UserSelector from '../../../components/UserSelector';
import OrganizationSelector from '../../../components/OrganizationSelector';
import FiberCompanySelector from '../../../components/FiberCompanySelector';
import DigitalToolBelt from '../../../components/DigitalToolBelt';
import CustomerInfo from '../../../components/CustomerInfo';
import SearchFiberCompanyReq from '../../../Requests/FiberCompany/SearchFiberCompanyReq';
import FiberCompanyApi from '../../../API/FiberCompanyApi';
import VersatileLoader from '../../../components/VersatileLoader';
import LeadCard from './LeadCard';
const Leads = ({ fromDate, toDate, salesReps, quickDateValue, type, soldOnly, passedOrgIds, knocked, leadType, dateTypeProp, fiberCompanyIds, customerName, source }) => {
    const [leads, setLeads] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [mapIcons, setMapIcons] = useState([]);
    const user = useContext(UserContext);
    const [search, setSearch] = useState('');
    const [filterBy, setFilterBy] = useState(leadType ? leadType : 'Customer');
    const [customerStatus, setCustomerStatus] = useState('Sold');
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [lastUpdatedFrom, setLastUpdatedFrom] = useState(fromDate ? fromDate : null);
    const [lastUpdatedTo, setLastUpdatedTo] = useState(toDate ? toDate : null);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState("20"); // (*)
    const observer = useRef(); // (*)
    const [firstRender, setFirstRender] = useState(true);
    const [selectedLeads, setSelectedLeads] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [salesRepIds, setSalesRepIds] = useState([]);
    const [orgIds, setOrgIds] = useState([]);
    const exportData = new ExportData();
    const navigate = useNavigate();
    const [selectedLead, setSelectedLead] = useState(null);
    const [openToolBelt, setOpenToolBelt] = useState(false);
    const [openCustomerInfo, setOpenCustomerInfo] = useState(false);
    const [dateType, setDateType] = useState(dateTypeProp ? dateTypeProp : 'DateSold');
    const [mapIconIds, setMapIconIds] = useState([]);
    const [includeInvalidCustomers, setIncludeInvalidCustomers] = useState(false);
    const [fiberCompanies, setFiberCompanies] = useState([]);
    const [orderType, setOrderType] = useState('All');
    const [rating, setRating] = useState(null);

    const [userWithName, setUserWithName] = useState(null);
    const [orgWithName, setOrgWithName] = useState(null);
    const [fiberCompaniesWithName, setFiberCompaniesWithName] = useState(null);

    const [quickDateValue2, setQuickDateValue2] = useState(quickDateValue ? quickDateValue : null);
    const searchFilter = useContext(SearchFiltersContext);    const [fiberCompaniesForDisplay, setFiberCompaniesForDisplay] = useState([]);


    const getFiberCompanies = async () => {
        let req = new SearchFiberCompanyReq();
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 10;
        const res = await FiberCompanyApi.SearchFiberCompany(req);
        if (res?.status?.success) {
            setFiberCompaniesForDisplay(res?.data?.list);
        }
    }

    useEffect(() => {
        getFiberCompanies();
    }, [])


    useEffect(() => {
        if (customerName) {
            setSearch(customerName);
        }
    }, [customerName]);
    // Set customerStatus based on the type prop
    useEffect(() => {
        setUserWithName(salesReps)
        setOrgWithName(passedOrgIds)
        setFiberCompaniesWithName(fiberCompanyIds)
        if (type) {
            setCustomerStatus(type);
        }
    }, [type, salesReps]);
    const lastCard = useCallback(
        (node) => {
            if (!isLoaded) {
                console.log("Skip due to loading");
                return;
            }
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {

                if (entries[0].isIntersecting && hasMore) {
                    setPageNumber((prev) => prev + 1);
                    console.log("visible");
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoaded, hasMore]
    );



    const getMapIcons = async () => {
        const res = await MapIconApi.GetAllMapIcons()
        if (res?.status?.success) {
            res.data.sort((a, b) => a.order - b.order)
            setMapIcons(res.data)
            localStorage.setItem('mapIcons', JSON.stringify(res.data))
      }
    }
    useEffect(() => {
        if (mapIcons == null || mapIcons.length === 0) {
            getMapIcons()
        }
        setLastUpdatedFrom(fromDate)
        setLastUpdatedTo(toDate)
        setQuickDateValue2(quickDateValue)
        console.log(fromDate, toDate, quickDateValue)
    }, [fromDate, toDate, quickDateValue]
    )

    const searchLeads = async (page, firstSearch) => {
        setIsLoaded(false);
        const req = new SearchLeadsForContactCenterReq();

        searchFilter.current = {
            ...searchFilter.current,
            contactCenter: {
                leadType: filterBy,
                dateType: dateType,
                fromDate: lastUpdatedFrom,
                toDate: lastUpdatedTo,
                salesReps: userWithName,
                quickDateValue: quickDateValue2,
                type: customerStatus,
                passedOrgIds: orgWithName,
                knocked: knocked,
                fiberCompanies: fiberCompaniesWithName,
                customerName: search,
                source: source
            }
        }

        req.SearchString = search;
        req.CustomersOnly = filterBy === 'Customer' ? true : filterBy === 'All' ? null : false;
        req.CustomerStatus = customerStatus;
        req.CustomerType = filterBy === 'KnockedDoors' ? 'All' : filterBy;
        req.Knocked = filterBy === 'KnockedDoors' ? true : null;
        req.rating = rating===0 ? null : rating;
        //req.IsVerfied = isVerified;
        if (page) {
            req.PagingParams.PageNumber = 1;
            setPageNumber(1);
        }
        else {
            req.PagingParams.PageNumber = pageNumber;
        }
        req.orderType = orderType;
        req.PagingParams.PageSize = parseInt(pageSize);
        if (user.userType === 'SalesRep' || user.userType === 'SalesOrgAdmin') {

            req.SalesOrgId = user?.salesOrgId;
        }
        if (user.userType === 'SalesOrgAdmin') {
            req.TeamAdminId = user.id;
        }
        else if (user.userType === 'SalesRep') {
            req.SalesRepId = user.id;
        }
        req.mapIconIds = null;
        req.SalesRepIds = userWithName ? userWithName.map(user => user.id) : salesRepIds;

        if (orgWithName) {
            req.SalesOrgIds = orgWithName.filter(org => org.id === 0).length > 0 ? null : orgWithName.map(org => org.id)
        }

        req.fiberCompanyIds = fiberCompaniesWithName ? fiberCompaniesWithName.map(company => company.id) : fiberCompanyIds;
        req.mapIconIds = mapIconIds.length === 0 || filterBy !=="All" ? null : mapIcons.filter(mapIcon => !mapIconIds?.includes(mapIcon.id)).map(mapIcon => mapIcon.id)
        req.includeInvalidCustomers = includeInvalidCustomers;

        console.log(lastUpdatedFrom)
        console.log(lastUpdatedTo)
        if (filterBy !== 'KnockedDoors') {
            switch (dateType) {
                case "LastUpdated":
                    if (lastUpdatedFrom) {
                        req.lastUpdatedFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.lastUpdatedTo = lastUpdatedTo;
                    }
                    break;
                case "InstallDate":
                    if (lastUpdatedFrom) {
                        req.installDateFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.installDateTo = lastUpdatedTo;
                    }
                    break;
                case "DateSold":
                    if (lastUpdatedFrom) {
                        req.timeStampFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.timeStampTo = lastUpdatedTo;
                    }
                    break;
                case "AppointmentDate":
                    if (lastUpdatedFrom) {
                        req.appointmentTimeFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.appointmentTimeTo = lastUpdatedTo;
                    }
                    break;
                case "CancelRequestTime":
                    if (lastUpdatedFrom) {
                        req.cancelRequestTimeFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.cancelRequestTimeTo = lastUpdatedTo;
                    }
                    break;
                case "RescheduleRequestTime":
                    if (lastUpdatedFrom) {
                        req.rescheduleRequestTimeFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.rescheduleRequestTimeTo = lastUpdatedTo;
                    }
                    break;

                case "KnockedDate":
                    if (lastUpdatedFrom) {
                        req.knockedDateFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.knockedDateTo = lastUpdatedTo;
                    }
                    break;
                case "OfferSentTime":
                    if (lastUpdatedFrom) {
                        req.offerTimeFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.offerTimeTo = lastUpdatedTo;
                    }
                    break;
                case "OfferAcceptedTime":
                    if (lastUpdatedFrom) {
                        req.offerAcceptedTimeFrom = lastUpdatedFrom;
                    }
                    if (lastUpdatedTo) {
                        req.offerAcceptedTimeTo = lastUpdatedTo;
                    }
                    break;
                default:
                    break;
            }
        }
        else {
            if (lastUpdatedFrom) {
                req.knockedDateFrom = new Date(lastUpdatedFrom);
            }
            if (lastUpdatedTo) {
                req.knockedDateTo = new Date(lastUpdatedTo);
            }
        }
        const res = await MapIconApi.SearchLeadsForContactCenter(req);
        if (res?.status?.success) {
            setLeads((prev) => {
                if (prev !== null && pageNumber > 1)
                    return [...new Set([...prev, ...res?.data?.list])];
                else
                    return res?.data?.list;
            }
            )
            setTotalItems(res.data.totalItems);
            console.log(res)
            setHasMore(res.data.hasNextPage);
            setIsLoaded(true);
        }
    }


    const filterSearch = () => {
        // setPageNumber(1);
        setLeads(null);
        searchLeads(1);

    }

    useEffect(() => {
        if (pageNumber > 1 && !firstRender)
            searchLeads();

        setFirstRender(false);
    }, [pageNumber])



    const getName = (lead) => {
        if (lead.fidiumCustomer != null) {
            return lead.fidiumCustomer.firstName + " " + lead.fidiumCustomer.lastName;
        }
        else if (lead.name != null) {
            return lead.name;
        }
        else {
            return "No Name";
        }
    }




    const getAddress = (lead) => {
        if (lead.fiberHouse?.mapString) {
            return lead.fiberHouse?.mapString;
        }
        else if (lead.fiberHouse?.address) {
            return lead.fiberHouse?.address?.street + " " + lead.fiberHouse?.address?.city + ", " + lead.fiberHouse?.address?.state + " " + lead.fiberHouse?.address?.zip + ", " + lead.fiberHouse?.address?.country;
        }
        else {
            return "No Address";
        }
    }
    const getPhone = (lead) => {
        if (lead.fidiumCustomer?.cellPhone != null) {
            return lead.fidiumCustomer.cellPhone;
        }
        else if (lead.phone != null) {
            return lead.phone;
        }
        else {
            return <FaPhoneSlash color='red' size={20} />
        }
    }

    useEffect(
        () => {
            setPageNumber(1)
            const timer = setTimeout(() => {
                filterSearch();
            }, 1000)

            return () => {
                clearTimeout(timer)
            }

        }, [filterBy,rating, pageSize, orderType, fiberCompanies, includeInvalidCustomers, search, customerStatus, orgIds, salesRepIds, lastUpdatedFrom, userWithName, orgWithName, lastUpdatedTo, dateType, mapIconIds]
    )

    const resetAllFilters = () => {
        setSearch('');
        setFilterBy('Customer');
        setCustomerStatus('Sold');
        setLastUpdatedFrom('');
        setLastUpdatedTo('');
        setSalesRepIds([]);
        setOrgIds([]);
        setOrgWithName([]);
    }

    const dataToExport = (data) => {
        let l = data.map(
            (lead) => {

                let latestIssue = lead.fidiumCustomer?.issueType === "Cancel"? lead?.fidiumCustomer?.cancelationForms?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0] :
                    lead?.fidiumCustomer?.issueType === "Reschedule"? lead?.fidiumCustomer?.rescheduleForms?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0] :
                    null

                let latestIssueTimestamp = lead.fidiumCustomer?.issueType === "Cancel"?
                  lead?.fidiumCustomer?.cancelationForms?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0]?.timestamp :
                    lead?.fidiumCustomer?.issueType === "Reschedule"?
                    lead?.fidiumCustomer?.rescheduleForms?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0]?.timestamp :
                    "N/A"

                let noOfIssues = lead.fidiumCustomer?.cancelationForms?.length + lead.fidiumCustomer?.rescheduleForms?.length

                return {
                    fiberCompany: fiberCompaniesForDisplay.find(company => company.id === lead.fidiumCustomer?.fiberCompanyId)?.name,
                    // sales agency.
                    salesOrgName: lead.fidiumCustomer?.salesOrg?.name,
                    salesRep: lead.fidiumCustomer?.salesRep?.firstName + " " + lead.fidiumCustomer?.salesRep?.lastName,
                    leadType: lead.fidiumCustomer? "Customer" : lead.isProspect? "Prospect" : "",
                    mapIcon: mapIcons.find(mapIcon => mapIcon.id === lead.fiberHouse?.mapIconId)?.name,
                    lightningNotes: lead.notes,
                    accountStatus: lead.fidiumCustomer?.accountStatus,
                    soldTimestamp: lead.fidiumCustomer?.timeStamp? new Date(lead.fidiumCustomer?.timeStamp).toLocaleDateString() + " " + new Date(lead.fidiumCustomer?.timeStamp).toLocaleTimeString() : null,
                    contactName: getName(lead),
                    seocndaryContactName: lead?.fidiumCustomer?.secondaryAccountHolderFullName,
                    phone: getPhone(lead),
                    email: lead.fidiumCustomer?.email ? lead.fidiumCustomer.email : lead.email,
                    address: getAddress(lead),
                    originalInstallDate: lead.fidiumCustomer?.originallInstallDateTimeFrom? new Date(lead.fidiumCustomer?.originallInstallDateTimeFrom).toLocaleDateString() + " " + new Date(lead.fidiumCustomer?.originallInstallDateTimeFrom).toLocaleTimeString() : null,
                    updatedInstallDate: lead.fidiumCustomer?.updatedInstallDateTimeFrom? new Date(lead.fidiumCustomer?.updatedInstallDateTimeFrom).toLocaleDateString() + " " + new Date(lead.fidiumCustomer?.updatedInstallDateTimeFrom).toLocaleTimeString() : null,
                    internetType: lead.fidiumCustomer.fiberPlan?.name?.includes("HFC")? "HFC" : "FTTH",
                    fiberPlan: lead.fidiumCustomer?.fiberPlan?.name,
                    // original fiber plan
                    // updated fiber plan
                    promos: lead.fidiumCustomer?.promo,
                    noOfReviews : lead.fidiumCustomer?.feedbacks?.length>0? lead.fidiumCustomer.feedbacks.length : "",
                    typeOfReview:lead.fidiumCustomer?.feedbacks.length>0? lead.fidiumCustomer?.feedbacks[0]?.content?"Lightning":"Google":"",
                    locationOfReview: lead.fidiumCustomer?.feedbacks[0]?.feedbackType,
                    noOfIssues :noOfIssues>0 && lead?.fidiumCustomer?.issueType ? noOfIssues : "",
                    accountIssue: lead?.fidiumCustomer?.issueType,
                    issueDescription : lead?.fidiumCustomer?.issueDescription,
                    issueSubmittedTimestamp: latestIssueTimestamp !== "N/A"? new Date(latestIssueTimestamp).toLocaleDateString() + " " + new Date(latestIssueTimestamp).toLocaleTimeString() : "",
                    issueResolved: latestIssue?latestIssue.resolved?"Y":latestIssue.isResolved?"Y":"N" : "",
                    id: lead.id,
                }
            }
        )
        return l
    }

    let exportMenuItems = [
        {
            title: "Export to XLSX",
            onClick: () => {

                let l = dataToExport(leads)
                exportData.ExportToXLSX('Leads.xlsx', l, selectedLeads)
            }
        },
        {
            title: "Export to CSV",
            onClick: () => {
                let l = dataToExport(leads)
                exportData.ExportToCsv('Leads.csv', l, selectedLeads)
            }
        }
    ]


    return (
        <div className="Leads">
            <div className="SearchFilters">

                {source && source === "Calendar" &&
                    <button className='reset_all_fitlers' style={{ backgroundColor: '#2761CF', marginBottom: '0.5rem' }} onClick={() => {
                        navigate(-1)
                    }}>Back</button>
                }
                <form onSubmit={(e) => { e.preventDefault(); }} className='search-bar'>
                    <input value={search} onChange={(e) => { setSearch(e.target.value) }} type="text" placeholder="Search" />
                    <FaSearch color='#2761CF' size={30} />
                </form>
                <button className='reset_all_fitlers' onClick={() => {
                    resetAllFilters();
                }}>Reset</button>
                <div className='selectors'>
                    <div className='selector_con'>
                        <label>Lead Type</label>
                        <select onChange={(e) => {
                            setFilterBy(e.target.value)
                            if (e.target.value === "KnockedDoors") {
                                setDateType("KnockedDate")
                            }
                        }} className='selector' value={filterBy}>
                            <option value="Customer">Customers</option>
                            <option value="Prospect">Prospects</option>
                            <option value="HotLead">Hot Leads</option>
                            <option value="KnockedDoors">Knocked Doors</option>
                            <option value="RequestedCustomer" >Requested Customers</option>
                            <option value="All" >All</option>
                        </select>
                    </div>
                    {
                        filterBy === 'Customer' &&
                        <div className='selector_con'>
                            <label>Status</label>
                            <select value={customerStatus} onChange={(e) => {
                                setCustomerStatus(e.target.value)
                                if (e.target.value === "PendingInstalls" || e.target.value === "Completed") {
                                    setDateType("InstallDate")
                                }
                            }} className='selector'>
                                <option value="Sold">Sold</option>
                                <option value="SoldOnly">Sold (Not Scheduled)</option>
                                <option value="VerifiedSold">Verified Sold</option>
                                <option value="PendingInstalls" >Pending Installs</option>
                                <option value="Completed">Completed</option>
                                <option value="PendingCancels">Pending Cancels</option>
                                <option value="Canceled">Canceled</option>
                                <option value="PendingReschedules">Pending Reschedules</option>
                                <option value="Referral">Referrals</option>
                                <option value="Reviewed">Reviewed</option>
                                <option value="Missing">Missing</option>
                            </select>
                        </div>
                    }
                    {
                        filterBy === "Prospect" &&
                        <div className='selector_con'>
                            <label>Status</label>
                            <select value={customerStatus} onChange={(e) => {
                                setCustomerStatus(e.target.value)
                            }} className='selector'>0
                                <option value="Prospect">All</option>
                                <option value="OffersSent">Offers Sent</option>
                                <option value="OffersAccepted">Offers Accepted</option>
                            </select>
                        </div>
                    }
                    {
                        customerStatus === "Reviewed" &&
                        <div className='selector_con single'>
                            <label>Rating</label>
                            <select value={rating} onChange={(e) => {
                                setRating(parseInt(e.target.value))
                            }} className='selector'>
                                <option value="0">All</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                    }
                    <FiberCompanySelector value={fiberCompanyIds} companiesWithOfferTemplateOnly={true} className={'single'} placeholder='Select Fiber Company' multiple={true} setCompanyObject={setFiberCompaniesWithName} setUser={setFiberCompanies} />
                    {
                        user.userType === 'SuperAdmin' &&
                        <div className='single'>
                            <div className='select_deselect mb-2'>
                                <button className='select' onClick={() => {
                                    setOrgWithName([
                                        {
                                            value: 0
                                            , label: "All", id: 0
                                        }
                                    ])
                                }}>Select All</button>
                                <button className='deselect' onClick={() => {
                                    setOrgWithName([])
                                }}>Deselect All</button>
                            </div>
                            <OrganizationSelector allOption={true} orgWithName={orgWithName} setOrgWithName={setOrgWithName} multiple={true} setUser={setOrgIds} placeholder='Select Organizations' />
                        </div>
                    }
                    {
                        (user.userType === 'SuperAdmin' || user.userType === "SalesOrgAdmin") &&
                        <div className='single'>
                            <UserSelector salesOrgIds={orgIds} includeYourself={true} SalesOrgId={user.userType === "SalesOrgAdmin" ? user.salesOrgId : null} setUser={setSalesRepIds} userWithName={userWithName} setUserWithName={setUserWithName} conversationType={"Group"} placeholder='Select SalesReps' userTypes={["SalesRep", "SalesOrgAdmin"]} />
                        </div>
                    }

                    {
                        filterBy === 'All' &&
                        <>
                            <div style={{
                                marginTop: "1rem",
                                // gap: "0.5rem",
                                gridColumn: "span 2",
                            }} className='select_deselect'>
                                <button className='select' onClick={() => {
                                    setMapIconIds([])
                                }}>Select All</button>
                                <button className='deselect' onClick={() => {
                                    setMapIconIds(
                                        mapIcons.map((mapIcon) => mapIcon.id)
                                    )
                                }}>Deselect All</button>
                            </div>
                            <div className='mapicon_con'>
                                {
                                    mapIcons.length > 0 &&
                                    mapIcons.map((mapIcon, index) => {
                                        return (
                                            <div key={index} className={`map_icon ${!mapIconIds?.includes(mapIcon.id) ? 'active' : ''}`} onClick={() => {
                                                if (mapIconIds?.includes(mapIcon.id)) {
                                                    setMapIconIds((prev) => prev.filter(id => id !== mapIcon.id))
                                                }
                                                else {
                                                    setMapIconIds((prev) => [...prev, mapIcon.id])
                                                }
                                            }}>
                                                <img src={"data:image/png;base64," + mapIcon.image} alt='Map Icon' />
                                            </div>
                                        )
                                    }
                                    )
                                }
                            </div>
                        </>}
                    <div className='selector_con'>
                        <label>Date Type</label>
                        <select value={dateType} onChange={(e) => {
                            setDateType(e.target.value)
                        }} className='selector'>
                            <option value="LastUpdated">Last Updated</option>
                            <option value="InstallDate">Install Date</option>
                            <option value={"DateSold"} >Date Sold</option>
                            <option value="AppointmentDate">Appointment Date</option>
                            <option value="CancelRequestTime" >Cancel Request Time</option>
                            <option value="RescheduleRequestTime" >Reschedule Request Time</option>
                            <option value="KnockedDate" >Knocked Date</option>
                            <option value="OfferSentTime">Offer Sent Date</option>
                            <option value="OfferAcceptedTime">Offer Accepted Date</option>
                        </select>
                    </div>
                    <QuickDate future={customerStatus==="PendingInstalls"} quickDateValue={quickDateValue2} selectQuickDate={quickDateValue} setQuickDateValue={setQuickDateValue2} from={lastUpdatedFrom} to={lastUpdatedTo} setFrom={setLastUpdatedFrom} setTo={setLastUpdatedTo} />
                    {
                        <>
                            <div className='selector_con col-span-2'>
                                <label>Include Invalid Customers</label>
                                <div className="toggle-switch">
                                    {/* <label>{"Viewing " + filterBy}</label> */}
                                    <input
                                        type="checkbox"
                                        checked={includeInvalidCustomers}
                                        onChange={() => { setIncludeInvalidCustomers(!includeInvalidCustomers) }}
                                    />
                                </div>
                            </div>
                            <div className='selector_con col-span-2'>
                                <label>Order Type</label>
                                <select value={orderType} onChange={(e) => {
                                    setOrderType(e.target.value)
                                }} className='selector'>
                                    <option value="All">All</option>
                                    <option value="Verified">Verified</option>
                                    <option value="Unverified">Unverified</option>
                                </select>
                            </div>
                        </>
                    }
                </div>

            </div>
            <div className="LeadsList">

                {isLoaded && <div className='leads_top_button'>
                    <h3 >RESULTS ({totalItems})</h3>

                    <div className='page_size_container'>
                        <p>Page Size</p>
                        <select value={pageSize} onChange={(e) => { setPageSize(e.target.value) }} >
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="2000">2000</option>
                            <option value="5000">5000</option>
                        </select>
                    </div>

                    <button className='filter_btn export_button' onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                    }}>Export | <MdExpandMore color='white' size={20} /></button>
                </div>}
                {
                    isLoaded &&
                    <div style={{
                        marginTop: "1rem",
                        // gap: "0.5rem"
                    }} className='select_deselect'>
                        <button className='select' onClick={() => {
                            setSelectedLeads(leads.map(l => l.id))
                            document.querySelectorAll('.select').forEach((checkbox) => {
                                checkbox.checked = true
                            })
                        }}>Select All</button>
                        <button className='deselect' onClick={() => {
                            setSelectedLeads([])
                            document.querySelectorAll('.select').forEach((checkbox) => {
                                checkbox.checked = false
                            })
                        }}>Deselect All</button>
                    </div>
                }
                <ContextMenu anchorEl={anchorEl} handleClose={() => {
                    setAnchorEl(null)
                }} setAnchorEl={setAnchorEl} menuItems={exportMenuItems} />

                <DigitalToolBelt open={openToolBelt} setOpen={setOpenToolBelt} lead={selectedLead} />
                <CustomerInfo lead={selectedLead} notes={selectedLead?.notes} customer={selectedLead?.fidiumCustomer} open={openCustomerInfo} setOpen={setOpenCustomerInfo} />
                {
                    leads != null &&
                    leads?.map(
                        (lead, index) => {
                            return (
                                <LeadCard key={index} lead={lead} 
                                totalLeads={leads?.length} index={index} 
                                lastCard={lastCard} setSelectedLead={setSelectedLead} 
                                setOpenCustomerInfo={setOpenCustomerInfo} setOpenToolBelt={setOpenToolBelt} 
                                setSelectedLeads={setSelectedLeads} selectedLeads={selectedLeads}  
                                fiberCompaniesForDisplay={fiberCompaniesForDisplay} mapIcons={mapIcons} />
                            )
                        }
                    )
                }

                {
                    !isLoaded &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        padding:"3rem"
                    }}>
                        <VersatileLoader size='large' color='#2761CF' />
                    </div>
                }

            </div>

        </div>
    )
}

export default Leads