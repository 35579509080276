import Swal from "sweetalert2";
import * as XLSX from 'xlsx';

class ExportData {

    // Function to convert camel case to capitalized words
    capitalizeKeys(data) {
        return data.map(item => {
            const newItem = {};
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const capitalizedKey = key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
                    newItem[capitalizedKey] = item[key];
                }
            }
            return newItem;
        });
    }

    ExportToCsv(filename, data, selectedIds) {
        console.log("Data to export:", data); // Debug line
        console.log("Selected IDs:", selectedIds); // Debug line
        const dataToExport = data.filter((item) => selectedIds.includes(item.id));
        if (dataToExport.length === 0) {
            Swal.fire({
                icon: "error",
                title: "No Customers Selected",
                text: "Please select customers to download"
            });
            return;
        }
        const capitalizedData = this.capitalizeKeys(dataToExport);
        const ws = XLSX.utils.json_to_sheet(capitalizedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "sheet");
        XLSX.writeFile(wb, filename);
    }

    ExportToXLSX(filename, data, selectedIds) {
        const selected = data.filter((item) => selectedIds.includes(item.id));
        if (selected.length === 0) {
            Swal.fire({
                icon: "error",
                title: "No Customers Selected",
                text: "Please select customers to download"
            });
            return;
        }
        const capitalizedData = this.capitalizeKeys(selected);
        const ws = XLSX.utils.json_to_sheet(capitalizedData);
    
        // Calculate column widths
        const columnWidths = Object.keys(capitalizedData[0]).filter(key => key !== "id").map(key => {
            const maxLength = Math.max(
                key.length,
                ...capitalizedData.map(item => (item[key] ? item[key].toString().length : 0))
            );
            return { wch: maxLength + 5 }; // Add extra space for padding
        });
        ws['!cols'] = columnWidths;
    
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "sheet");
        XLSX.writeFile(wb, filename);
    }

    // CSV Export Method
    ExportIssuesToCsv(filename, data) {
        if (!Array.isArray(data) || data.length === 0) {
            console.log("Data to export:", data); // Debug line
            Swal.fire({
                icon: "error",
                title: "No Data",
                text: "There is no data to export."
            });
            return;
        }
    
        const capitalizedData = this.capitalizeKeys(data);
        const ws = XLSX.utils.json_to_sheet(capitalizedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Issues");
        XLSX.writeFile(wb, filename);
    }
    
    ExportIssuesToXLSX(filename, data) {
        if (!Array.isArray(data) || data.length === 0) {
            console.log("Data to export:", data); // Debug line
            Swal.fire({
                icon: "error",
                title: "No Data",
                text: "There is no data to export."
            });
            return;
        }
    
        const capitalizedData = this.capitalizeKeys(data);
        const ws = XLSX.utils.json_to_sheet(capitalizedData);
    
        // Calculate column widths
        const columnWidths = Object.keys(capitalizedData[0]).map(key => {
            const maxLength = Math.max(
                key.length,
                ...capitalizedData.map(item => (item[key] ? item[key].toString().length : 0))
            );
            return { wch: maxLength + 5 }; // Add extra space for padding
        });
        ws['!cols'] = columnWidths;
    
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Issues");
        XLSX.writeFile(wb, filename);
    }


    
    dataToExport(data,fiberCompaniesForDisplay,mapIcons){
        let l = data.map(
            (lead) => {

                let latestIssue = lead.fidiumCustomer?.issueType === "Cancel"? lead?.fidiumCustomer?.cancelationForms?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0] :
                    lead?.fidiumCustomer?.issueType === "Reschedule"? lead?.fidiumCustomer?.rescheduleForms?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0] :
                    null

                let latestIssueTimestamp = lead.fidiumCustomer?.issueType === "Cancel"?
                  lead?.fidiumCustomer?.cancelationForms?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0]?.timestamp :
                    lead?.fidiumCustomer?.issueType === "Reschedule"?
                    lead?.fidiumCustomer?.rescheduleForms?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0]?.timestamp :
                    "N/A"

                let noOfIssues = lead.fidiumCustomer?.cancelationForms?.length + lead.fidiumCustomer?.rescheduleForms?.length

                return {
                    fiberCompany: fiberCompaniesForDisplay.find(company => company.id === lead.fidiumCustomer?.fiberCompanyId)?.name,
                    // sales agency.
                    salesOrgName: lead.fidiumCustomer?.salesOrg?.name,
                    salesRep: lead.fidiumCustomer?.salesRep?.firstName + " " + lead.fidiumCustomer?.salesRep?.lastName,
                    leadType: lead.fidiumCustomer? "Customer" : lead.isProspect? "Prospect" : "",
                    mapIcon: mapIcons.find(mapIcon => mapIcon.id === lead.fiberHouse?.mapIconId)?.name,
                    lightningNotes: lead.notes,
                    accountStatus: lead.fidiumCustomer?.accountStatus,
                    soldTimestamp: lead.fidiumCustomer?.timeStamp? new Date(lead.fidiumCustomer?.timeStamp).toLocaleDateString() + " " + new Date(lead.fidiumCustomer?.timeStamp).toLocaleTimeString() : null,
                    contactName: getName(lead),
                    seocndaryContactName: lead?.fidiumCustomer?.secondaryAccountHolderFullName,
                    phone: getPhone(lead),
                    email: lead.fidiumCustomer?.email ? lead.fidiumCustomer.email : lead.email,
                    address: getAddress(lead),
                    originalInstallDate: lead.fidiumCustomer?.originallInstallDateTimeFrom? new Date(lead.fidiumCustomer?.originallInstallDateTimeFrom).toLocaleDateString() + " " + new Date(lead.fidiumCustomer?.originallInstallDateTimeFrom).toLocaleTimeString() : null,
                    updatedInstallDate: lead.fidiumCustomer?.updatedInstallDateTimeFrom? new Date(lead.fidiumCustomer?.updatedInstallDateTimeFrom).toLocaleDateString() + " " + new Date(lead.fidiumCustomer?.updatedInstallDateTimeFrom).toLocaleTimeString() : null,
                    internetType: lead.fidiumCustomer.fiberPlan?.name?.includes("HFC")? "HFC" : "FTTH",
                    fiberPlan: lead.fidiumCustomer?.fiberPlan?.name,
                    // original fiber plan
                    // updated fiber plan
                    promos: lead.fidiumCustomer?.promo,
                    noOfReviews : lead.fidiumCustomer?.feedbacks?.length>0? lead.fidiumCustomer.feedbacks.length : "",
                    typeOfReview:lead.fidiumCustomer?.feedbacks.length>0? lead.fidiumCustomer?.feedbacks[0]?.content?"Lightning":"Google":"",
                    locationOfReview: lead.fidiumCustomer?.feedbacks[0]?.feedbackType,
                    noOfIssues :noOfIssues>0 && lead?.fidiumCustomer?.issueType ? noOfIssues : "",
                    accountIssue: lead?.fidiumCustomer?.issueType,
                    issueDescription : lead?.fidiumCustomer?.issueDescription,
                    issueSubmittedTimestamp: latestIssueTimestamp !== "N/A"? new Date(latestIssueTimestamp).toLocaleDateString() + " " + new Date(latestIssueTimestamp).toLocaleTimeString() : "",
                    issueResolved: latestIssue?latestIssue.resolved?"Y":latestIssue.isResolved?"Y":"N" : "",
                    id: lead.id,
                }
            }
        )
        return l
    }
    
}
const getName = (lead) => {
    if (lead.fidiumCustomer != null) {
        return lead.fidiumCustomer.firstName + " " + lead.fidiumCustomer.lastName;
    }
    else if (lead.name != null) {
        return lead.name;
    }
    else {
        return "No Name";
    }
}




const getAddress = (lead) => {
    if (lead.fiberHouse?.mapString) {
        return lead.fiberHouse?.mapString;
    }
    else if (lead.fiberHouse?.address) {
        return lead.fiberHouse?.address?.street + " " + lead.fiberHouse?.address?.city + ", " + lead.fiberHouse?.address?.state + " " + lead.fiberHouse?.address?.zip + ", " + lead.fiberHouse?.address?.country;
    }
    else {
        return "No Address";
    }
}
const getPhone = (lead) => {
    if (lead.fidiumCustomer?.cellPhone != null) {
        return lead.fidiumCustomer.cellPhone;
    }
    else if (lead.phone != null) {
        return lead.phone;
    }
    else {
        return ""
    }
}
export default ExportData