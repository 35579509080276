import React, { useContext, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import CreateCustomerReq from '../../../../Requests/Customer/CreateCustomerReq';
import Swal from 'sweetalert2';
import OfferApi from '../../../../API/OfferApi';
import logo from './../../Offer/components/logo.png'
import authorized from './../../Offer/components/authorized.png'
import LightAcceptOfferReq from '../../../../Requests/Customer/LightAcceptOfferReq';
import { IoMdCloseCircle } from 'react-icons/io';
import authorizedFastwyre from './../../Offer/components/authorizedFastwyre.png'
import { useNavigate } from 'react-router-dom';
const LightAcceptOfferForm = ({ color, lead, offer, setOpen, setReload, fiberPlan, isSharedOffer }) => {
    const [lightFirstName, setLightFirstName] = useState(lead?.name?.split(' ')[0])
    const [lightLastName, setLightLastName] = useState(lead?.name?.split(' ').length > 1 ? lead?.name?.split(' ')[1] : '')
    const [lightEmail, setLightEmail] = useState(lead?.email)
    const [lightPhone, setLightPhone] = useState(lead?.phone)
    const [lightConsent, setLightConsent] = useState(false)
    const [address, setAddress] = useState('')

    const navigate = useNavigate()

    const createLightCustomer = async () => {
        // console.log(offer)
        // console.log(fiberPlan)
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Creating Light Customer",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        if (!lightFirstName) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "First Name is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lightPhone) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (lightPhone.length < 11) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone is invalid",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }


        if (!offer.fiberCompanyId) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Company is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!fiberPlan) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Plan is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lightConsent) {
            Swal.fire({
                icon: "error",
                title: "Please agree to the terms and conditions",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (offer.referredByOfferId && !address) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Address is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        var req = new LightAcceptOfferReq()
        req.leadId = lead.id
        req.firstName = lightFirstName
        req.lastName = lightLastName
        req.email = lightEmail
        req.phoneNumber = lightPhone
        req.address = address
        req.salesRepId = offer?.salesRepId;
        req.signedUpBy = "SalesRep"
        req.companyId = offer.fiberCompanyId
        req.planId = fiberPlan.id
        req.offerId = offer.id
        const res = await OfferApi.LightAcceptOffer(req)
        if (res?.status?.success) {
            if (offer.fiberCompanyId === 2) {

                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "A Rep will contact you shortly.",
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: "Close",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    customClass: {
                        container: 'custom-swal'
                    },
                    willClose: () => {
                        Swal.hideLoading()
                    }
                })
            }
            else {
                const opt = await Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "A Rep will contact you shortly.\nWould you like to fill the Order Form Now Or Wait for the Rep to contact you?",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Fill Order Form Now",
                    cancelButtonText: "Wait For the Rep",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    customClass: {
                        container: 'custom-swal'
                    },
                    willClose: () => {
                        Swal.hideLoading()
                    }
                })

                if (opt.isConfirmed) {
                    navigate(`/fastwyre-form/${offer.offerGuid}`)
                }
            }
            setReload(true);
            setOpen(false);
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                customClass: {
                    container: 'custom-swal'
                },
                text: res?.status?.message
            })
        }
    }


    return (
        <div className='custom_modal'>
            <div style={{ width: "100%" }}>
                <IoMdCloseCircle style={{ marginLeft: "auto", cursor: "pointer" }} onClick={() => {
                    setOpen(false)
                }} size={30} color={color} />
            </div>
            {
                offer?.fiberCompanyId === 9 && <img alt="Logo" src={authorizedFastwyre} className='logo' />
            }
            {
                offer?.fiberCompanyId === 2 && <img alt="Logo" src={authorized} className='logo' />
            }
            {/* <h1 >Customer Info</h1> */}
            <div className='inputs'>
                <input placeholder='*First Name' value={lightFirstName} onChange={(e) => {
                    setLightFirstName(e.target.value)
                }} />
                <input placeholder='Last Name' value={lightLastName} onChange={(e) => {
                    setLightLastName(e.target.value)
                }} />
                <input placeholder='Email' value={lightEmail} onChange={(e) => {
                    setLightEmail(e.target.value)
                }} />

                {
                    offer.referredByOfferId &&
                    <input placeholder='*Address' value={address} onChange={(e) => {
                        setAddress(e.target.value)
                    }} />
                }

                {/* <FiberCompanySelector setUser={setLightCompany} disabled={false} placeholder="Select a Company" className="fiber_company_selector" />
                <FiberPlanSelector setUser={setLightPlan} disabled={lightCompany ? false : true}
                    placeholder="Select a Plan" className="fiber_company_selector" fiberCompany={lightCompany} /> */}
                <PhoneInput placeholder='* Cellphone' country={'us'} containerStyle={{
                    width: "100%",
                    // marginBottom: "1rem",
                    borderRadius: "10px"
                }} inputClass='custom_input' inputStyle={{
                    // width: "100%",
                    // height: "auto",
                    // padding: "0.5rem",
                    // fontSize: "1.2rem",
                    // borderRadius: "10px"
                }} value={lightPhone} onChange={(e) => {
                    setLightPhone(e)
                }} />
                <div className='consent'>
                    <input type="checkbox"
                        checked={lightConsent}
                        onChange={(e) => {
                            setLightConsent(e.target.checked)
                        }}
                    />
                    <label>I agree to receive email and/or sms messages from Lightning OS LLC.</label>
                </div>
                <button style={{ backgroundColor: color }} onClick={() => {
                    createLightCustomer()
                }
                }>Reserve Free Install</button>
                {/* <img alt='Logo' src={logo} style={{
                    width: "100%",
                    // marginTop: "1rem"
                }} className='logo' /> */}
            </div>
        </div>
    )
}

export default LightAcceptOfferForm
