import React from 'react'
import { FaCheck } from 'react-icons/fa'
import map from './../images/opportunity_map.png'
import { Modal } from '@mui/material'
// import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2'
import CreateJobApplicationReq from '../../../../Requests/Users/CreateJobApplicationReq'
import UsersApi from '../../../../API/UsersApi'
import CreateFileReq from '../../../../Requests/CloudFile/CreateFileReq'
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import { v4 as uuidv4 } from 'uuid'
import PhoneInput from 'react-phone-input-2'
import logo from "./../../../TeamAdmin/MapPage/components/NewLeadCard/Images/logo.png"
// const searchLocation = async (address) => {
//     const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${'AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0'}`;


//     try {
//         const response = await fetch(geocodingUrl);
//         const data = await response.json();


//         if (response.status === 200 && data.status === "OK") {
//             return data;
//         } else {
//             // console.log(data.error_message)
//         }
//     } catch (error) {
//         console.error(error);
//         return null;
//     }
// };
const ExpandedTab = ({ type, jobDescription, requirements, position,openForm }) => {


    const [open, setOpen] = React.useState(openForm?true:false)


    // job application states
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [streetAddress, setStreetAddress] = React.useState('')
    const [unitNo, setUnitNo] = React.useState('')
    const [city, setCity] = React.useState('')
    const [state, setState] = React.useState('')
    const [zipCode, setZipCode] = React.useState('')




    const [outOfState, setOutOfState] = React.useState(null)
    const [preferToTravel, setPreferToTravel] = React.useState(null)
    const [haveDriverLicense, setHaveDriverLicense] = React.useState(null)
    const [workSchedule, setWorkSchedule] = React.useState(null)
    const [levelOfD2DSale, setLevelOfD2DSale] = React.useState(null)
    const [payReference, setPayReference] = React.useState(null)
    const [financialTarget, setFinancialTarget] = React.useState(null)


    const [message, setMessage] = React.useState('')
    // const [address, setAddress] = React.useState(null)
    // const [search, setSearch] = React.useState('')
    const [resume, setResume] = React.useState(null)
    const [how, setHow] = React.useState('')
    const [other, setOther] = React.useState('')
    const storage = getStorage();


    // async function getLocation() {
    //     if (search === "") {
    //         return
    //     }


    //     const data = await searchLocation(search)
    //     return data.results.map((item) => {
    //         return { value: item.formatted_address, label: item.formatted_address }
    //     })
    // }


    const submitApplication = async () => {
        Swal.fire({
            icon: "info",
            title: "Submitting Application",
            text: "Please wait...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            customClass: {
                container: 'custom-swal'
            },
            didOpen: () => {
                Swal.showLoading()
            }
        })


        if (!firstName) {
            Swal.fire({
                icon: "error",
                title: "First Name is required",
                text: "Please enter your first name",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }


        if (!lastName) {
            Swal.fire({
                icon: "error",
                title: "Last Name is required",
                text: "Please enter your last name",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!phoneNumber) {
            Swal.fire({
                icon: "error",
                title: "Phone Number is required",
                text: "Please enter your phone number",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        
        if (phoneNumber.length < 11) {
            Swal.fire({
                icon: "error",
                title: "Invalid Phone Number",
                text: "Please enter a valid phone number",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!email) {
            Swal.fire({
                icon: "error",
                title: "Email is required",
                text: "Please enter your email",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        let emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/;
        if (!emailRegex.test(email)) {
            Swal.fire({
                icon: "error",
                title: "Invalid Email",
                text: "Please enter a valid email",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        // if (!address?.value) {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Address is required",
        //         text: "Please enter your address",
        //         customClass: {
        //             container: 'custom-swal'
        //         },
        //         didOpen: () => {
        //             Swal.hideLoading()
        //         }
        //     })
        //     return
        // }

        if (!city) {
            Swal.fire({
                icon: "error",
                title: "City is required",
                text: "Please enter your city",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!state) {
            Swal.fire({
                icon: "error",
                title: "State is required",
                text: "Please enter your state",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!outOfState){
            Swal.fire({
                icon: "error",
                title: "Please specify if you are open to travel out of state",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!preferToTravel){
            Swal.fire({
                icon: "error",
                title: "Please specify if you prefer not to travel",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!haveDriverLicense){
            Swal.fire({
                icon: "error",
                title: "Please specify if you have a valid driver's license",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!workSchedule){
            Swal.fire({
                icon: "error",
                title: "Work Schedule is required",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!levelOfD2DSale){
            Swal.fire({
                icon: "error",
                title: "Level of Door-to-Door Sales Experience is required",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!payReference){
            Swal.fire({
                icon: "error",
                title: "Pay Reference is required",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!financialTarget){
            Swal.fire({
                icon: "error",
                title: "Financial Target is required",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!how) {
            Swal.fire({
                icon: "error",
                title: "Please specify how you heard about us",
                text: "Please select an option",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (how === 'Other' && !other) {
            Swal.fire({
                icon: "error",
                title: "Please specify how you heard about us",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        // if (!resume) {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Resume is required",
        //         text: "Please upload your resume",
        //         customClass: {
        //             container: 'custom-swal'
        //         },
        //         didOpen: () => {
        //             Swal.hideLoading()
        //         }
        //     })
        //     return
        // }


        const req = new CreateJobApplicationReq();
        req.firstName = firstName;
        req.lastName = lastName;
        req.email = email;
        req.phone = phoneNumber;
        req.address = streetAddress;
        req.unitNumber = unitNo;
        req.city = city;
        req.state = state;
        req.travelOutOfState = outOfState === 'Yes' ? true : false;
        req.preferNotToTravel = preferToTravel === 'Yes' ? true : false;
        req.validDriverLicense = haveDriverLicense === 'Yes' ? true : false;
        req.workSchedule = workSchedule;
        req.experience = levelOfD2DSale;
        req.payReference = payReference;
        req.payExpectation = financialTarget;
        req.zipCode = zipCode;
        req.message = message;
        req.position = position;
        req.howDidYouHear = how === 'Other' ? other : how;
        if (resume) {
            const file = new CreateFileReq()
            file.name = resume.name
            let downloadURLs = [];
            let filePaths = [];
            // let index = 0;
            // Function to upload file and get download URL
            const uploadAndGetData = async (file, type) => {
                // Generate a unique ID
                const uniqueId = uuidv4();
                const storageRef = ref(storage, `/job-application/${uniqueId}/${file.name}`);
                await uploadBytes(storageRef, file);
                // Get download URL and push to downloadURLs array
                const downloadURL = await getDownloadURL(storageRef);
                downloadURLs.push(downloadURL);
                filePaths.push(`/job-application/${uniqueId}/${file.name}`)
            };
            await uploadAndGetData(resume);
            file.name = resume.name;
            file.extension = file.name.split('.').pop();
            file.url = downloadURLs[0];
            file.path = filePaths[0];
            file.fileSize = resume.size.toString();
            file.sellingCardId = 1;
            file.caption = "123";
            file.fileType = resume.type
            req.file = file;
        }


        const res = await UsersApi.CreateJobApplication(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Application Submitted",
                text: "Thank you for applying",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            setOpen(false)
            setFirstName('')
            setLastName('')
            setEmail('')
            setPhoneNumber('')
            // setAddress({})
            setMessage('')


        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }


    const handleOptionChange = (e) => {
        setHow(e.target.value)
    }


    return (
        <div className='expanded_tab'>
            <Modal open={open} onClose={()=>{
                setOpen(false)
            }} >
                <div className='job_application_form'>
                    <img style={{ height: "100px", margin: "auto" }} src={logo} alt='Lightning_Logo' />
                    <h1>Job Application</h1>
                    <div className='inner'>
                        <h3>Contact Information</h3>
                        <input className='input' value={firstName} onChange={(e) => { setFirstName(e.target.value) }} type='text' placeholder='First Name *' />
                        <input className='input' value={lastName} onChange={(e) => { setLastName(e.target.value) }} type='text' placeholder='Last Name *' />
                        <PhoneInput containerStyle={{ width: "100%" }} inputStyle={{ width: "100%" }} country={'us'} value={phoneNumber} onChange={(e) => { setPhoneNumber(e) }} />
                        <input className='input' value={email} onChange={(e) => { setEmail(e.target.value) }} type='email' placeholder='Email *' />
                        {/* <input className='input' value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }} type='tel' placeholder='Phone Number' /> */}
                        <input className='input' value={streetAddress} onChange={(e) => { setStreetAddress(e.target.value) }} type='text' placeholder='Street Address' />
                        <input className='input' value={unitNo} onChange={(e) => { setUnitNo(e.target.value) }} type='text' placeholder='Unit #' />
                        <input className='input' value={city} onChange={(e) => { setCity(e.target.value) }} type='text' placeholder='City *' />
                        <input className='input' value={state} onChange={(e) => { setState(e.target.value) }} type='text' placeholder='State *' />
                        <h3>Questions</h3>
                        <div className='how'>
                            <p>Open to travel out of state?* (our blitz model)</p>
                            {
                                ['Yes', 'No'].map((val, index) => {
                                    return (
                                        <div key={index}>
                                            <input type='radio' name='outOfState' value={val} checked={outOfState === val} onChange={(e) => setOutOfState(e.target.value)} xcheck />
                                            <label>{val}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='how'>
                            <p>Prefer not to travel *</p>
                            {
                                ['Yes', 'No'].map((val, index) => {
                                    return (
                                        <div key={index}>
                                            <input type='radio' name='preferToTravel' value={val} checked={preferToTravel === val} onChange={(e) => setPreferToTravel(e.target.value)} xcheck />
                                            <label>{val}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>


                        <div className='how'>
                            <p>Do you have a valid driver's license? *</p>
                            {
                                ['Yes', 'No'].map((val, index) => {
                                    return (
                                        <div key={index}>
                                            <input type='radio' name='haveDriverLicense' value={val} checked={haveDriverLicense === val} onChange={(e) => setHaveDriverLicense(e.target.value)} xcheck />
                                            <label>{val}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>


                        <div className='how'>
                            <p>What work schedule are you looking for? *</p>
                            {
                                ['Full Time', 'Part Time', 'Blitz Model'].map((schedule, index) => {
                                    return (
                                        <div key={index}>
                                            <input type='radio' name='workSchedule' value={schedule} checked={workSchedule === schedule} onChange={(e) => setWorkSchedule(e.target.value)} xcheck />
                                            <label>{schedule}</label>
                                        </div>
                                    )
                                })
                            }
                           
                        </div>


                        <div className='how'>
                            <p>What level of door-to-door sales experience do you have? *</p>
                            {
                                ['Beginner', 'Intermediate', 'Expert', 'Pro'].map((level, index) => {
                                    return (
                                        <div key={index}>
                                            <input type='radio' name='levelOfD2DSale' value={level} checked={levelOfD2DSale === level} onChange={(e) => setLevelOfD2DSale(e.target.value)} xcheck />
                                            <label>{level}</label>
                                        </div>
                                    )
                                })
                            }
                           
                        </div>


                        <div className='how'>
                            <p>What is your pay reference? *</p>
                            {
                                ['Commission', 'Salary'].map((pay, index) => {
                                    return (
                                        <div key={index}>
                                            <input type='radio' name='payReference' value={pay} checked={payReference === pay} onChange={(e) => setPayReference(e.target.value)} xcheck />
                                            <label>{pay}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>


                        <div className='how'>
                            <p>What is your financial target? *</p>


                            {
                                ['$3K - $5K', '$5K - $8K', '$8K - $12K', '$12K - $18K', '$18K - $30K', '$30K +'].map((target, index) => {
                                    return (
                                        <div key={index}>
                                            <input type='radio' name='financialTarget' value={target} checked={financialTarget === target} onChange={(e) => setFinancialTarget(e.target.value)} xcheck />
                                            <label>{target}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <textarea value={message} onChange={(e) => { setMessage(e.target.value) }} required placeholder='Message to Lightning Executive Team (Optional)' rows={4} />
                        <div>
                            <label>Resume</label>
                            <input className='input' type='file' onChange={(e) => { setResume(e.target.files[0]) }} />
                        </div>
                        <div className='how'>
                            <p>How did you hear about us?*</p>
                            {
                                [ 'LinkedIn', 'Facebook', 'Instagram', 'Google', 'Referral', 'Other'].map((val, index) => {
                                    return (
                                        <div key={index}>
                                            <input type='radio' name='how' value={val} checked={how === val} onChange={handleOptionChange} xcheck />
                                            <label>{val}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            how === 'Other' &&
                            <input className='input' onChange={(e) => setOther(e.target.value)} value={other} type='text' placeholder='Please specify *' />
                        }
                        <button onClick={submitApplication}>Submit Application</button>
                    </div>
                </div>
            </Modal>
            <p>Date Posted: MARCH 21, 2024</p>
            <h3>Job Description</h3>
            <p>{jobDescription}</p>
            <h3>Requirements and Responsibilities</h3>
            <div className='requirements'>
                {/* <div className='requirement_item'>
                    <FaCheck color='2761D0' size={20} />
                    <p>Conduct field visits to promote Lightning OS fiber internet solutions.</p>
                </div>
                <div className='requirement_item'>
                    <FaCheck color='2761D0' size={20} />
                    <p>Forge meaningful connections with potential customers and provide comprehensive product knowledge.</p>
                </div>
                <div className='requirement_item'>
                    <FaCheck color='2761D0' size={20} />
                    <p>Collaborate closely with the sales team to exceed targets and drive sustainable revenue growth.</p>
                </div> */}
                {
                    requirements.map((requirement, index) => {
                        return (
                            <div key={index} className='requirement_item'>
                                <FaCheck color='2761D0' size={20} />
                                <p>{requirement}</p>
                            </div>
                        )
                    })
                }
            </div>


            <h1>Job Location</h1>
            <p style={{
                textAlign: 'center',
                marginTop: "-2rem"
            }}>Nation Wide</p>


            <img style={{ margin: "auto" }} src={map} alt='map' />
            <button onClick={() => { setOpen(true) }} className='button'>APPLY NOW</button>
            <p style={{ textAlign: "center" }}>Valid Until 21st September 2024</p>
            <p style={{
                textAlign: "center",
                color: "#2761D0"
            }}>Stay Tuned For More Upcoming Positions</p>
        </div>
    )
}


export default ExpandedTab