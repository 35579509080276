import { Box, CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import MapIconApi from '../../../../API/MapIconApi';
import StringIntReq from '../../../../Requests/StringIntReq';
import userGrey from '../../../../assets/images/userGrey.png';
import { MapContext } from '..';
import { useSwipeable } from 'react-swipeable';

const CandidateActivityHistory = ({ selectedCandidate, user }) => {
    const [activityHistory, setActivityHistory] = useState([]);
    const [currentTab, setCurrentTab] = useState(2);
    const { mapIcons } = useContext(MapContext);
    const [loading, setLoading] = useState(true);

    const getActivityHistory = async () => {
        setLoading(true);
        const req = new StringIntReq();
        req.int = selectedCandidate.id;
        const res = await MapIconApi.GetCandidateActivityHistory(req);
        if (res?.status?.success) {
            setActivityHistory(res.data);
            setLoading(false);
        }
    }

    useEffect(() => {
        getActivityHistory();
    }, []);

    const extractNotesArray = (notes) => {
        return notes.split("!*!").filter((note) => note !== "");
    }

    const stopRef = React.useRef(null);
    const refPassthrough = (el) => {
        handlers.ref(el);
        stopRef.current = el;
    }
    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwiping: (eventData) => {
            eventData.event.stopPropagation();
        },
        onSwipedDown: (eventData) => {
            eventData.event.stopPropagation();
        },
    })

    const getIcon = (acHistory) => {

        switch (acHistory?.activityType) {
            case "Map Icon Change":
                if (acHistory?.salesRep?.profileImage?.url != null) {
                    return (
                        <img src={"data:image/png;base64," + mapIcons?.find((assignedMapIcons) => assignedMapIcons.id === acHistory?.iconToId)?.image} alt="Map Icon" />
                    );
                }
                else {
                    return (
                        <img src={userGrey} alt="Sales Rep" />
                    );
                }
            case "Lead Create":
                return (
                    <img src={"data:image/png;base64," + mapIcons?.find((assignedMapIcons) => assignedMapIcons.id === acHistory?.iconFromId)?.image} alt="Map Icon" />
                );
            case "OwnerShip Change":
                if (acHistory?.salesRep?.profileImage?.url != null) {
                    return (
                        <img src={acHistory?.salesRep?.profileImage?.url} alt="Sales Rep" />
                    );
                }
                else {
                    return (
                        <img src={userGrey} alt="Sales Rep" />
                    );
                }
            case "Lead Edit":
                if (acHistory?.salesRep?.profileImage?.url != null) {
                    return (
                        <img src={acHistory?.salesRep?.profileImage?.url} alt="Sales Rep" />
                    );
                }
                else {
                    return (
                        <img src={userGrey} alt="Sales Rep" />
                    );
                }
            default:
                return (
                    <img src={"data:image/png;base64," + mapIcons?.find((assignedMapIcons) => assignedMapIcons.id === acHistory?.iconFromId)?.image} alt="Map Icon" />
                );
        }
    }

    const getTitle = (acHistory) => {
        switch (acHistory?.activityType) {
            case "Map Icon Change":
                return (
                    <h3 style={{
                        textAlign: "left"
                    }}>
                        {
                            mapIcons?.find((assignedMapIcons) => assignedMapIcons.id === acHistory?.iconToId)?.name
                        }
                    </h3>
                );
            case "Lead Create":
                return (
                    <h3 style={{
                        textAlign: "left"
                    }}>
                        {
                            mapIcons?.find((assignedMapIcons) => assignedMapIcons.id === acHistory?.iconFromId)?.name
                        }
                    </h3>
                );
            case "OwnerShip Change":
                if (acHistory?.salesRep == null) {
                    return (
                        <h3 style={{
                            textAlign: "left"
                        }}>
                            {
                                "No Sales Rep Found"
                            }
                        </h3>
                    );
                }
            default:
                return (
                    <h3 style={{
                        textAlign: "left"
                    }}>
                        {
                            acHistory?.salesRep?.firstName + " " + acHistory?.salesRep?.lastName
                        }
                    </h3>
                )
        }
    }


    const getLine1 = (acHistory) => {
        switch (acHistory?.activityType) {
            case "Map Icon Change":
                return (
                    <span>
                        Status Update
                    </span>
                );
            case "Lead Create":
                return (
                    <span>
                        Lead Created
                    </span>
                );
            case "OwnerShip Change":
                return (
                    <span>
                        Ownership Change
                    </span>
                );
            case "Lead Edit":
                return (
                    <span>
                        Lead Edited
                    </span>
                );
            default:
                return (
                    <span>
                        Lead Edited
                    </span>
                );
        }
    }

    const getLine2 = (acHistory) => {
        if (acHistory?.salesOrg == null) {
            return (
                <span>
                    No Sales Org Found
                </span>
            );
        }
        return (
            <span>
                {acHistory?.salesOrg?.name}
            </span>
        );
    }

    const getLine3 = (acHistory) => {
        switch (acHistory?.activityType) {
            case "Map Icon Change":
                if (acHistory?.salesRep == null) {
                    return;
                }
                return (
                    //display in one row styling


                    <span style={{ display: "flex", flexDirection: "row" }}>
                        Icon changed from{<img style={{ marginRight: "5px", marginLeft: "5px", scale: "0.5" }} src={"data:image/png;base64," + mapIcons?.find((icons) => icons.id === acHistory?.iconFromId)?.image} alt="Map Icon" />}to{<img style={{ marginRight: "2px", marginLeft: "2px", scale: "0.5" }} src={"data:image/png;base64," + mapIcons?.find((icons) => icons.id === acHistory?.iconToId)?.image} alt="Map Icon" />}
                    </span>
                );
            case "Lead Create":
                return;
            case "OwnerShip Change":
                return (
                    <span>
                        {acHistory?.notes}
                    </span>
                );
            case "Lead Edit":
                return (
                    extractNotesArray(acHistory?.notes).map((note) => {
                        return (
                            <p>Notes Updated</p>
                        );
                    })
                );
            default:
                return (
                    extractNotesArray(acHistory?.notes).map((note) => {
                        return (
                            <p>{note}</p>
                        );
                    })
                );
        }
    }


    const getLine4 = (acHistory) => {
        if (acHistory?.activityType !== "OwnerShip Change") {
            if (acHistory?.salesRep == null) {
                return <span>No Sales Rep Found</span>;
            }
            return (
                <span>
                    {acHistory?.salesRep?.firstName + " " + acHistory?.salesRep?.lastName}
                </span>
            );
        }
    }


    const Card = ({ acHistory }) => {
        return (
            <div className="NewActivityHistoryCard">
                <div className="ActivityHistoryCardHeader">
                    <div className="ImgDiv">
                        {
                            getIcon(acHistory)
                        }
                    </div>
                    <div className="TitleDiv">
                        {
                            getTitle(acHistory)
                        }
                    </div>
                </div>
                <div className="ActivityHistoryCardBody">
                    <div className="Line1">
                        {
                            getLine1(acHistory)
                        }
                    </div>
                    <div className="Line1">
                        {
                            getLine4(acHistory)
                        }
                    </div>
                    <div className="Line2">
                        {
                            getLine2(acHistory)
                        }
                    </div>
                    <div className="Line3">
                        {
                            getLine3(acHistory)
                        }
                    </div>
                    <div className="Timestamp">
                        <span>{new Date(acHistory?.timestamp).toLocaleString()}</span>
                    </div>
                </div>
            </div>
        );
    }

    const actvity = (
        <div style={{ height: window.innerHeight - 350 + 'px' }} ref={refPassthrough} className='overflow_container'>
            <div className="NewActivityHistoryBody">
                {
                    !loading ? (
                        activityHistory.length > 0 ? (
                            activityHistory.map((activity, index) => (
                                <Card key={index} acHistory={activity} />
                            ))
                        ) : (
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <h1 style={{ fontWeight: "bold", fontSize: "2rem" }}>NO ACTIVITY HISTORY</h1>
                            </div>
                        )
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress size={40} />
                        </Box>
                    )
                }
            </div>
        </div>
    );

    const getTab = () => {
        switch (currentTab) {
            case 2:
            default:
                return actvity;
        }
    }

    return (
        <div className="NewActivityHistory">
            {/* <div className='NewActivityHistoryHeader' style={{ placeItems: "center" }}>
                <div onClick={() => setCurrentTab(2)} className={`menu ${currentTab === 2 && 'active'}`}>
                    <p>ACTIVITY</p>
                </div>
            </div> */}
            {getTab()}
        </div>
    );
};

export default CandidateActivityHistory;
