/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from 'react'
import { MapContext } from '../..'

// import Modal from './../../../../../components/Modal'
import { Modal, Rating } from '@mui/material'
import CreateCustomer from './../CreateCustomer'
import { UserContext } from '../../../../../App'
import { FaFire, FaRegCopy } from "react-icons/fa";
import { IoQrCode } from "react-icons/io5";
import { MdBolt, MdDeleteOutline, MdDoorbell, MdRoute } from "react-icons/md";
import Swal from 'sweetalert2'
import DeleteFiberHouseReq from './../../../../../Requests/FiberHouse/DeleteFiberHouseReq'
import FiberHouseApi from '../../../../../API/FiberHouseApi'
import { FaAddressCard } from "react-icons/fa6";
import EditLeadReq from '../../../../../Requests/FiberHouse/EditLeadReq'
import EditFiberHouseReq from '../../../../../Requests/FiberHouse/EditFiberHouseReq'
import bot from "./../../Images/bot.png"
import ContextMenu from '../../../../../components/ContextMenu'
import { RxReset } from "react-icons/rx"
import MapHelper from '../../MapHelper'
import { fromLonLat } from 'ol/proj'
import { GiTv } from 'react-icons/gi'
import MarkLeadAsBundledReq from '../../../../../Requests/FiberHouse/MarkLeadAsBundledReq'
import { SiSpeedtest, SiTheconversation } from 'react-icons/si'
import SpeedTestModal from './SpeedTestModal'
import DigitalToolBelt from "./../../../../../components/DigitalToolBelt"
import CustomerInfo from "./../../../../../components/CustomerInfo"
import FreeInstallForm from './FreeInstallForm'
import AppointmentForm from './AppointmentForm'
import ScheduleForm from './ScheduleForm'
const LeadData = ({ mapIcon, setReload, fidiumCustomer, lead, setEdit, fiberHouse, searchFiberHouses, setIndex, setFiberHouse }) => {
    const user = useContext(UserContext)
    let { mapIcons, cluster, mapObject, salesReps, setSelectedFiberHouse, selectedFiberHouseFeature, updateFiberHouseInContext } = useContext(MapContext)
    console.log(lead)
    // console.log(fiberHouse)
    // console.log(mapIcons)
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState(() => {

        if (lead.fidiumCustomer != null) {
            return lead.fidiumCustomer.firstName + " " + lead.fidiumCustomer.lastName;
        }
        else if (lead.name != null) {
            return lead.name;
        }
        else {
            return "";
        }

    })
    const [email, setEmail] = useState(lead?.email)
    const [phone, setPhone] = useState(lead?.phone)
    const [openModal, setOpenModal] = useState(false)
    const [openInstallTimeModal, setOpenInstallTimeModal] = useState(false)
    const [address, setAddress] = useState(lead?.fiberHouse?.mapString);
    const initialRender = useRef(true)
    const shouldSaveAddress = useRef(false)
    const [openContextMenu, setOpenContextMenu] = useState(false)

    // const [lightSalesRep, setLightSalesRep] = useState(null)
    const [lightOpenModal, setLightOpenModal] = useState(false)
    const [speedTestOpenModal, setSpeedTestOpenModal] = useState(false)
    const [beforeImageUrl, setBeforeImageUrl] = useState('');
    const [afterImageUrl, setAfterImageUrl] = useState('');

    const [isBundled, setIsBundled] = useState(lead?.isBundled);
    const [hasVideoDoorBell, setHasVideoDoorBell] = useState(lead?.hasVideoDoorBell);

    const [openDigitalToolBelt, setOpenDigitalToolBelt] = useState(false)
    const [openCustomerInfo, setOpenCustomerInfo] = useState(false)



    const deleteFiberHouse = async () => {
        const result = await Swal.fire({
            icon: "question",
            title: "Are you sure?",
            text: "You want to delete this Fiber House",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Yes, I'm Sure!",

        })

        if (result.isConfirmed) {
            Swal.fire({
                icon: "info",
                title: "Please Wait",
                text: "We are deleting the Fiber House",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: false,
                willOpen: () => {
                    Swal.showLoading();
                },
            })
            // if (lead.fidiumCustomerId) {
            //     const deleteCustomerReq = new DeleteCustomerReq()
            //     deleteCustomerReq.int = lead.fidiumCustomerId
            //     deleteCustomerReq.pagingParams.pageNumber = 1
            //     deleteCustomerReq.pagingParams.pageSize = 10
            //     const res1 = await CustomerApi.DeleteFidiumCustomer(deleteCustomerReq);

            // }
            const deleteFiberHouseReq = new DeleteFiberHouseReq()
            deleteFiberHouseReq.int = fiberHouse.id
            deleteFiberHouseReq.pagingParams.pageNumber = 1
            deleteFiberHouseReq.pagingParams.pageSize = 10
            const res = await FiberHouseApi.DeleteFiberHouse(deleteFiberHouseReq)
            if (res?.status?.success) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Fiber House Deleted Successfully",
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: "Close",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    willClose: () => {
                        Swal.hideLoading()
                    }
                })
                // setFiberHouses(
                //     (prev) => {
                //         return prev.filter((fh) => fh.id !== fiberHouse.id)
                //     }
                // )

                setSelectedFiberHouse(null)
                // setSelectedLead(null)
                cluster.current.getSource().getSource().forEachFeature((feature) => {
                    if (feature.get('id') === fiberHouse.id) {
                        cluster.current.getSource().getSource().removeFeature(feature)
                    }
                })

                setIndex(-1)
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: res?.status?.message
                })

            }
        }
    }

    function copyAddress() {
        navigator.clipboard.writeText(address);
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Address Copied to Clipboard',
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        })
    }

    function copyPhone() {
        navigator.clipboard.writeText(phone.slice(2));
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Phone Number Copied to Clipboard',
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        })
    }

    function copyEmail() {
        navigator.clipboard.writeText(email);
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Email Copied to Clipboard',
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        })
    }

    const updateLead = async (type) => {

        let req = new EditLeadReq();
        req.id = lead.id;

        switch (type) {
            case 'name':
                req.name = name;
                break;
            case 'email':
                req.email = email;
                break;
            case 'phone':
                req.phone = phone;
                break;
            default:
                req.name = name;
                req.email = email;
                req.phone = phone;
        }
        req.salesRepId = user.id;
        let res = await FiberHouseApi.EditLead(req);
        if (res?.status?.success) {
            searchFiberHouses();
            setEdit(false);
        }
    }

    const updateFiberHouse = async () => {
        let req = new EditFiberHouseReq();
        req.id = lead?.fiberHouse?.id;
        req.mapString = address;
        req.coordinate = null
        req.salesRepId = user?.id;
        const res = await FiberHouseApi.EditFiberHouse(req);
        if (res?.status?.success) {
            searchFiberHouses();
        }
    }

    useEffect(
        () => {
            if (name !== lead?.name && name !== "") {
                const timer = setTimeout(() => {
                    updateLead('name')
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [name]
    )

    useEffect(
        () => {
            if (email !== lead?.email && email !== "") {
                const timer = setTimeout(() => {
                    updateLead('email')
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [email]
    )

    useEffect(
        () => {
            if (phone !== lead?.phone) {
                const timer = setTimeout(() => {
                    updateLead('phone')
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [phone]
    )

    useEffect(
        () => {
            if (initialRender.current) {
                initialRender.current = false;
                return;
            }
            else if (shouldSaveAddress.current && address !== lead?.fiberHouse?.mapString) {
                const timer = setTimeout(() => {
                    updateFiberHouse()
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [address]
    )

    const menuItemsForConnector = [
        {
            title: `Go to LightCurve Portal`,
            onClick: () => {
                window.open('https://rainierconnect.smarthub.coop/Shop.html#SelectAddressAndCity:', '_blank')
            },
            show: lead.fiberCompanyId ? lead.fiberCompanyId === 2 ? true : false : true
        },
        {
            title: `Go to Fastwyre Portal`,
            onClick: () => {
                window.open('https://fastwyre.com/lightning/', '_blank')
            },
            show: lead.fiberCompanyId ? lead.fiberCompanyId === 9 ? true : false : true
        },
        {
            title: `Copy LightCurve Link`,
            onClick: () => {
                navigator.clipboard.writeText('https://rainierconnect.smarthub.coop/Shop.html#SelectAddressAndCity:')
                Swal.fire({
                    icon: 'success',
                    title: 'Copied',
                    text: 'Link Copied to Clipboard',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                })
            },
            show: lead.fiberCompanyId ? lead.fiberCompanyId === 2 ? true : false : true
        },
        {
            title: `Copy Fastwyre Link`,
            onClick: () => {
                navigator.clipboard.writeText('https://fastwyre.com/lightning/')
                Swal.fire({
                    icon: 'success',
                    title: 'Copied',
                    text: 'Link Copied to Clipboard',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                })
            },
            show: lead.fiberCompanyId ? lead.fiberCompanyId === 9 ? true : false : true
        }

    ]

    async function updateCoordinates(fh) {
        console.log(fh)
        // return
        const result = await Swal.fire({
            title: 'Update Coordinates',
            text: 'Are you sure you want to Reset the Position of the Lead (this can not be reversed)? ',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'green',
            cancelButtonColor: 'red',
        });

        if (result.isConfirmed) {
            // let coordinatesFailed = 0
            // const list = []
            Swal.fire({
                icon: "info",
                title: "Please Wait",
                text: "We are updating the coordinates",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: false,
                willOpen: () => {
                    Swal.showLoading();
                }
            })
            const helper = new MapHelper();
            const mapstring = fh.mapString

            const coordinates = await helper.searchLocation(mapstring);
            if (selectedFiberHouseFeature && selectedFiberHouseFeature.get('id') === fh.id) {
                selectedFiberHouseFeature.getGeometry().setCoordinates(fromLonLat(coordinates))
                mapObject.getView().setCenter(fromLonLat(coordinates))
            }

            const req = new EditFiberHouseReq()
            req.coordinate.latitude = coordinates[1];
            req.coordinate.longitude = coordinates[0];
            req.id = fh.id;

            // update the coordiantes of the feature


            const res = await FiberHouseApi.EditFiberHouse(req);
            if (res?.status?.success) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Coordinates Updated Successfully",
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: "Close",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    willClose: () => {
                        Swal.hideLoading()
                    }
                })
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: res?.status?.message,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: "Close",
                    willClose: () => {
                        Swal.hideLoading()
                    }
                })
            }
        }


    }

    const markLeadAsBundled = async () => {
        const req = new MarkLeadAsBundledReq();
        req.id = lead.id;
        req.isBundled = !isBundled;
        setIsBundled(!isBundled)
        const res = await FiberHouseApi.MarkLeadAsBundled(req);
        console.log(res.status);
        if (res?.status?.success === true) {
            updateFiberHouseInContext(fiberHouse.id, { isBundled: req.isBundled });
        }
    }

    const markLeadHasVideoDoorBell = () => {
        const req = new MarkLeadAsBundledReq();
        req.id = lead.id;
        req.hasVideoDoorBell = !hasVideoDoorBell;
        setHasVideoDoorBell(!hasVideoDoorBell)
        const res = FiberHouseApi.MarkLeadHasVideoDoorBell(req);
        if (res?.status?.success) {
            console.log(res);
        }
    }

    const handleImageUpload = (e, setImage) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result); // Set the image as a base64 string
            };
            reader.readAsDataURL(file);
        }
    };


    return (
        <>
            <DigitalToolBelt setOpen={setOpenDigitalToolBelt} open={openDigitalToolBelt} lead={lead} />
            <CustomerInfo lead={lead} open={openCustomerInfo} setOpen={setOpenCustomerInfo} customer={lead.fidiumCustomer} />
            <FreeInstallForm lead={lead} fiberHouse={fiberHouse} open={lightOpenModal} setOpen={setLightOpenModal} setReload={setReload} />
            <AppointmentForm lead={lead} open={openModal} setOpen={setOpenModal} setReload={setReload} />
            <ScheduleForm lead={lead} fidiumCustomer={fidiumCustomer} open={openInstallTimeModal} setOpen={setOpenInstallTimeModal} setReload={setReload} />

            <SpeedTestModal
                user={user}
                lead={lead}
                open={speedTestOpenModal}
                setOpen={setSpeedTestOpenModal}
                beforeImageUrl={beforeImageUrl}
                setBeforeImageUrl={setBeforeImageUrl}
                afterImageUrl={afterImageUrl}
                setAfterImageUrl={setAfterImageUrl}
                handleImageUpload={handleImageUpload}
            />
            <ContextMenu
                anchorEl={openContextMenu}
                setAnchorEl={setOpenContextMenu}
                menuItems={menuItemsForConnector.filter(item => item.show)}
                handleClose={() => {
                    setOpenContextMenu(null)
                }}
            />
            <div className="LeadData">
                <span className='see_activity' style={{
                    marginLeft: "auto",
                    marginBottom: "1rem",
                }} onClick={() => setIndex(13)}>See Activity</span>
                <div className="DataDiv">
                    {/* Lead Icon */}

                    {
                        <div className='owner_name'>
                            {/* <p>Owned By</p> */}
                            {
                                // (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") &&
                                salesReps?.filter(rep => lead?.fiberHouse?.salesRepFiberHouses.some(salesRep => salesRep.salesRepId === rep.id)).map((rep, index) => {
                                    return (

                                        <div key={index}>
                                            <MdBolt style={{ color: "#a0a4ac" }} size={30} />
                                            <p>{rep.firstName + " " + rep.lastName}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {/* See Activity + Edit Contact Info */}

                    <div className='ButtonsDivTop'>
                        {/* <span onClick={() => setEdit(true)}>Edit Contact Info</span> */}

                        {lead?.fidiumCustomer &&
                            <div style={{
                                width: "fit-content",
                                cursor: "pointer",
                            }} onClick={(e) => {
                                setSpeedTestOpenModal(e.currentTarget)
                            }} >
                                <SiSpeedtest className='icon' size={25} color='#2761D0' />
                            </div>
                        }
                        {
                            lead?.fidiumCustomer == null &&
                            <div className='bot' onClick={() => {
                                setLightOpenModal(true)
                            }} style={{
                                // padding: "0.2rem",
                                borderRadius: "20px",
                                border: 'none',
                                cursor: "pointer",
                                // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)"
                            }}>
                                <img src={bot} width={30} height={30} alt="bot" style={{

                                }} />
                            </div>
                        }

                        <div style={{
                            width: "fit-content",
                            cursor: "pointer",
                        }} onClick={(e) => {
                            setOpenContextMenu(e.currentTarget)
                        }} >
                            <MdRoute className='icon' size={25} color='#2761D0' />
                        </div>
                        <div style={{
                            width: "fit-content",
                            cursor: "pointer",
                        }} onClick={() => {
                            markLeadAsBundled()
                        }}>
                            <GiTv color={isBundled ? '#2761D0' : 'grey'} size={26} />
                        </div>
                        <div style={{
                            width: "fit-content",
                            cursor: "pointer",
                        }} onClick={() => {
                            markLeadHasVideoDoorBell()
                        }}>
                            <MdDoorbell color={hasVideoDoorBell ? '#2761D0' : 'grey'} size={26} />
                        </div>
                        <div style={{
                            width: "fit-content",
                            cursor: "pointer",
                        }} onClick={() => deleteFiberHouse()}>
                            <MdDeleteOutline className='icon' size={25} color='red' />
                        </div>

                    </div>

                    <hr style={{
                        gridColumn: "span 3/span 3"
                    }} />
                    <div className="DataDiv_MapIcon">
                        <div className='part1'>
                            <div className="image-wrapper">
                                <img
                                    src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === mapIcon)?.image}
                                    alt="Map Icon"
                                />
                                {isBundled && (
                                    <span>
                                        TV
                                    </span>
                                )}
                            </div>

                            <input placeholder='No Name' value={name} onChange={(event) => {
                                setName(event.target.value)
                            }} className='LeadName' />
                            <div>
                            </div>
                            <span>
                                {mapIcons.find(assignedMapIcons => assignedMapIcons.id === mapIcon)?.name + " "}
                                {mapIcons.find(assignedMapIcons => assignedMapIcons.id === mapIcon)?.abbreviation && "(" + mapIcons.find(assignedMapIcons => assignedMapIcons.id === mapIcon)?.abbreviation + ")"}
                            </span>
                        </div>
                        <div className='icons'>
                            <img onClick={() => {
                                setOpenDigitalToolBelt(true)
                            }} src="/images/digital_tool_belt/digital_belt.png" alt='Digital Tool Belt' />
                            <img onClick={() => {
                                setOpenCustomerInfo(true)
                            }} src='/images/digital_tool_belt/customerInfo.svg' alt='Digital Tool Belt' />
                        </div>
                    </div>
                    <hr style={{
                        gridColumn: "span 3/span 3"
                    }} />
                    {
                        <div style={{
                            gridColumn: "span 2/span 2",
                            display: "flex"
                        }}>
                            <textarea rows={3} style={{
                                width: "100%",
                                outline: "none",
                            }} placeholder='Address' className='' value={address} onChange={(e) => {
                                setAddress(e.target.value)
                                shouldSaveAddress.current = true
                            }} />
                            <FaRegCopy style={{
                                cursor: "pointer",
                                marginLeft: "0.5rem",
                            }} size={20} color='#84A1C8' onClick={() => copyAddress()} />
                        </div>

                    }
                    <div className='ButtonsDivBottom'>
                        {
                            (user.userType === "SuperAdmin") && (   (lead?.fidiumCustomer && lead?.fidiumCustomer?.crmId) || (lead.offer && lead.offer.crmId) ) &&
                            <div>
                                <SiTheconversation className='icon' size={25} color='#2761D0' onClick={() => {
                                    let crmId = lead?.fidiumCustomer?.crmId ? lead?.fidiumCustomer?.crmId : lead.offer.crmId
                                    window.open(`https://app.gohighlevel.com/v2/location/2bpuwFoxpdxZs2Oq2Z8a/contacts/detail/${crmId}`)
                                }} />
                            </div>
                        }
                        <div >
                            <RxReset className='icon' size={25} color='#2761D0' onClick={() => { updateCoordinates(lead?.fiberHouse) }} />
                        </div>
                        {/* <div style={{
                            borderBottom: lead?.isHotLead ? '6px solid red' : 'none', borderRadius: '5px'
                        }} className='HotLeadDiv'>
                            <FaFire className='icon' size={25} color='#ccc' />
                        </div> */}
                        <div style={{ borderRadius: '5px' }} className='QRCodeDiv'>
                            <IoQrCode className='icon' size={25} color='#2761D0' onClick={() => setIndex(14)} />
                        </div>

                        {/* <div style={{ borderBottom: lead?.assignedConvertedToCustomer ? '6px solid green' : "none", borderRadius: '5px' }} className='FreeInstallFormDiv'>
                            <MdEditDocument className='icon' size={25} color='#2761D0' onClick={() => setModalOpen(true)} />
                        </div> */}
                        <div>
                            <FaAddressCard className='icon' size={25} color='#2761D0' onClick={() => setIndex(17)} />
                        </div>
                    </div>

                    <hr style={{
                        gridColumn: "span 3/span 3"
                    }} />
                    <div style={{
                        gridColumn: "span 3/ span 3",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        // gap: "1rem" // Add gap between grid items
                    }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input
                                placeholder='No Email'
                                className='email_phone_input'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{
                                    fontSize: "1rem"
                                }}

                            // style={{ flex: 1 }}
                            />
                            {email &&
                                <FaRegCopy
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    size={20}
                                    color='#84A1C8'
                                    onClick={copyEmail}
                                />
                            }
                        </div>

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <input
                                placeholder='No Phone'
                                className='email_phone_input'
                                value={phone}
                                style={{
                                    fontSize: "1rem"
                                }}
                                onChange={(e) => setPhone(e.target.value)}
                            // style={{ flex: 1 }}
                            />
                            {phone &&
                                <FaRegCopy
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    size={20}
                                    color='#84A1C8'
                                    onClick={copyPhone}
                                />
                            }
                        </div>
                    </div>
                    <hr style={{
                        gridColumn: "span 3/span 3"
                    }} />
                    <div style={{
                        gridColumn: "span 3/span 3",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr"
                    }}>
                        <div>
                            <p onClick={() => {
                                setOpenModal(true)
                            }}
                                className="date_label"
                            >Set Follow-Up</p>
                        </div>
                        {lead?.appointmentDateTime ?
                            <p>{new Date(lead?.appointmentDateTime).toLocaleDateString() + " " + new Date(lead?.appointmentDateTime).toLocaleTimeString()}</p>
                            :
                            <p>No Appointment Date</p>
                        }
                        {
                            fidiumCustomer &&
                            <>
                                <div>
                                    <p
                                        onClick={() => {
                                            setOpenInstallTimeModal(true)
                                        }}
                                        className="date_label"
                                    >
                                        {
                                            lead?.fidiumCustomer?.installDateTime ? "Request Install Reschedule" : "Set Install Date"
                                        }
                                    </p>
                                </div>
                                {lead?.fidiumCustomer?.installDateTime ? <p>Current Install Date: {new Date(lead?.fidiumCustomer?.installDateTime).toLocaleDateString() + " " + new Date(lead?.fidiumCustomer?.installDateTime).toLocaleTimeString(
                                    'en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                }
                                )} -  {lead?.fidiumCustomer?.updatedInstallDateTimeTo ? new Date(lead?.fidiumCustomer?.updatedInstallDateTimeTo).toLocaleTimeString(
                                    "en-US", {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                }
                                ) : new Date(lead?.fidiumCustomer?.originalIntstallDateTimeTo).toLocaleTimeString(
                                    "en-US", {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                }
                                )} </p>
                                : <p>Not Scheduled</p>
                            }

                            </>
                        }
                        {
                            fidiumCustomer && fidiumCustomer.fiberPlan &&
                            <>
                                <div>
                                    <p>Current Plan</p>
                                </div>
                                <p>{fidiumCustomer.fiberPlan.name}</p>
                            </>
                        }
                        {
                            fidiumCustomer && fidiumCustomer.originalPlan && fidiumCustomer.originalPlanId !== fidiumCustomer.fiberPlanId &&
                            <>
                                <div>
                                    <p>Original Plan</p>
                                </div>
                                <p>{fidiumCustomer.originalPlan.name}</p>
                            </>
                        }
                        {
                            fidiumCustomer && fidiumCustomer.feedbacks && fidiumCustomer.feedbacks.length > 0 &&
                            <>
                                <div>
                                    <p>Rating</p>
                                </div>
                                <Rating value={fidiumCustomer.feedbacks[0].rating} sx={{ color: "#faaf00 !important" }} readOnly />
                            </>
                        }

                        {/* {
                            fidiumCustomer && fidiumCustomer.wasCancelled === true &&
                            <div className='col-span-2 flex justify-center items-center'>
                                <button className='px-4 py-2 rounded-lg bg-green-400 text-white font-bold mt-2'>Save the Deal</button>
                            </div>
                        } */}
                    </div>
                </div>
                <Modal height={"80vh"} minHeight={"80vh"} open={modalOpen} setOpen={setModalOpen} >
                    <CreateCustomer fiberHouse={fiberHouse} lead={lead} setOpen={setModalOpen} setIndex={setIndex}
                        searchFiberHouses={searchFiberHouses} user={user} HotLeadForm={false} />
                </Modal>
            </div>
        </>
    )
}

export default LeadData