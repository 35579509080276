import React, { useContext, useEffect } from 'react'
import { MapContext } from '..';
import { useSwipeable } from 'react-swipeable';
import { ClickAwayListener } from '@mui/material';
import { IoMdArrowRoundBack } from 'react-icons/io';

const Popup = ({ children, open, onClose }) => {
  const { index, setIndex, searchFiberHouses, triggerSearch } = useContext(MapContext)

  const popUpRef = React.useRef(null);
  const heightRef = React.useRef(null)
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)
  const [isMinimized, setIsMinimized] = React.useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])


  const onMinimize = () => {
    setIsMinimized(!isMinimized);
    setHeight(isMinimized ? window.innerHeight * 0.8 : window.innerHeight * 0.2);
  };

  const containerRef = React.useRef(null)
  const [height, setHeight] = React.useState(index === 11 || index === 12 || index === 4 || index === 13 || index === 20 || index === 8 ? window.innerHeight - 100 : window.innerHeight / 3)
  const handlers = useSwipeable({
    delta: 20,
    onSwiping: (eventData) => {
      // eventData.event.preventDefault()
      // if(index!==8  && index!==11 && index!==12)
      if (index !== 7)
        setHeight(
          (prev) => {
            if (eventData.first) {
              heightRef.current = height
            }
            if (index === 2) {
              return prev
            }
            if (((index !== 10)) && eventData.dir === "Down" && containerRef?.current?.scrollTop !== 0) {
              return prev
            }

            if ((index !== 10) && eventData.dir === "Up" && (containerRef?.current?.scrollHeight - containerRef?.current?.offsetHeight) !== containerRef?.current?.scrollTop) {
              return prev
            }

            if (((index !== 19)) && eventData.dir === "Down" && containerRef?.current?.scrollTop !== 0) {
              return prev
            }

            if ((index !== 19) && eventData.dir === "Up" && (containerRef?.current?.scrollHeight - containerRef?.current?.offsetHeight) !== containerRef?.current?.scrollTop) {
              return prev
            }


            // console.log(containerRef?.current?.scrollTop)
            // console.log(containerRef?.current?.scrollHeight - containerRef?.current?.offsetHeight)
            const newHeight = heightRef.current - eventData.deltaY
            // console.log(newHeight)
            if (newHeight <= window.innerHeight - 100 && newHeight >= window.innerHeight / 6) {
              return newHeight
            }
            else if (newHeight >= window.innerHeight - 100) {
              return parseInt(window.innerHeight - 100)
            }
            else {
              onClose()
              return parseInt(window.innerHeight / 6)
            }
          }
        )

      // console.log(eventData)
    },

  });


  const refPassthrough = (el) => {
    handlers.ref(el);
    popUpRef.current = el;
  }

  useEffect(
    () => {
      if (index === 2) {
        setHeight(window.innerHeight / 6)
        return
      }

      if (index === 11 || index === 12 || index === 4 || index === 13 || index === 8 || index === 14 || index === 20) {
        setHeight(window.innerHeight - 100)
      }
      else {
        setHeight(window.innerHeight / 1.5)
      }
      if (index === 7) {
        setHeight(window.innerHeight - 60)
      }
    }, [children, index]
  )

  return (
    open ?
      <ClickAwayListener onClickAway={() => {
        // setSwiped(false)
      }}>
        <div className='PopupContainer'

          style={{
            width: "100%",
            height: height + "px",
            // top: 110-height+"%",
            bottom: "0%",
            touchAction: "none",
            paddingTop: "0px",
            overflow: "hidden",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",

          }}
          ref={refPassthrough}
          {
          ...handlers
          }
        >
          <div style={{
            width: "100%",
            height: index !== 8 ? "20px" : "30px",
            touchAction: 'none',
            padding: "1rem",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          }}

          >

            {
              // index !== 8 && index!==11 && index!==12
              // ?
              <div style={{
                backgroundColor: "#ccc",
                width: "40px",
                height: "5px",
                borderRadius: "20px",
                margin: "0 auto",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",
                zIndex: 100,
              }} >
              </div>

              // :
              // index === 8 &&
              // <p style={{
              //   textAlign: "right",
              //   fontSize: "1rem",
              //   color: "#2761D0",
              //   margin:"auto 0",
              //   paddingBottom:"0.2rem",
              //   textDecoration:"underline",
              //   cursor:"pointer"
              // }} onClick={()=>{
              //   onClose()
              // }} >Done</p>
            }
            {
              index === 10 &&
              <p onClick={
                () => {
                  setIndex(13)
                }
              } style={{
                textDecoration: "underline",
                color: "#2761D0",
                fontSize: "0.8rem",
                fontWeight: "500"
              }}>See Activity</p>
            }
            {
              index === 19 &&
              <p onClick={
                () => {
                  setIndex(20)
                }
              } style={{
                textDecoration: "underline",
                color: "#2761D0",
                fontSize: "0.8rem",
                fontWeight: "500"
              }}>See Activity</p>
            }

          </div>
          <div style={{
            overflowY: (index === 2 || index === 10 || index === 19) ? "hidden" : "auto",
            overflowX: "hidden",
            height: "calc(100% - 30px)",
            // scrollbarWidth: "thin",
          }} ref={containerRef}>
            <div style={{
              display: "flex",
              justifyContent: index === 11 || index === 12 || index === 14 || index === 17 || index === 13 || index === 20 ? "space-between" : "flex-end",
              padding: "0.5rem",
              cursor: "pointer"
            }}>
              {
                (index === 11 || index === 12 || index === 14 || index === 17 || index === 13) &&
                <div className='go_back_button'>
                  <IoMdArrowRoundBack size={30} style={{
                    cursor: "pointer"
                  }} onClick={() => {
                    setIndex(10)
                  }} />
                </div>}
                {
                (index === 20) &&
                <div className='go_back_button'>
                  <IoMdArrowRoundBack size={30} style={{
                    cursor: "pointer"
                  }} onClick={() => {
                    setIndex(19)
                  }} />
                </div>}
              {index === 18 &&
                <p style={{
                  textDecoration: "underline",
                  color: "#2761D0",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  marginRight: "1rem"
                }} onClick={() => { onMinimize() }} >{isMinimized ? "Maximize" : "Minimize"}</p>

              }
              {
                index !== 19 ?
                  index !== 7 ?
                    index !== 10 ? <p style={{
                      textDecoration: "underline",
                      color: "#2761D0",
                      fontSize: "1rem",
                      fontWeight: "bold"
                    }} onClick={() => { onClose() }} >Close</p> : null
                    :
                    <p style={{
                      textDecoration: "underline",
                      color: "#2761D0",
                      fontSize: "1rem",
                      fontWeight: "bold"
                    }} onClick={() => {
                      if (screenWidth < 1000 && triggerSearch) {
                        searchFiberHouses();
                      }
                      onClose();
                    }} >Done</p>
                  : null
              }
            </div>
            {
              children
            }
          </div>

        </div>
      </ClickAwayListener>
      :
      <></>
  )
}

export default Popup