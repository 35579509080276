import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";

class FiberHouseApi{
    baseUrl = EnvSettings.url;
    CreateFiberHouseUrl = "CreateFiberHouseWithLead";
    BatchCreateFiberHouseWithLeadUrl = "BatchCreateFiberHousesWithLead";
    SearchFiberHouseUrl = "SearchFiberHouses";
    SearchFiberHousesOptimizedUrl = "SearchFiberHousesOptimized";
    searchFiberHousesOptimizedLightUrl = "SearchFiberHousesOptimizedLight";
    SearchFiberHousesOptimizedLightAssignedUnassignedUrl = "SearchFiberHousesOptimizedLightAssignedUnassigned";
    EditFiberHouseUrl = "EditFiberHouse";
    EditCandidateLeadUrl = "EditCandidate";
    DeleteFiberHouseUrl = "DeleteFiberHouse";
    EditLeadUrl = "EditLead";
    AssignRepsToLeadsUrl = "AssignRepsToLeads";
    RemoveRepsFromLeadsUrl = "RemoveRepsFromLeads";
    AddFiberHouseToSalesOrgUrl = "AddFiberHousesToSalesOrg";
    GetLeadByIdUrl = "GetLeadById";
    GetLeadByFiberHouseIdUrl = "GetLeadByFiberHouseId";
    BulkUpdateFiberHouseCoordinatesUrl = "BulkUpdateFiberHouseCoordinates";
    UpdateLeadAppointmentUrl = "UpdateLeadAppointment";
    RemoveFiberHousesFromSalesOrgUrl = "RemoveFiberHousesFromSalesOrg";
    MarkLeadAsBundledUrl = "MarkLeadAsBundled";
    MarkLeadHasVideoDoorBellUrl = "MarkLeadHasVideoDoorBell";
    UpdateSpeedTestImagesUrl = "UpdateSpeedTestImages";
    AddLeadsToCompanyUrl = "AddLeadsToCompany";
    GetNumberOfRequestedCustomersUrl = "GetNumberOfRequestedCustomers";
    ScheduleAppointmentForProspectUrl = "ScheduleAppointmentForProspect";
    IgnoreRequestedCustomersUrl = "IgnoreRequestedCustomers";
    GetCandidateLeadDetailsUrl = "GetCandidateById";
    UpdateCandidateLeadAppointmentUrl = "UpdateCandidateAppointmentDateTime";


    async IgnoreRequestedCustomers(req)
    {
        const res = await fetch(this.baseUrl + this.IgnoreRequestedCustomersUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async ScheduleAppointmentForProspect(req)
    {
        const res = await fetch(this.baseUrl + this.ScheduleAppointmentForProspectUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    

    async GetNumberOfRequestedCustomers(req)
    {
        const res = await fetch(this.baseUrl + this.GetNumberOfRequestedCustomersUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    
    async AddLeadsToCompany(req)
    {
        const res = await fetch(this.baseUrl + this.AddLeadsToCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async MarkLeadHasVideoDoorBell(req)
    {
        const res = await fetch(this.baseUrl + this.MarkLeadHasVideoDoorBellUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async MarkLeadAsBundled(req)
    {
        const res = await fetch(this.baseUrl + this.MarkLeadAsBundledUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    async RemoveFiberHousesFromSalesOrg(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveFiberHousesFromSalesOrgUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async UpdateLeadAppointment(req)
    {
        const res = await fetch(this.baseUrl + this.UpdateLeadAppointmentUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }
    

    async GetLeadByFiberHouseId(req)
    {
        const res = await fetch(this.baseUrl + this.GetLeadByFiberHouseIdUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchFiberHousesOptimizedLight(req)
    {
        const res = await fetch(this.baseUrl + this.searchFiberHousesOptimizedLightUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async SearchFiberHousesOptimizedLightAssignedUnassigned(req)
    {
        const res = await fetch(this.baseUrl + this.SearchFiberHousesOptimizedLightAssignedUnassignedUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async BulkUpdateFiberHouseCoordinates(req)
    {
        const res = await fetch(this.baseUrl + this.BulkUpdateFiberHouseCoordinatesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetLeadById(req)
    {
        const res = await fetch(this.baseUrl + this.GetLeadByIdUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AddFiberHouseToSalesOrg(req)
    {
        const res = await fetch(this.baseUrl + this.AddFiberHouseToSalesOrgUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }


    async RemoveRepsFromLeads(req)
    {
        const res = await fetch(this.baseUrl + this.RemoveRepsFromLeadsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async AssignRepsToLeads(req)
    {
        const res = await fetch(this.baseUrl + this.AssignRepsToLeadsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateFiberHouse(req)
    {
        const res = await fetch(this.baseUrl + this.CreateFiberHouseUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async BatchCreateFiberHouse(req)
    {
        const res = await fetch(this.baseUrl + this.BatchCreateFiberHouseWithLeadUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditFiberHouse(req)
    {
        const res = await fetch(this.baseUrl + this.EditFiberHouseUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchFiberHouse(req)
    {
        const res = await fetch(this.baseUrl + this.SearchFiberHouseUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async SearchFiberHousesOptimized(req)
    {
        const res = await fetch(this.baseUrl + this.SearchFiberHousesOptimizedUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async DeleteFiberHouse(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteFiberHouseUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditLead(req)
    {
        const res = await fetch(this.baseUrl + this.EditLeadUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //UpdateSpeedTestImages
    async UpdateSpeedTestImages(req)
    {
        const res = await fetch(this.baseUrl + this.UpdateSpeedTestImagesUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    //GetCandidateLeadDetails
    async GetCandidateLeadDetails(req)
    {
        const res = await fetch(this.baseUrl + this.GetCandidateLeadDetailsUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;  
    }

    //updateCandidateLeadAppointment
    async UpdateCandidateLeadAppointment(req)
    {
        const res = await fetch(this.baseUrl + this.UpdateCandidateLeadAppointmentUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;  
    }

    //EditCandidateLead
    async EditCandidateLead(req)
    {
        const res = await fetch(this.baseUrl + this.EditCandidateLeadUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;  
    }
}

export default new FiberHouseApi();