import React from 'react'
import { FaCheckCircle } from "react-icons/fa";
import './Plan.scss'
const Plan = ({ setColor,setSelectedPlan,setOpen,name,plan, bulletPoints,color }) => {


    function onClick(){
        setSelectedPlan(plan)
        setOpen(true)
        setColor(color)
    }

    return (
        <div className='plan_container'>
            <h1 className='plan_name' style={{color:color}} >{name}</h1>
            <p className='plan_price'><span>{plan?.price}</span> /mo*</p>
            <p className='plan_speedupto'><p style={{color:color}}>Speeds up to</p><p className='gig_speed'>{plan?.speed} GIG</p><span>{plan?.speed * 1000}<br/> Mbps</span></p>
            <p className='plan_desc'>Upload/Download Speeds</p>
            <ul className='plan_bullets'>
                {bulletPoints.split('\n').map((bulletPoint, index) => <li key={index}>
                    <FaCheckCircle color={color} />
                    <p>{bulletPoint}</p>
                </li>)}
            </ul>
            <button onClick={onClick} style={{backgroundColor:color}} className='plan_button'>Accept Offer</button>
            <p className='note'>* + $2.95 Network Surcharge Fee</p>
        </div>
    )
}

export default Plan