import React, { useState, useEffect, useContext, useRef } from 'react';
import QuickDate from '../../../components/QuickDate';
import { SearchFiltersContext, UserContext } from '../../../App';
import OrgApi from '../../../API/OrgApi';
import './index.scss';
import Layout from '../../../Layouts/Layout';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { MapContext } from '../../TeamAdmin/MapPage';
import MapIconApi from '../../../API/MapIconApi';
import { AiFillSchedule } from 'react-icons/ai';
import { GiConfirmed } from 'react-icons/gi';
import { IoIosSend } from 'react-icons/io';
import OrganizationSelector from '../../../components/OrganizationSelector';
import UserSelector from '../../../components/UserSelector';
import FiberCompanySelector from '../../../components/FiberCompanySelector';
import ReportReq from '../../../Requests/Users/SalesReportReq';
import { BiSolidDoorOpen } from 'react-icons/bi';
import { MdSchedule } from 'react-icons/md';


const formatDateToISOString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    // Concatenate without timezone offset
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};

const SalesGeneralReport = () => {
    const user = useContext(UserContext);
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState(formatDateToISOString(new Date(new Date().setHours(0, 0, 0, 0))));
    const [toDate, setToDate] = useState(formatDateToISOString(new Date(new Date().setHours(23, 59, 59, 999))));
    const [salesReps, setSalesReps] = useState([]);
    const [filterBy, setFilterBy] = useState('Team');
    const [orgWithName, setOrgWithName] = useState(null);
    const [quickDateValue, setQuickDateValue] = useState('Today');
    const [orgIds, setOrgIds] = useState([]);
    const [mapIcons, setMapIcons] = useState([]);
    const [fiberCompanies, setFiberCompanies] = useState([]);
    const [fiberCompanyWithName, setFiberCompanyWithName] = useState(null);
    const navigate = useNavigate();
    const [userWithName, setUserWithName] = useState(null);

     const searchFilter = useContext(SearchFiltersContext);

    const statusMap = {
        doorsKnocked: 'Knocked',
        doorsOpened: 'Opened',
        doorsSold: 'Sold',
        doorsScheduled: 'PendingInstalls',
        doorsInstalled: 'Completed',
        cancelRequests: 'PendingCancels',
        doorsCanceled: 'Canceled',
        rescheduleRequests: 'PendingReschedules',
        soldOnly: "SoldOnly",
        offersSent: 'OffersSent',
        offersAccepted: 'OffersAccepted'
    };

    useEffect(() => {
        getMapIcons();
        setQuickDateValue('Today');
    }, [])
    const getMapIcons = async () => {
        const res = await MapIconApi.GetAllMapIcons()
        if (res?.status?.success) {
            setMapIcons(res.data);
        }
    }

    const getSalesReportData = async () => {
        setLoading(true);
        let req = new ReportReq();

        req.filterBy = filterBy
        req.salesRepIds = salesReps
        req.orgIds = orgIds
        req.fiberCompanyIds = fiberCompanies

        if (user.userType === "SalesOrgAdmin" && filterBy === 'Me') {
            req.userId = user.id
            setUserWithName(
                [
                    {
                        id: user.id,
                        label: user.firstName + " " + user.lastName,
                    }
                ]
            )
        }
        if (user.userType === "SalesOrgAdmin" && filterBy === 'Team') {
            req.userId = null
            req.salesOrgId = user.salesOrgId
        }

        if (user.userType === "SalesRep") {
            req.userId = user.id
            req.salesOrgId = user.salesOrgId
        }


        if(user.userType === "SuperAdmin" && filterBy === 'Me'){
            req.userId = user.id
            setUserWithName(
                [
                    {
                        id: user.id,
                        label: user.firstName + " " + user.lastName,
                    }
                ]
            )
            setOrgWithName(null)
            setOrgIds(null)
            setSalesReps(null)
        }

        if(user.userType === "SuperAdmin" && filterBy === 'Team'){
            req.userId = null
            req.salesOrgId = null
        }

        if (fromDate && toDate) {
            req.fromDate = fromDate;
            req.toDate = toDate;
        }



        console.log(req);

        try {
            const res = await OrgApi.getGeneralSalesReportData(req);
            if (res?.status?.success) {
                setReportData(res.data);
                console.log(reportData)
            }
        } catch (err) {
            console.error('Error fetching sales report data:', err);
        } finally {
            setLoading(false);
        }
    };
    //console.log({fromDate: fromDate, toDate: toDate,quickDateValue: quickDateValue, passedOrgIds: orgWithName, knocked: true, leadType: "KnockedDoors", dateType: "KnockedDate"})
    useEffect(() => {
        setQuickDateValue('Today');
        getSalesReportData();
    }, [filterBy]);



    const moveToContactCenter = (type) => {

        let salesRepToSend = [];

        if (user.userType === 'SuperAdmin') {
            salesRepToSend = userWithName;
        }

        if (user.userType === 'SalesOrgAdmin') {
            salesRepToSend = filterBy === "Me" ? [{
                id: user.id,
                label: user.firstName + " " + user.lastName
            }] : salesReps;
        }


        switch (type) {
            case statusMap.doorsSold:
                searchFilter.current = {
                    ...searchFilter.current,
                    contactCenter:{
                        fromDate: fromDate,
                        toDate: toDate,
                        salesReps: salesRepToSend,
                        quickDateValue: quickDateValue,
                        type: statusMap.doorsSold,
                        passedOrgIds: orgWithName,
                        fiberCompanies: fiberCompanyWithName,
                        dateType: "DateSold"
                    }
                }
                navigate('/contact-center');
                break;
            case statusMap.doorsScheduled:
                searchFilter.current = {
                    ...searchFilter.current,
                    contactCenter:{
                        fromDate: fromDate,
                        toDate: toDate,
                        salesReps: salesRepToSend,
                        quickDateValue: quickDateValue,
                        type: statusMap.doorsScheduled,
                        passedOrgIds: orgWithName,
                        fiberCompanies: fiberCompanyWithName,
                        dateType: "DateSold"
                    }
                }
                navigate('/contact-center');
                break;

            case statusMap.doorsCanceled:
                searchFilter.current = {
                    ...searchFilter.current,
                    contactCenter:{
                        fromDate: fromDate,
                        toDate: toDate,
                        salesReps: salesRepToSend,
                        quickDateValue: quickDateValue,
                        type: statusMap.doorsCanceled,
                        passedOrgIds: orgWithName,
                        fiberCompanies: fiberCompanyWithName,
                        dateType: "DateSold"
                    }
                }
                navigate('/contact-center');
                break;

            case statusMap.doorsInstalled:
                searchFilter.current = {
                    ...searchFilter.current,
                    contactCenter: {
                        fromDate: fromDate,
                        toDate: toDate,
                        salesReps: salesRepToSend,
                        quickDateValue: quickDateValue,
                        type: statusMap.doorsInstalled,
                        passedOrgIds: orgWithName,
                        fiberCompanies: fiberCompanyWithName,
                        dateType: "InstallDate"
                    }
                }
                navigate('/contact-center');
                break;

            case statusMap.cancelRequests:
                searchFilter.current = {
                    ...searchFilter.current,
                    contactCenter: {
                        fromDate: fromDate,
                        toDate: toDate,
                        salesReps: salesRepToSend,
                        quickDateValue: quickDateValue,
                        type: statusMap.cancelRequests,
                        passedOrgIds: orgWithName,
                        fiberCompanies: fiberCompanyWithName,
                        dateType: "CancelRequestTime"
                    }
                }
                navigate('/contact-center');
                break;

            case statusMap.rescheduleRequests:
                searchFilter.current = {
                    ...searchFilter.current,
                    contactCenter: {
                        fromDate: fromDate,
                        toDate: toDate,
                        salesReps: salesRepToSend,
                        quickDateValue: quickDateValue,
                        type: statusMap.rescheduleRequests,
                        passedOrgIds: orgWithName,
                        fiberCompanies: fiberCompanyWithName,
                        dateType: "RescheduleRequestTime"
                    }
                }
                navigate('/contact-center');
                break;

            case statusMap.soldOnly:
                searchFilter.current = {
                    ...searchFilter.current,
                    contactCenter: {
                        fromDate: fromDate,
                        toDate: toDate,
                        salesReps: salesRepToSend,
                        quickDateValue: quickDateValue,
                        type: statusMap.soldOnly,
                        passedOrgIds: orgWithName,
                        fiberCompanies: fiberCompanyWithName,
                        dateType: "DateSold"
                    }
                }
                navigate('/contact-center')
                break;
            case statusMap.offersSent:
                searchFilter.current = {
                    ...searchFilter.current,
                    contactCenter: {
                        fromDate: fromDate,
                        toDate: toDate,
                        salesReps: salesRepToSend,
                        quickDateValue: quickDateValue,
                        type: statusMap.offersSent,
                        passedOrgIds: orgWithName,
                        fiberCompanies: fiberCompanyWithName,
                        dateType: "OfferSentTime",
                        leadType: "Prospect"
                    }
                }
                navigate('/contact-center');
                break;
            case statusMap.offersAccepted:
                searchFilter.current = {
                    ...searchFilter.current,
                    contactCenter: {
                        fromDate: fromDate,
                        toDate: toDate,
                        salesReps: salesRepToSend,
                        quickDateValue: quickDateValue,
                        type: statusMap.offersAccepted,
                        passedOrgIds: orgWithName,
                        fiberCompanies: fiberCompanyWithName,
                        dateType: "OfferAcceptedTime",
                        leadType: "RequestedCustomer"
                    }
                }
                navigate('/contact-center');
                break;
            default:
                break;
        }
    }


    const moveToMap = (type) => {
        let mapicons = mapIcons;

        switch (type) {
            case statusMap.doorsKnocked:
                mapicons = mapicons.filter(mi => mi.tags.filter(t => t.tag.abbreviation === 'K').length > 0);
                console.log("mapicons", mapicons)
                mapicons = mapicons.map(mi => mi.id);
                searchFilter.current = {
                    ...searchFilter.current,
                    map:  {
                        assignedFromDate: fromDate,
                        unassignedFromDate: fromDate,
                        assignedToDate: toDate,
                        unassignedToDate: toDate,
                        assignedMapIcons: mapicons,
                        unassignedMapIcons: mapicons,
                        salesReps: salesReps,
                        assignedOrgIds: orgIds,
                        unassignedOrgIds: orgIds,
                        assignedQuickDateValue: quickDateValue,
                        unassignedQuickDateValue: quickDateValue,
                        assignedFiberCompanies: fiberCompanyWithName,
                        unassignedFiberCompanies: fiberCompanyWithName,
                        unassignedEnabled: false,
                        iconOnceWas: true
                    }
                }
                navigate('/map');
                break;
            case statusMap.doorsOpened:
                mapicons = mapicons.filter(mi => mi.tags.filter(t => t.tag.abbreviation === 'DO').length > 0);
                 mapicons = mapicons.map(mi => mi.id);
                console.log("mapicons", mapicons)
                searchFilter.current = {
                    ...searchFilter.current,
                    map:  {
                        assignedFromDate: fromDate,
                        unassignedFromDate: fromDate,
                        assignedToDate: toDate,
                        unassignedToDate: toDate,
                        assignedMapIcons: mapicons,
                        unassignedMapIcons: mapicons,
                        salesReps: salesReps,
                        assignedOrgIds: orgIds,
                        unassignedOrgIds: orgIds,
                        assignedQuickDateValue: quickDateValue,
                        unassignedQuickDateValue: quickDateValue,
                        assignedFiberCompanies: fiberCompanyWithName,
                        unassignedFiberCompanies: fiberCompanyWithName,
                        unassignedEnabled: false,
                        iconOnceWas: true
                    }
                }
                navigate('/map');
                break;
            default:
                break;
        }

    }




    return (
        <Layout>
            <div className="sales-report-container">
                <div className="filter-container">
                    {(user.userType === "SalesOrgAdmin" || (user.userType === "SuperAdmin"))  && 
                        <div className="toggle-switch">
                            <label>{"Viewing " + filterBy}</label>
                            <input
                                type="checkbox"
                                value={filterBy}
                                checked={filterBy === 'Team'}
                                onChange={() => setFilterBy(filterBy === 'Me' ? 'Team' : 'Me')}
                            />
                        </div>
                    }
                    <QuickDate selectQuickDate="Today" setFrom={setFromDate} setTo={setToDate} setQuickDateValue={setQuickDateValue} />

                    {user.userType === "SuperAdmin" && filterBy === "Team" &&
                        <div className="selector_con">
                            <div className='single'>
                                <FiberCompanySelector setCompanyObject={setFiberCompanyWithName} companiesWithOfferTemplateOnly={true} placeholder='Select Fiber Company' setUser={setFiberCompanies} multiple={true} />
                            </div>
                            <div className='single'>
                                <OrganizationSelector multiple={true} setOrgWithName={setOrgWithName} setUser={setOrgIds} placeholder='Select Organizations' />
                            </div>
                            <div className='single'>
                                <UserSelector setUserWithName={setUserWithName} salesOrgIds={orgIds} SalesOrgId={user.userType === "SalesOrgAdmin" ? user.salesOrgId : null} setUser={setSalesReps} conversationType={"Group"} placeholder='Select SalesReps' userTypes={["SalesRep", "SalesOrgAdmin"]} />
                            </div>
                        </div>
                    }

                    <button onClick={() => getSalesReportData()}>Fetch Report</button>
                </div>

                <div className="report-summary">
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <CircularProgress />
                        </div>
                    ) : reportData ? (
                        <div className="summary-cards">

                            {/* Doors Knocked  */}
                            <div className="summary-card" onClick={() => moveToMap(statusMap.doorsKnocked)}>
                                <div className="card-value">{reportData.doorsKnocked}</div>
                                <div className="card-label"><img className='img' src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === 1)?.image} alt="Map Icon" />Doors Knocked</div>
                            </div>


                            {/* Doors Opened  */}
                            <div className="summary-card" onClick={() => moveToMap(statusMap.doorsOpened)}>
                                <div className="card-value">{reportData.doorsOpened}</div>
                                <div className="card-label"><BiSolidDoorOpen color='#2761D0' size={60} />Doors Opened</div>
                            </div>

                            {/* Doors Sold  */}
                            <div className="summary-card" onClick={() => moveToContactCenter(statusMap.doorsSold)}>
                                <div className="card-value">{reportData.doorsSold}</div>
                                <div className="card-label"><img className='img' src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === 9)?.image} alt="Map Icon" />Doors Sold</div>
                            </div>

                            {/* Doors Scheduled  */}
                            <div className="summary-card" onClick={() => moveToContactCenter(statusMap.doorsScheduled)}>
                                <div className="card-value">{reportData.doorsScheduled} </div>
                                {
                                    reportData.doorsSoldOnly ?
                                        <p onClick={(e) => {
                                            e.stopPropagation();
                                            moveToContactCenter(statusMap.soldOnly)
                                        }}>{reportData.doorsSoldOnly ? reportData.doorsSoldOnly : 0} Unscheduled</p>
                                        : ""
                                }
                                <div className="card-label"><AiFillSchedule color='#2761D0' size={60} />Doors Scheduled</div>
                            </div>

                            {/* Doors Canceled  */}
                            <div className="summary-card" onClick={() => moveToContactCenter(statusMap.doorsCanceled)}>
                                <div className="card-value">{reportData.doorsCanceled}</div>
                                <div className="card-label"><img className='img' src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === 28)?.image} alt="Map Icon" />Doors Canceled</div>
                            </div>

                            {/* Doors Installed */}
                            <div className="summary-card" onClick={() => moveToContactCenter(statusMap.doorsInstalled)}>
                                <div className="card-value">{reportData.doorsInstalled}</div>
                                <div className="card-label"><img className='img' src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === 10)?.image} alt="Map Icon" />Doors Installed</div>
                            </div>

                            {/* Cancel Requests */}
                            <div className="summary-card" onClick={() => moveToContactCenter(statusMap.cancelRequests)}>
                                <div className="card-value">{reportData.cancelRequests}</div>
                                <div className="card-label"><img className='img' src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === 34)?.image} alt="Map Icon" />Cancel Requests</div>
                            </div>

                            {/* Reschedule Requests */}
                            <div className="summary-card" onClick={() => moveToContactCenter(statusMap.rescheduleRequests)}>
                                <div className="card-value">{reportData.rescheduleRequests}</div>
                                <div className="card-label"><MdSchedule color='#2761D0' size={60} /> Reschedule Requests</div>
                            </div>

                            {/* Offers Sent */}
                            <div className="summary-card" onClick={() => {
                                moveToContactCenter(statusMap.offersSent)
                            }} >
                                <div className="card-value">{reportData.offersSent}</div>
                                <div className="card-label"><IoIosSend color='#2761D0' size={60} /> Offers Sent</div>
                            </div>

                            {/* Offers Accepted */}
                            <div className="summary-card" onClick={() => {
                                moveToContactCenter(statusMap.offersAccepted)
                            }} >
                                <div className="card-value">{reportData.offersAccepted}</div>
                                <div className="card-label"><GiConfirmed color='#2761D0' size={60} />Offers Accepted</div>
                            </div>
                        </div>
                    ) : (
                        <div className="summary-cards">
                            <div className="summary-card">
                                <div className="card-value">0</div>
                                <div className="card-label"><img className='img' src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === 1)?.image} alt="Map Icon" />Doors Knocked</div>
                            </div>
                            <div className="summary-card">
                                <div className="card-value">0</div>
                                <div className="card-label"><BiSolidDoorOpen color='#2761D0' size={60} />Doors Opened</div>
                            </div>
                            <div className="summary-card">
                                <div className="card-value">0</div>
                                <div className="card-label"><img className='img' src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === 9)?.image} alt="Map Icon" />Doors Sold</div>
                            </div>
                            <div className="summary-card">
                                <div className="card-value">0</div>
                                <div className="card-label"><AiFillSchedule color='#2761D0' size={60} /> Doors Scheduled</div>
                            </div>
                            <div className="summary-card">
                                <div className="card-value">0</div>
                                <div className="card-label"><img className='img' src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === 10)?.image} alt="Map Icon" />Doors Installed</div>
                            </div>
                            <div className="summary-card">
                                <div className="card-value">0</div>
                                <div className="card-label"><img className='img' src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === 34)?.image} alt="Map Icon" />Cancel Requests</div>
                            </div>
                            <div className="summary-card">
                                <div className="card-value">0</div>
                                <div className="card-label"><img className='img' src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === 28)?.image} alt="Map Icon" />Doors Canceled</div>
                            </div>
                            <div className="summary-card">
                                <div className="card-value">0</div>
                                <div className="card-label"><GiConfirmed color='#2761D0' size={60} />Offers Accepted</div>
                            </div>
                            <div className="summary-card">
                                <div className="card-value">0</div>
                                <div className="card-label"><IoIosSend color='#2761D0' size={60} />Offers Sent</div>
                            </div>
                        </div>
                    )}
                </div>
                <button className='btn' onClick={() => navigate('/sales-reporting-page')}>LeaderBoard & Door Data</button>
            </div>
        </Layout>
    );
};

export default SalesGeneralReport;
