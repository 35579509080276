import { useState, useEffect } from 'react';
import fastwyreLogo from "./../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorizedFastwyre.png";
import Swal from 'sweetalert2';
import CustomerApi from '../../../API/CustomerApi';
import { useParams } from 'react-router-dom';
import GetOfferByGuidReq from '../../../Requests/Offer/GetOfferByGuidReq';
import OfferApi from '../../../API/OfferApi';
import { Check } from '@mui/icons-material';

export default function CustomerForm() {

    const { offerGuid } = useParams();

    const [showPetInstructions, setShowPetInstructions] = useState(false);

    // State variables for each input
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [secondaryHolder, setSecondaryHolder] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [apartment, setApartment] = useState('');
    const [cityStateZip, setCityStateZip] = useState('');
    const [internetSpeed, setInternetSpeed] = useState('');
    const [eligibility, setEligibility] = useState('');
    const [installDay, setInstallDay] = useState('');
    const [flexibleSchedule, setFlexibleSchedule] = useState(false);
    const [timePreference, setTimePreference] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [petInstructions, setPetInstructions] = useState('');
    const [highSchool, setHighSchool] = useState('');
    const [mothersMaidenName, setMothersMaidenName] = useState('');
    const [ssnLast4, setSsnLast4] = useState('');

    const [offer, setOffer] = useState(null);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getOffer = async () => {
            setLoading(true)
            // console.log(id)
            const req = new GetOfferByGuidReq();
            req.string = offerGuid;
            req.int = 1;
            req.pagingParams.pageNumber = 1;
            req.pagingParams.pageSize = 10;
            const res = await OfferApi.GetOfferByGuid(req);
            // console.log(res)
            if (res?.status?.success) {
                setOffer(res.data)
                setFirstName(res?.data?.lead?.name?.split(" ")[0])
                setLastName(res?.data?.lead?.name?.split(" ")[1])
                setEmail(res?.data.lead?.email)
                setPhone(res?.data?.lead?.phone)
                setAddress(res?.data?.lead?.fiberHouse?.mapString)
                setLoading(false)
                // }
            }
        }
        getOffer()
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        Swal.fire({
            icon: "info",
            title: "Please wait",
            text: "We are processing your request",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if (!firstName) {
            Swal.fire({
                title: "Error",
                text: "First Name is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lastName) {
            Swal.fire({
                title: "Error",
                text: "Last Name is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!email) {
            Swal.fire({
                title: "Error",
                text: "Email is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!phone) {
            Swal.fire({
                title: "Error",
                text: "Phone is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!address) {
            Swal.fire({
                title: "Error",
                text: "Address is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!cityStateZip) {
            Swal.fire({
                title: "Error",
                text: "City, State, Zip is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!internetSpeed) {
            Swal.fire({
                title: "Error",
                text: "Internet Speed is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!eligibility) {
            Swal.fire({
                title: "Error",
                text: "Eligibility is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!installDay) {
            Swal.fire({
                title: "Error",
                text: "Installation Day is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!timePreference) {
            Swal.fire({
                title: "Error",
                text: "Time Preference is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!additionalInfo) {
            Swal.fire({
                title: "Error",
                text: "Additional Info is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (additionalInfo === 'yes' && !petInstructions) {
            Swal.fire({
                title: "Error",
                text: "Pet Instructions is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!highSchool) {
            Swal.fire({
                title: "Error",
                text: "High School is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!mothersMaidenName) {
            Swal.fire({
                title: "Error",
                text: "Mother's Maiden Name is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!ssnLast4) {
            Swal.fire({
                title: "Error",
                text: "Last 4 digits of SSN is required",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        // ssnLast4 validation
        let ssnRegex = new RegExp("^[0-9]{4}$");
        if (!ssnRegex.test(ssnLast4)) {
            Swal.fire({
                title: "Error",
                text: "Last 4 digits of SSN is invalid. Please enter a valid 4 digit number",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }



        let formData = {
            secondaryHolder: secondaryHolder,
            eligibility: eligibility,
            installDay: installDay,
            timePreference: timePreference,
            highSchool: highSchool,
            mothersMaidenName: mothersMaidenName,
            ssnLast4: ssnLast4,
            cityStateZip: cityStateZip,
            internetSpeed: internetSpeed,
        }

        let req = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            address: address,
            formData: JSON.stringify(formData),
            apartment: apartment,
            cityStateZip: cityStateZip,
            leadId: offer.leadId
        }

        let res = await CustomerApi.SubmitFastwyreCompleteForm(req);

        if (res?.status?.success) {
            Swal.fire({
                title: "Success",
                text: "Form submitted successfully. Our Representative will contact you soon.",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
        else {
            Swal.fire({
                title: "Error",
                text: res.status.message,
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }



    }


    return (
        <div className="min-h-screen bg-gradient-to-r from-[#022780] to-[#000614] p-8">
            <div className="max-w-3xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
                <div className="p-6">
                    <img src={fastwyreLogo} alt="Fastwyre Logo" className="w-full h-32 object-contain object-center" />
                    <h2 className="text-2xl font-bold text-[#2761D0] text-center mb-6">New Customer Information</h2>
                    <form onSubmit={handleSubmit} className="space-y-8">
                        {/* 1. New Customer Information */}
                        <div className="space-y-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">First Name *</label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        value={firstName}
                                        required
                                        onChange={(e) => setFirstName(e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">Last Name *</label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        value={lastName}
                                        required
                                        onChange={(e) => setLastName(e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="secondaryHolder" className="block text-sm font-medium text-gray-700 mb-1">Secondary account holder's full name (if applicable)</label>
                                <input
                                    type="text"
                                    id="secondaryHolder"
                                    value={secondaryHolder}
                                    onChange={(e) => setSecondaryHolder(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                />
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email Address *</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                />
                            </div>
                            <div>
                                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone Number *</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    required
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                />
                            </div>
                            <div>
                                <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">Address *</label>
                                <input
                                    type="text"
                                    id="address"
                                    value={address}
                                    required
                                    onChange={(e) => setAddress(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                />
                            </div>
                            <div>
                                <label htmlFor="apartment" className="block text-sm font-medium text-gray-700 mb-1">Apartment/Suite/Unit/Lot number (if applicable)</label>
                                <input
                                    type="text"
                                    id="apartment"
                                    value={apartment}
                                    onChange={(e) => setApartment(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                />
                            </div>
                            {/* <div>
                                <label htmlFor="cityStateZip" className="block text-sm font-medium text-gray-700 mb-1">City, State, Zip/Postal code *</label>
                                <input
                                    type="text"
                                    id="cityStateZip"
                                    value={cityStateZip}
                                    onChange={(e) => setCityStateZip(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                />
                            </div> */}
                        </div>

                        {/* 2. Internet Speed Options */}
                        <div>
                            <h3 className="text-lg font-semibold text-[#2761D0] text-center uppercase mb-2">Internet Speed Options *</h3>
                            {/* <div className="space-y-2">
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="internetSpeed"
                                        value="2GB"
                                        checked={internetSpeed === '2GB'}
                                        onChange={(e) => setInternetSpeed(e.target.value)}
                                        className="form-radio text-[#2761D0]"
                                    />
                                    <span>2GB (2,000 Mbps): $69.99/month + $2.95 network surcharge</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="internetSpeed"
                                        value="1GB"
                                        checked={internetSpeed === '1GB'}
                                        onChange={(e) => setInternetSpeed(e.target.value)}
                                        className="form-radio text-[#2761D0]"
                                    />
                                    <span>1GB (1,000 Mbps): $49.99/month + $2.95 network surcharge</span>
                                </label>
                            </div> */}
                            {/* <ul className="list-disc pl-5 mt-2 text-sm">
                                {
                                    offer?.offerTemplate?.bulletPoints?.split("\n").map((point,index) => {
                                        return <li key={index}>{point}</li>
                                    })
                                }
                            </ul> */}
                            <div className="space-y-6">
                              
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <label className={`relative cursor-pointer ${internetSpeed === '1GB' ? 'ring-2 ring-[#2761D0]' : 'ring-1 ring-gray-200'} rounded-lg p-4 transition-all duration-200`}>
                                        <input
                                            type="radio"
                                            name="internetSpeed"
                                            value="1GB"
                                            checked={internetSpeed === '1GB'}
                                            onChange={() => {
                                                setInternetSpeed('1GB')
                                            }}
                                            className="sr-only"
                                        />
                                        <div className="space-y-2">
                                            <div className="font-semibold text-gray-600">Fastwyre</div>
                                            <div className="text-3xl font-bold">1 Gig</div>
                                            <div className="text-2xl font-bold">$49.99/mo*</div>
                                        </div>
                                        {internetSpeed === '1GB' && (
                                            <div className="absolute -top-2 -right-2 w-6 h-6 bg-[#2761D0] rounded-full flex items-center justify-center">
                                                <Check className="w-4 h-4 text-white" />
                                            </div>
                                        )}
                                    </label>
                                    <label className={`relative cursor-pointer ${internetSpeed === '2GB' ? 'ring-2 ring-[#2761D0]' : 'ring-1 ring-gray-200'} rounded-lg p-4 transition-all duration-200`}>
                                        <input
                                            type="radio"
                                            name="internetSpeed"
                                            value="2GB"
                                            checked={internetSpeed === '2GB'}
                                            onClick={() => {
                                                setInternetSpeed('2GB')
                                            }}
                                            className="sr-only"
                                        />
                                        <div className="space-y-2">
                                            <div className="font-semibold text-gray-600">Fastwyre</div>
                                            <div className="text-3xl font-bold">2 Gig</div>
                                            <div className="text-2xl font-bold">$69.99/mo*</div>
                                        </div>
                                        {internetSpeed === '2GB' && (
                                            <div className="absolute -top-2 -right-2 w-6 h-6 bg-[#2761D0] rounded-full flex items-center justify-center">
                                                <Check className="w-4 h-4 text-white" />
                                            </div>
                                        )}
                                    </label>
                                </div>
                                <div className="space-y-3">
                                    {offer?.offerTemplate?.bulletPoints?.split('\n').map((benefit, index) => (
                                        <div key={index} className="flex items-center gap-2 text-gray-600">
                                            <div className="w-5 h-5 rounded-full bg-[#2761D0] flex items-center justify-center flex-shrink-0">
                                                <Check className="w-3 h-3 text-white" />
                                            </div>
                                            <span>{benefit}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* 3. Eligibility Questions for Discounts */}
                        <div>
                            <h3 className="text-lg font-semibold text-[#2761D0] text-center uppercase mb-2">Eligibility Questions for 10% Discount</h3>
                            <label htmlFor="eligibility" className="block text-sm font-medium text-gray-700 mb-1">Are you military, a senior, a first responder, or an educator? *</label>
                            <select
                                id="eligibility"
                                value={eligibility}
                                onChange={(e) => setEligibility(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                            >
                                <option value="">Select an option</option>
                                <option value="military">Military</option>
                                <option value="senior">Senior</option>
                                <option value="firstResponder">First Responder</option>
                                <option value="educator">Educator</option>
                                <option value="none">None of the above</option>
                            </select>
                        </div>

                        {/* 4. Installation Scheduling */}
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-lg font-semibold text-[#2761D0] text-center uppercase mb-2">Installation Scheduling</h3>
                                <label htmlFor="installDay" className="block text-sm font-medium text-gray-700 mb-1">Preferred installation day (Monday-Friday) *</label>
                                <select
                                    id="installDay"
                                    value={installDay}
                                    onChange={(e) => setInstallDay(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                >
                                    <option value="">Select a day</option>
                                    <option value="monday">Monday</option>
                                    <option value="tuesday">Tuesday</option>
                                    <option value="wednesday">Wednesday</option>
                                    <option value="thursday">Thursday</option>
                                    <option value="friday">Friday</option>
                                    <option value="asap">As soon as possible</option>
                                </select>
                            </div>
                            {/* <div className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    id="flexibleSchedule"
                                    checked={flexibleSchedule}
                                    onChange={(e) => setFlexibleSchedule(e.target.checked)}
                                    className="form-checkbox text-[#2761D0]"
                                />
                                <label htmlFor="flexibleSchedule">My schedule is flexible, set installation at earliest available date</label>
                            </div> */}
                            <div>
                                <label htmlFor="timePreference" className="block text-sm font-medium text-gray-700 mb-1">Time Preference *</label>
                                <select
                                    id="timePreference"
                                    value={timePreference}
                                    onChange={(e) => setTimePreference(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                >
                                    <option value="">Select a time</option>
                                    <option value="morning">Morning</option>
                                    <option value="afternoon">Afternoon</option> 
                                </select>
                            </div>
                        </div>

                        {/* 5. Additional Information */}
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-lg font-semibold text-[#2761D0] text-center uppercase mb-2">Additional Information</h3>
                                <label htmlFor="additionalInfo" className="block text-sm font-medium text-gray-700 mb-1">Is there anything we should know about (Aggressive Pets, Locked Gates and Gate Codes) ? *</label>
                                <select
                                    id="additionalInfo"
                                    value={additionalInfo}
                                    onChange={(e) => {
                                        setAdditionalInfo(e.target.value);
                                        setShowPetInstructions(e.target.value === 'yes');
                                    }}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                >
                                    <option value="">Select an option</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            {showPetInstructions && (
                                <div>
                                    <label htmlFor="petInstructions" className="block text-sm font-medium text-gray-700 mb-1">Provide details below: *</label>
                                    <textarea
                                        id="petInstructions"
                                        value={petInstructions}
                                        onChange={(e) => setPetInstructions(e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                        rows={3}
                                    ></textarea>
                                </div>
                            )}
                        </div>

                        {/* 6. Security Questions */}
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-lg font-semibold text-[#2761D0] text-center uppercase mb-2">Security Questions</h3>
                                <label htmlFor="highSchool" className="block text-sm font-medium text-gray-700 mb-1">High school attended/graduated *</label>
                                <input
                                    type="text"
                                    id="highSchool"
                                    value={highSchool}
                                    onChange={(e) => setHighSchool(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                />
                            </div>
                            <div>
                                <label htmlFor="mothersMaidenName" className="block text-sm font-medium text-gray-700 mb-1">Mother's maiden name *</label>
                                <input
                                    type="text"
                                    id="mothersMaidenName"
                                    value={mothersMaidenName}
                                    onChange={(e) => setMothersMaidenName(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                />
                            </div>
                            <div>
                                <label htmlFor="ssnLast4" className="block text-sm font-medium text-gray-700 mb-1">Last four digits of social security number *</label>
                                <input
                                    type="text"
                                    id="ssnLast4"
                                    value={ssnLast4}
                                    onChange={(e) => setSsnLast4(e.target.value)}
                                    maxLength={4}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2761D0]"
                                />
                            </div>
                        </div>

                        <button type="submit" className="w-full bg-[#2761D0] text-white py-2 px-4 rounded-md hover:bg-[#2761D0]/90 focus:outline-none focus:ring-2 focus:ring-[#2761D0] focus:ring-offset-2">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}