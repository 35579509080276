import React, { useContext, useEffect, useRef } from 'react'
import ReviewContainer from '../../../../Reps/DigitalBusinessCenter/components/ReviewContainer'
import { MdEmail, MdLocationOn, MdOutlineLocalPhone, MdOutlineMail } from 'react-icons/md'
import { FaPhone } from 'react-icons/fa'
import { UserContext } from '../../../../../App'
import { GiWireframeGlobe } from 'react-icons/gi'
import ContactButtons from '../../../../../Services/ContactButtons'
const MobileBizCard = ({ salesRep }) => {
    const user = useContext(UserContext)
    const contactButtons = new ContactButtons();
    const videoRef = useRef(null)
    useEffect(
        () => {
            if (videoRef.current) {
               videoRef.current.load()
            }
        }, [videoRef.current]
    )

    const getImage = () => {
        if (salesRep) {
            return salesRep.profileImage ? salesRep.profileImage.url : null
        }

        return user?.profileImage ? user.profileImage.url : null
    }
    const getVideo = () => {
        if (salesRep) {
            return salesRep.profileVideo ? salesRep.profileVideo.url : null
        }

        return user?.profileVideo ? user.profileVideo.url : null

    }
    return (
        <div style={{
            backgroundImage: "url('/images/Banner/background.png')"
        }} className='mobile_biz_card'>
            <div style={{
                gridTemplateColumns: salesRep?.profileImage ? "1fr 1fr" : "1fr",
            }} className='nameimage'>
                <div style={{
                    display: salesRep?.profileImage ? "block" : "none"
                }} className='image'>
                    {/* image */}
                    {salesRep?.profileImage && <img  src={getImage()}  alt="Shasta Weishampel" />}
                </div>
                <div className='name'>
                    {/* Name  */}
                    <h1>{salesRep ? salesRep?.firstName + " " + salesRep.lastName : user?.firstName + " " + user?.lastName}</h1>
                    <h3>Fiber Specialist</h3>
                </div>
            </div>
            <div className='video'>
                {salesRep?.profileVideo && <video ref={videoRef} controls src={getVideo()+"#t=0.001"} alt="video" />}
            </div>
            <div className='contact_details'>
                <div className='element'>
                    <GiWireframeGlobe color='black' size={30} />
                    <p>{salesRep ? salesRep?.address : user?.address}</p>
                </div>
                <div className='element' onClick={()=>{
                    contactButtons.call(salesRep ? salesRep?.phoneNumber : user?.phoneNumber)
                }}>
                    <MdOutlineLocalPhone color='black' size={30} />
                    <p>{salesRep ? salesRep?.phoneNumber : user?.phoneNumber}</p>
                </div>
                <div className='element' 
                onClick={()=>{
                    contactButtons.email(salesRep ? salesRep?.email : user?.email)
                }}
                >
                    <MdOutlineMail color='black' size={30} />
                    <p>{salesRep ? salesRep?.email : user.email}</p>
                </div>
            </div>
            {/* <div className='reviews'>
            <ReviewContainer salesRepId={salesRep?.id} />
            </div> */}
        </div>
    )
}

export default MobileBizCard