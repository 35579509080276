import React, { useEffect, useState } from 'react'
import authorized from "./../../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorized.png"
import authorizedFastwyre from "./../../../TeamAdmin/MapPage/components/NewLeadCard/Images/authorizedFastwyre.png"

import { Modal } from '@mui/material'
import PhoneInput from 'react-phone-input-2'
import logo from "./../../../TeamAdmin/MapPage/components/NewLeadCard/Images/logo.png"
import Swal from 'sweetalert2'
import CreateLightCustomerReq from '../../../../Requests/Customer/CreateLightCustomerReq'
import CustomerApi from '../../../../API/CustomerApi'
import MapIconApi from '../../../../API/MapIconApi'
import SearchLeadsForContactCenterReq from '../../../../Requests/Customer/SearchLeadsForContactCenterReq'
const LightCustomerModal = ({ lightOpenModal, setLightOpenModal, fiberCompanyId, fiberPLanId, salesRepId, salesOrgId }) => {

    const [lightFirstName, setLightFirstName] = React.useState('')
    const [lightLastName, setLightLastName] = React.useState('')
    const [lightEmail, setLightEmail] = React.useState('')
    const [lightPhone, setLightPhone] = React.useState('')
    const [lightAddress, setLightAddress] = React.useState('')
    const [lightConsent, setLightConsent] = React.useState(false)
    const [selectedOption, setSelectedOption] = useState(null)


    const [options, setOptions] = useState([])

    console.log(fiberCompanyId)
    const createLightCustomer = async () => {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Creating Light Customer",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        if (!lightFirstName) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "First Name is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lightPhone) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }



        if (lightPhone.length < 11) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone is invalid",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })

            return
        }

        if (!lightConsent) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Consent is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        // if (!selectedOption) {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Error",
        //         text: "Please type and select an address from the list",
        //         customClass: {
        //             container: 'custom-swal'
        //         },
        //         didOpen: () => {
        //             Swal.hideLoading()
        //         }
        //     })

        //     return
        // }

        // let details = await getSuggestionDetails(selectedOption.place_id);


        const req = new CreateLightCustomerReq()
        // req.leadId = lead.id
        req.firstName = lightFirstName
        req.lastName = lightLastName
        req.email = lightEmail
        req.phoneNumber = lightPhone
        req.salesRepId = salesRepId
        req.salesOrgId = salesOrgId
        req.signedUpBy = "BizCardOffer"
        req.companyId = fiberCompanyId
        req.planId = fiberPLanId
        req.address = lightAddress
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        req.timezone = timeZone
        const res = await CustomerApi.AcceptBizCardOffer(req)
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Thank you for your interest in Lightning OS. Our Representative will contact you in 24 to 48 hours.",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })

            // setReload(
            //     (prev) => {
            //         return !prev
            //     }
            // )

            setLightOpenModal(false)
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                customClass: {
                    container: 'custom-swal'
                },
                text: res?.status?.message
            })
        }
    }

    useEffect(() => {
        console.log(lightPhone)
    }, [lightPhone])

    const getSuggestionDetails = async (id) => {
        const detailsPlaceUrl = await MapIconApi.GetMapIdDetails(id);
        console.log(detailsPlaceUrl)
        return detailsPlaceUrl;
    }

    // const searchLocation = async (address, houses) => {

    //     try {
    //         const req = new SearchLeadsForContactCenterReq();
    //         req.SearchString = address;
    //         req.PagingParams.PageNumber = 1;
    //         req.PagingParams.PageSize = 10;
    //         const locationSuggestions = await MapIconApi.SearchMapPlacesUrl(address);

    //         let formattedLocationSuggestions = [];
    //         if (locationSuggestions && locationSuggestions.status === "OK") {
    //             formattedLocationSuggestions = locationSuggestions.predictions.map((suggestion) => ({
    //                 formatted_address: suggestion.description,
    //                 place_id: suggestion.place_id,
    //                 types: suggestion.types,
    //                 isCustomer: false, // Mark this as a location result
    //             }));
    //         }
    //         return { results: formattedLocationSuggestions, status: "OK" };

    //     } catch (error) {
    //         console.error(error);
    //         return null;
    //     }
    // };

    // useEffect(() => {
    //     const getOptions = async () => {
    //         const optionsArr = await searchLocation(lightAddress);
    //         setOptions(optionsArr.results);
    //     };
    //     let id = setTimeout(() => {
    //         getOptions();
    //     }
    //         , 1000)

    //     return () => {
    //         clearTimeout(id)
    //     }


    // }, [lightAddress]);

    return (
        <Modal setOpen={setLightOpenModal} minHeight={'80vh'} open={lightOpenModal} onClose={() => {
            setLightOpenModal(false)
        }} >
            <div className='custom_modal'>
                {fiberCompanyId == 2 &&
                    <img alt="Logo" src={authorized} className='logo' />
                }
                {
                    fiberCompanyId == 9 &&
                    <img alt="Logo" src={authorizedFastwyre} className='logo' />
                }
                {/* <h1 >Customer Info</h1> */}
                <div className='inputs'>
                    <input placeholder='*First Name' value={lightFirstName} onChange={(e) => {
                        setLightFirstName(e.target.value)
                    }} />
                    <input placeholder='Last Name' value={lightLastName} onChange={(e) => {
                        setLightLastName(e.target.value)
                    }} />
                    <input placeholder='Email' value={lightEmail} onChange={(e) => {
                        setLightEmail(e.target.value)
                    }} />

                    <PhoneInput placeholder='* Cellphone' country={'us'} containerStyle={{
                        width: "100%",
                        borderRadius: "10px"
                    }} inputClass='custom_input'

                        value={lightPhone} onChange={(e) => {
                            setLightPhone(e)
                        }} />

                    <div className='address'>
                        <input placeholder='* Address' value={lightAddress} onChange={(e) => {
                            setLightAddress(e.target.value)
                        }} />
                    </div>

                    <div className='consent'>
                        <input type="checkbox"
                            checked={lightConsent}
                            onChange={(e) => {
                                setLightConsent(e.target.checked)
                            }}
                        />
                        <label>I agree to receive email and/or sms messages from Lightning OS LLC.</label>
                    </div>
                    <button onClick={() => {
                        createLightCustomer()
                    }
                    }>Reserve Free Install</button>
                    <img alt='Logo' src={logo} style={{
                        width: "100%",
                        // marginTop: "1rem"
                    }} className='logo' />
                </div>
            </div>
        </Modal>
    )
}

export default LightCustomerModal